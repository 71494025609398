import { styled, css, keyframes } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    .main-container {
      padding: 15px;
    }
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: #e5edff;
    box-shadow: -4px 4px 4px 0 #00000040;
    width: 100%;
    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .main-data {
        display: flex;
        align-items: center;
        width: calc(100% - 60px);
        gap: 5px;
        .icon {
          width: 33px;
          height: 40px;
          object-fit: contain;
          @media (max-width: 450px) {
            width: 20px;
          }
        }
        .title {
          color: #000;
          font-size: 14px;
          margin-bottom: 1px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: calc(100% - 105px);
          @media (max-width: 992px) {
            font-size: 11px;
          }
          @media (max-width: 500px) {
            max-width: 160px;
          }
          @media (max-width: 359px) {
            max-width: 100px;
          }
        }
        .description {
          font-size: 13px;
          white-space: nowrap;
          @media (max-width: 1300px) {
            font-size: 10px;
          }
        }
      }
      .price {
        color: #5f5f5f;
        background: #f0f0f0;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 600;
        white-space: nowrap;
        font-size: 16px;
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
    .data-container {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .main-data {
        width: calc(100% - 195px);
        @media (max-width: 1300px) {
          width: calc(100% - 165px);
        }
        @media (max-width: 1024px) {
          width: calc(100% - 145px);
        }
        @media (max-width: 450px) {
          width: calc(100% - 60px);
        }
        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 600;
          margin-bottom: 10px;
          @media (max-width: 768px) {
            align-items: flex-start;
          }
          &:hover {
            transform: none;
          }
          .name {
            font-size: 18px;
            color: #5f5f5f;
            width: 48px;
            margin-top: 3px;
            @media (max-width: 1024px) {
              font-size: 14px;
              width: 41px;
            }
            @media (max-width: 550px) {
              font-size: 12px;
              width: 35px;
            }
          }
          .description {
            color: #000;
            font-size: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            @media (max-width: 768px) {
              white-space: break-spaces;
            }
            @media (max-width: 1024px) {
              font-size: 14px;
            }
            @media (max-width: 550px) {
              font-size: 12px;
            }
            margin-top: 3px;
            a {
              text-decoration: none;
              font-size: 14px;
              @media (max-width: 550px) {
                font-size: 12px;
              }
            }
          }
        }
      }
      .statistic-data {
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 450px) {
          flex-direction: column;
          gap: 10px;
          justify-content: center;
        }
        .days-left {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          .number {
            font-size: 30px;
            @media (max-width: 1300px) {
              font-size: 25px;
            }
            @media (max-width: 1024px) {
              font-size: 22px;
            }
            @media (max-width: 768px) {
              font-size: 19px;
            }
            @media (max-width: 450px) {
              font-size: 16px;
            }
            color: #303030;
            font-weight: 600;
          }
          .name {
            font-size: 16px;
            white-space: nowrap;

            @media (max-width: 1300px) {
              font-size: 14px;
            }
            @media (max-width: 1024px) {
              font-size: 14px;
            }
            @media (max-width: 768px) {
              font-size: 12px;
            }
            @media (max-width: 450px) {
              font-size: 10px;
            }
            font-weight: 600;
          }
        }
      }
      .circular-progress-container {
        width: 96px;
        height: 96px;
      }

      @media (max-width: 1300px) {
        /* Tablet */
        .circular-progress-container {
          width: 75px;
          height: 75px;
        }
      }

      @media (max-width: 1024px) {
        /* Tablet */
        .circular-progress-container {
          width: 55px;
          height: 55px;
        }
      }
    }
  `
);
