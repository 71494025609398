import { React, useState, useEffect } from "react";
import TrustedPanel from "./TrustedPanel";
import ServiceSearch from "./ServiceFinder/ServiceSearch";
import HomePagePanel from "./HomePagePanel";
import { api_Routes } from "../../api_Routes";
import { Helper } from "../../tools/Helper";
import Header from "../common/header";
import MainLoader from "../common/main-loader";
import RenderOnScroll from "../../helpers/RenderOnScroll";
// import FAQ from "./faq";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [Categories, setCategories] = useState([]);
  const [Panels, setPanels] = useState([]);
  const [country, setCountry] = useState([]);
  const [trustedPanel, setTrustedPanel] = useState([]);
  const [pinup, setPinup] = useState([]);
  const [pinDown, setPinDown] = useState([]);
  const [homePagePanels, setHomePagePanels] = useState([]);

  const get_Home = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.HomePage.view,
      hasToken: true,
      data: {
        results: 190,
      },
    });
    if (response) {
      setIsLoading(false);
      setHomePagePanels([]);
      setPinDown([]);
      setPinup([]);
      setPanels([]);
      setCountry(
        response.data.Countries.map((ele) => ({
          id: ele.id,
          label: ele.name,
        }))
      );
      setCategories(
        response.data.Categories.map((category) => ({
          id: category.id,
          label: category.name,
          subCategories: category.sub_categories.map((sub) => ({
            id: sub.id,
            label: sub.name,
          })),
        }))
      );

      // response.data.panelsForFilter.forEach((ele) => {
      //   setPanels((prev) => [
      //     ...prev,
      //     {
      //       id: ele.id,
      //       label: ele.name,
      //     },
      //   ]);
      // });
      setTrustedPanel(
        response.data.trustedPanel.map((ele) => ({
          id: ele.id,
          name: ele.name,
          customLogo: ele.customLogo,
          uuid: ele.uuid,
        }))
      );
      response.data.pinUpPanels.forEach((ele) => {
        setPinup((prev) => [
          ...prev,
          {
            id: ele.id,
            label: ele.name,
            description: ele.description,
            panelId: ele?.uuid,
          },
        ]);
      });
      response.data.pinDownPanels.forEach((ele) => {
        setPinDown((prev) => [
          ...prev,
          {
            id: ele.id,
            label: ele.name,
            description: ele.description,
            panelId: ele?.uuid,
          },
        ]);
      });
      response.data.homePagePanels.forEach((ele) => {
        setHomePagePanels((prev) => [
          ...prev,
          {
            id: ele.id,
            name: ele.name,
            uuid: ele.uuid,
            logo: ele.logo,
            CustomLogo: ele.CustomLogo,
            description: ele.description,
            trust: ele.trust,
          },
        ]);
      });

    } else {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    get_Home();
  }, []);

  return (
    <>
      {isLoading ? (
        <MainLoader />
      ) : (
        <>
          <Header />
          <ServiceSearch
            categories={Categories}
            countries={country}
            pinup={pinup}
            pinDown={pinDown}
          />
          <TrustedPanel trusted={trustedPanel} />
          <RenderOnScroll>
            <HomePagePanel homePagePanels={homePagePanels} />
          </RenderOnScroll>
          {/* <FAQ /> */}
        </>
      )}
    </>
  );
};
export default Home;
