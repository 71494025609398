import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import RocketSection from "./RocketAnimations";

const OurCompany = () => {

    return (
        <>

            <Typography sx={{
                fontWeight: "700", fontSize: "50px", lineHeight: "75px",
                color: "#474747", textAlign: "center"
            }}>


                About US
            </Typography>
            <Box sx={{ background: "#E5EDFF", padding: "30px", borderRadius: "25px", marginTop: "20px" }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} md={5}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Box>
                                <Typography sx={{ fontSize: "30px", fontWeight: "45px", fontWeight: "600", color: "#474747", lineHeight: "35px" }}>
                                    Our Company
                                </Typography>
                            </Box>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec ultricies lorem. Proin quis mait amet dui varius, commodo turpis ac, auctor lorem. Vestibulum faucibus ex non risus cursus, non sollicitu potenti. Integer at arcu non orci convallis varius at at ligula. In scelerisque justo nec facilisis pharetra. Cras varius augue eget turpis gravida, ut scelerisque libero elementum.
                                nec facilisis pharetra. Cras varius augue eget turpis gravida,
                            </Typography>
                            <Typography>

                            // bannar section
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>

                        {/* <RocketSection /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>

                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec ultricies lorem. Proin quis magna ut eros varius dignissim. Sed sit amet dui varius, commodo turpis ac, auctor lorem. Vestibulum faucibus ex non risus cursus, non sollicitudin odio malesuada. Suspendisse potenti. Integer at arcu non orci convallis varius at at ligula. In scelerisque justo nec facilisis pharetra. Cras varius augue eget turpis gravida, ut scelerisque libero elementum.
                            nec facilisis pharetra. Cras varius augue eget turpis gravida,et dui varius, commodo turpis ac, auctor lorem. Vestibulum faucibus ex non risus cursus, non sollicitudin odio malesuada. Suspendisse potenti. Integer at arcu non orci convallis varius at at ligula. In scelerisque justo nec facilisis pharetra. Cras varius augue eget turpis gravida, ut scelerisque libero elementum.
                            nec facilisis pharetra. Cras varius augue eget turpis gravida,
                        </Typography>
loi89
                    </Grid>




                </Grid>
            </Box>






        </>

    )
}
export default OurCompany;