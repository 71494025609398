import { styled, css, keyframes } from "@mui/system";

// Keyframes for moving "M" letters
const Loader = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
`;

export const Styles = styled("div")(
  ({ theme }) => css`
    img {
      animation: 0.8s ${Loader} 0s infinite forwards linear;
    }
  `
);
