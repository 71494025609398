import React, { useState } from "react";
import {
  Box,
  Button,
  Rating,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import Flag from "react-world-flags";
import { Styles } from "./styles";
import Panelimg from "../../../../..//Assets/Provider.png";
import CustomDateInput from "./CustomDateInput";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

const EditDetails = ({ Panel, onSave, onCancel, isLoading }) => {
  const [formData, setFormData] = useState({
    name: Panel.name || "",
    description: Panel.description || "",
    url: Panel.url || "",
    domain_registered: Panel.domain_registered || "",
    country_id: Panel.country_name || "",
    payment_method: Panel.payment_method || [],
    logo: Panel.logo || "",
    images: Panel.images || [],
    email: Panel?.email || "",
    phoneNumber: Panel?.phoneNumber || "",
    smmpanel_skybe: Panel?.smmpanel_skybe || Panel?.skype,
    facebook: Panel?.facebook || "",
    smmpanel_whatsapp: Panel?.smmpanel_whatsapp || Panel?.whatsapp,
    smmpanel_instagram: Panel?.instagram || Panel.smmpanel_instagram,
    telegram: Panel?.telegram || "",
    twitter: Panel?.twitter || "",
  });
  const [inputValue, setInputValue] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);

  const handleAddMethod = () => {
    if (inputValue.trim() !== "") {
      setPaymentMethod([...paymentMethod, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleDeleteMethod = (index) => {
    const updatedMethods = paymentMethod.filter((_, i) => i !== index);
    setPaymentMethod(updatedMethods);
  };

  const handleAddImages = (files) => {
    const newImages = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...newImages],
    }));
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <Styles>
      <Box
        sx={{
          padding: {
            xs: "30px",
            sm: "30px 30px 30px 40px",
            md: "30px",
            lg: "30px 80px",
          },
          background: "#D2E2EC",
        }}
        className="offer-details-container"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "50px", md: "30px" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: "50px", md: "30px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                  width: { xs: "160px" },
                  gap: { xs: "30px", md: 0 },
                  position: "relative",
                  borderRadius: "15px",
                  overflow: "hidden",
                }}
              >
                <div
                  className="img-container"
                  style={{
                    height: "125px",
                    width: "160px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={formData.logo || Panelimg}
                    alt="Panel"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <Box
                  sx={{
                    position: "absolute",
                    right: "5px",
                    top: "15px",
                    width: "20px !important",
                    height: "20px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  <Button component="label">
                    <EditIcon
                      sx={{
                        width: "0.9rem",
                      }}
                    />

                    <input
                      type="file"
                      hidden
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      onChange={(e) =>
                        handleChange(
                          "logo",
                          URL.createObjectURL(e.target.files[0])
                        )
                      }
                    />
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                  width: "calc(100% - 160px)",
                }}
              >
                <TextField
                  label="Panel Name"
                  value={formData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  fullWidth
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                  required
                />
                <TextField
                  label="Panel URL"
                  value={formData.url}
                  onChange={(e) => handleChange("url", e.target.value)}
                  required
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                  fullWidth
                />
              </Box>
            </Box>
            <TextField
              label="Description"
              value={formData.description}
              onChange={(e) => handleChange("description", e.target.value)}
              fullWidth
              required
              multiline
              rows={3}
              sx={{
                boxShadow: "-2px 2px 4px #00000040",
                borderRadius: "10px",
                backgroundColor: "white",
                border: "0.5px solid #959595",
                "& .MuiInputBase-root": {
                  color: "black !important",
                  borderRadius: "10px",
                  padding: "4px 8px", // Reduced padding
                },
                "& .MuiOutlinedInput-root": {
                  borderWidth: "1px", // Adjusted border width
                  "&:hover fieldset": {
                    borderColor: "black !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black !important",
                  },
                },
                "& .MuiInputBase-input": {
                  padding: "8px", // Adjust input padding
                  fontSize: "16px", // Adjust font size if needed
                },
              }}
            />
            <CustomDateInput
              label="Domain Creating Date"
              value={formData.domain_registered}
              onChange={(newValue) =>
                handleChange("domain_registered", newValue)
              }
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
              gap={1}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#474747",
                }}
              >
                Country:
              </Typography>
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <FormControl fullWidth required>
                  <InputLabel>Country</InputLabel>
                  <Select
                    value={formData.country_code}
                    label="Country"
                    required
                    onChange={(e) =>
                      handleChange("country_code", e.target.value)
                    }
                    sx={{
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px",
                        fontSize: "16px",
                      },
                    }}
                  >
                    <MenuItem value="US">United States</MenuItem>
                    <MenuItem value="GB">United Kingdom</MenuItem>
                    {/* Add more countries as needed */}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#474747",
                  marginBottom: "20px",
                }}
              >
                Contact Info
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "10px",
                }}
              >
                <TextField
                  label="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                />
                <TextField
                  label="Phone Number"
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      phoneNumber: e.target.value,
                    }))
                  }
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                />
                <TextField
                  label="Skype"
                  value={formData.smmpanel_skybe}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      smmpanel_skybe: e.target.value,
                    }))
                  }
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                />
                <TextField
                  label="Facebook"
                  value={formData.facebook}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      facebook: e.target.value,
                    }))
                  }
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                />
                <TextField
                  label="WhatsApp"
                  value={formData.smmpanel_whatsapp}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      smmpanel_whatsapp: e.target.value,
                    }))
                  }
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                />
                <TextField
                  label="Instagram"
                  value={formData.smmpanel_instagram}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      smmpanel_instagram: e.target.value,
                    }))
                  }
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                />
                <TextField
                  label="Telegram"
                  value={formData.telegram}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      telegram: e.target.value,
                    }))
                  }
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                />
                <TextField
                  label="Twitter"
                  value={formData.twitter}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      twitter: e.target.value,
                    }))
                  }
                  sx={{
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px", // Reduced padding
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px", // Adjusted border width
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px", // Adjust input padding
                      fontSize: "16px", // Adjust font size if needed
                    },
                  }}
                />
              </Box>
            </Box>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{ position: "relative", marginTop: "20px" }}
            >
              <Typography
                sx={{
                  marginBottom: "10px",
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "30px",
                  color: "#474747",
                }}
              >
                Payment Methods
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="smmpanel_tel1"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  size="small"
                  placeholder="Type your Payment methods"
                  sx={{
                    width: "95%",
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    marginTop: "10px",
                    border: "0.5px solid #959595",
                    "& .MuiInputBase-root": {
                      color: "black !important",
                      borderRadius: "10px",
                      padding: "4px 8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderWidth: "1px",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px",
                      fontSize: "16px",
                    },
                  }}
                />
                <Button
                  onClick={handleAddMethod}
                  sx={{
                    backgroundColor: "#ECA601",
                    color: "black",
                    padding: "4px 8px",
                    borderRadius: "10px",
                    minWidth: "40px",
                    minHeight: "40px",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: "#FFD700",
                    },
                  }}
                >
                  <svg
                    width="19"
                    height="17"
                    viewBox="0 0 19 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.851562 8.51283H9.07813M17.3047 8.51283H9.07813M9.07813 8.51283V0.923828M9.07813 8.51283V15.5784"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </Button>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {paymentMethod.map((method, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#D7D7D7",
                      borderRadius: "9px",
                      padding: "10px 12px",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#dcdcdc",
                      },
                    }}
                  >
                    {method}
                    <IconButton
                      onClick={() => handleDeleteMethod(index)}
                      size="small"
                      sx={{
                        padding: "1px",
                        borderRadius: "2px",
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        backgroundColor: "white",
                        boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                        "&:hover": {
                          backgroundColor: "#f8d7da",
                        },
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "12px", color: "black" }} />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              backgroundColor: "#fff",
              borderRadius: "15px",
              height: "fit-content",
              padding: "20px",
              paddingBottom: "60px",
              paddingTop: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                width: "80%",
                textAlign: "center",
                marginBottom: "40px",
              }}
            >
              Please select the images that represent the Panel Website
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "10px",
                width: "90%",
              }}
            >
              {formData.images.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    border: "1px solid #303030",
                    borderRadius: "5px",
                    height: "100px",
                    backgroundColor: "#F5F1E8",
                  }}
                >
                  <img
                    src={image}
                    alt={`Preview ${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                  <Button
                    onClick={() => handleRemoveImage(index)}
                    sx={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      backgroundColor: "#F39E91",
                      color: "#000",
                      minWidth: "20px",
                      height: "20px",
                    }}
                  >
                    X
                  </Button>
                </Box>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F5F1E8",
                  border: "1px dashed #ccc",
                  borderRadius: "5px",
                  height: "100px",
                  cursor: "pointer",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    width: "100%",
                    height: "100%",
                    color: "#ECA601",
                    fontSize: "40px",
                    backgroundColor: "#F5F1E8",
                  }}
                >
                  +
                  <input
                    type="file"
                    multiple
                    hidden
                    onChange={(e) => handleAddImages(e.target.files)}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isLoading}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
              background: "#ECA601",
              padding: "5px 50px",
              width: "150px",
              borderRadius: "10px",
              // Adding inner shadow using a pseudo-element
              color: "#F1F5FD",
              position: "relative",
              overflow: "hidden",
              fontWeight: "600",
              fontSize: "20px",
              boxShadow: "0px 3px 3px #00000040", // Outer shadow
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "0 20px 20px 0",
                background: "rgba(255, 255, 255, 0.1)", // Color for the inner shadow
                filter: "blur(4px)",
                zIndex: 1,
              },
              "&:hover:before": {
                background: "rgba(255, 255, 255, 0.2)", // Darker on hover for inner shadow
              },
            }}
          >
            {isLoading ? <CircularProgress size={15} /> : "Save"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
              background: "transparent",
              padding: "5px 50px",
              borderRadius: "10px",
              width: "150px",
              color: "#3F78E0",
              position: "relative",
              overflow: "hidden",
              fontWeight: "600",
              borderColor: "#3F78E0",
              fontSize: "20px",
              boxShadow: "0px 3px 3px #00000040", // Outer shadow
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "0 20px 20px 0",
                background: "rgba(255, 255, 255, 0.1)", // Color for the inner shadow
                filter: "blur(4px)",
                zIndex: 1,
              },
              "&:hover:before": {
                background: "rgba(255, 255, 255, 0.2)", // Darker on hover for inner shadow
              },
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Styles>
  );
};

export default EditDetails;
