import React, { useEffect, useRef, useState } from "react";
import { Styles } from "./styles";
import { Helper } from "../../../../tools/Helper";
import { api_Routes } from "../../../../api_Routes";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import UserPhoto from "../../../../Assets/userphoto.png";
import IncomingUserPhoto from "../../../../Assets/Dashbaord/chat/userImage.svg";

const ChatComponent = ({ data, getData, isSendLoading, setIsSendLoading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const profileData = useSelector((state) => state.profile.profileData);

  const [message, setMessage] = useState("");
  const messagesRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSendLoading(true);
    const { response, message: ApiMessage } = await Helper.Post({
      url: api_Routes.dashboard.tickets.addMessage,
      data: {
        message,
        ticket_support_id: id,
      },
      hasToken: true,
    });
    if (response) {
      getData();
    } else {
      setIsSendLoading(false);
      enqueueSnackbar(ApiMessage, { variant: "error" });
    }
    if (message.trim()) {
      setMessage("");
    }
  };

  return (
    <Styles>
      <div className="chat">
        <div className="messages" ref={messagesRef}>
          {/* Incoming message */}
          {data?.map((item, idx) => {
            if (item?.admin_id) {
              return (
                <div className="message-container incoming-message" key={idx}>
                  <div className="data">
                    <div className="user">
                      <img
                        src={profileData?.photo || UserPhoto}
                        alt={profileData?.name}
                      />
                    </div>
                    <div className="message">{item?.message}</div>
                  </div>
                  <div className="date">{item?.crated_at}</div>
                </div>
              );
            } else {
              return (
                <div className="message-container outcoming-message" key={idx}>
                  <div className="data">
                    <div className="user">
                      <img src={IncomingUserPhoto} />
                    </div>
                    <div className="message">{item?.message}</div>
                  </div>
                  <div className="date">{item?.crated_at}</div>
                </div>
              );
            }
          })}
        </div>
        <div className="footer">
          <form onSubmit={handleSendMessage}>
            <textarea
              name="message"
              placeholder="Type a message"
              rows={5}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              type="submit"
              className="send"
              aria-label="Send message"
              disabled={isSendLoading || message?.length === 0}
            >
              {isSendLoading ? (
                <CircularProgress size={"16px"} color="#fff" />
              ) : (
                "Send"
              )}
            </button>
          </form>
        </div>
      </div>
    </Styles>
  );
};

export default ChatComponent;
