import { React } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const TrustedPanel = ({ trusted }) => {
  const navigate = useNavigate();
  const handleNavigation = (uuid) => {
    navigate(`/PanelsDetails/${uuid}`);
  };
  return (
    trusted?.length > 0 && (
      <>
        <Box sx={{ marginBlock: "40px" }}>
          <Typography
            sx={{
              fontWeight: "700",
              lineHeight: "40px",
              fontSize: "40px",
              color: "#004571",
              textAlign: "center",
            }}
          >
            Our Trusted Providers
          </Typography>
          <Box
            className="carousel"
            sx={{ marginTop: "60px" }}
            onMouseEnter={() => {
              document.querySelector(
                ".carousel-inner"
              ).style.animationPlayState = "paused";
            }}
            onMouseLeave={() => {
              document.querySelector(
                ".carousel-inner"
              ).style.animationPlayState = "running";
            }}
          >
            <Box className="carousel-inner">
              {trusted.map((Tr) => (
                <Box className="item-carosel" key={Tr.id}
                  onClick={() => handleNavigation(Tr.uuid)}>

                  {Tr?.logo ? (
                    <img
                      src={Tr?.logo}
                      alt="panel"
                      style={{ width: "100px" }}
                    />
                  ) : (
                    <div className="final-logo-container">
                      <Typography
                        sx={{
                          fontSize: `${Tr?.customLogo?.fontSize}px`,
                          color: Tr?.customLogo?.color,
                          fontFamily: Tr?.customLogo?.fontFamily,
                        }}
                        className="final-logo"
                      >
                        {Tr?.customLogo?.logoName || "LOREM"}
                      </Typography>
                    </div>
                  )}
                </Box>
              ))}

              {trusted.map((Tr) => (
                <Box className="item-carosel" key={`repeat-${Tr.id}`}>
                  {Tr?.logo ? (
                    <img
                      src={Tr?.logo}
                      alt="panel"
                      style={{ width: "100px" }}
                    />
                  ) : (
                    <div className="final-logo-container">
                      <Typography
                        sx={{
                          fontSize: `${Tr?.customLogo?.fontSize}px`,
                          color: Tr?.customLogo?.color,
                          fontFamily: Tr?.customLogo?.fontFamily,
                        }}
                        className="final-logo"
                      >
                        {Tr?.customLogo?.logoName || "LOREM"}
                      </Typography>
                    </div>
                  )}
                </Box>
              ))}
            </Box>
          </Box>


        </Box>
      </>
    )
  );
};
export default TrustedPanel;
