import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import { Helper } from "../../../tools/Helper";
import { api_Routes } from "../../../api_Routes";
import { useNavigate, useParams } from "react-router-dom";
import ChatComponent from "./chat-component";
import { Box, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";

const TicketsChat = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.dashboard.tickets.viewTicket + id,
      hasToken: true,
    });
    if (response) {
      setData(response.data);
    } else {
      enqueueSnackbar("Can't find this ticket", { variant: "error" });
      navigate("/dashboard/tickets")
    }
    setIsLoading(false);
    setIsSendLoading(false);
  };

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100dvh - 92px)",
      }}
    >
      <CircularProgress sx={{ color: "blue" }} />
    </Box>
  ) : (
    <Styles>
      <div className="title">Ticket #{id}</div>
      <ChatComponent
        data={data}
        getData={getData}
        isSendLoading={isSendLoading}
        setIsSendLoading={setIsSendLoading}
      />
    </Styles>
  );
};

export default TicketsChat;
