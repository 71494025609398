import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileData: null,
    isSettingsChanges: false,
    isLoading: true,
  },
  reducers: {
    setProfileData: (state, { payload }) => {
      state.profileData = payload;
      state.isLoading = false;
    },
    setIsLoading: (state) => {
      state.isLoading = false;
    },
    setIsSettingsChanges: (state, { payload }) => {
      state.isSettingsChanges = payload;
      state.isLoading = true;
    },
  },
});
export const { setIsSettingsChanges, setProfileData, setIsLoading } =
  profileSlice.actions;
export default profileSlice.reducer;
