import axios from "axios";

export const isLogin = JSON.parse(localStorage.getItem("user"))?.token
  ? true
  : false;
const token = () => {
  if (localStorage.hasOwnProperty("user")) {
    return `Bearer ${JSON.parse(localStorage.getItem("user")).token}`;
  }
  return "";
};
const handleUnauthorizedError = () => {
  localStorage.clear();
  window.location.href = "/login";
  return { message: "Please login to continue." };
};

function extractErrorMessages(message) {
  if (typeof message === "string") {
    return message.trim(); // Return string after trimming whitespace
  } else if (Array.isArray(message)) {
    return message.filter(Boolean).join(", "); // Join array items, ignoring empty/falsey values
  } else if (typeof message === "object" && message !== null) {
    // Check if the object contains an 'errors' array
    if (message.errors && Array.isArray(message.errors)) {
      return message.errors.filter(Boolean).join(", "); // Join non-empty errors
    }
    // Handle nested objects or arrays of error messages
    return Object.values(message)
      .flat()
      .filter(Boolean)
      .join(", ");
  }
  return "An unknown error occurred."; // Default fallback for unsupported formats
}


export const Helper = {
  Get: async ({ url, hasToken, data = null }) => {
    try {
      const response = await axios.get(
        url,
        hasToken
          ? {
            headers: {
              Authorization: token(),
              //  ApiKey: "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
            },
            params: data ? data : {},
          }
          : {
            headers: {
              //  ApiKey: "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
            },
            params: data ? data : {},
          }
      );
      if (response.success && response.status === 200) {
        return {
          message: response.data.message,
          response: response.data,
        };
      } else {
        return {
          message: response.data.message,
          response: response.data,
        };
      }
    } catch (err) {
      console.log(err);

      if (err.response) {
        const err_response = err.response.data;

        if (err.response.status === 403) {
          return {
            message:
              "Access Forbidden: You do not have permission to access this resource.",
          };
        } else if (err.response.status === 401) {
          return handleUnauthorizedError();
        } else if (
          err_response.success !== undefined &&
          err_response.data !== undefined
        ) {
          if (err_response.data.length > 0) {
            return { message: err_response.data };
          } else {
            return { message: err_response.message };
          }
        }
      }

      // Default error message
      return { message: err.message };
    }
  },
  Get_Abort: async ({ url, hasToken, data = null, signal }) => {
    try {
      const response = await axios.get(
        url,
        hasToken
          ? {
            headers: {
              Authorization: token(),
              /// ApiKey: "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
            },
            params: data ? data : {},
            signal: signal,
          }
          : {
            params: data ? data : {},
            signal: signal,
            headers: {
              //   ApiKey: "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
            },
          }
      );
      if (response.success && response.status === 200) {
        return {
          message: response.data.message,
          response: response.data,
        };
      } else {
        return {
          message: response.data.message,
          response: response.data,
        };
      }
    } catch (err) {
      console.log(err);

      if (err.response) {
        const err_response = err.response.data;

        if (err.response.status === 403) {
          return {
            message:
              "Access Forbidden: You do not have permission to access this resource.",
          };
        } else if (err.response.status === 401) {
          return handleUnauthorizedError();
        } else if (
          err_response.success !== undefined &&
          err_response.data !== undefined
        ) {
          if (err_response.data.length > 0) {
            return { message: err_response.data };
          } else {
            return { message: err_response.message };
          }
        }
      }

      // Default error message
      return { message: err.message };
    }
  },
  Post: async ({ url, hasToken, data = null }) => {
    try {
      const response = await axios.post(
        url,
        data,
        hasToken
          ? {
            headers: {
              Authorization: token(),
            },
          }
          : {}
      );

      if (response.data.success && response.status === 200) {
        return {
          message: response.data.message || "Request successful.",
          response: response.data,
        };
      } else {
        return {
          message: response.data.message || "An unexpected error occurred.",
          response: response.data,
        };
      }
    } catch (err) {
      console.error("Error:", err);

      if (err.response) {
        const err_response = err.response.data;

        // Specific status code handling
        if (err.response.status === 403) {
          return {
            message: "Access Forbidden: You do not have permission to access this resource.",
          };
        } else if (err.response.status === 401) {
          return handleUnauthorizedError(); // Assuming you have a function for this
        } else if (err.response.status === 422 || err.response.status === 400) {
          // Handle validation errors using extractErrorMessages
          return {
            message: extractErrorMessages(err_response),
          };
        } else if (err_response && err_response.success === false) {
          // Generic error handling for API failure responses
          return {
            message: err_response.message || "An error occurred on the server.",
          };
        }
      }

      // Default error handling for unexpected issues
      return { message: err.message || "A network error occurred." };
    }
  },

  Put: async ({ url, hasToken, data = null }) => {
    try {
      const response = await axios.put(
        url,
        data,
        hasToken
          ? {
            headers: {
              Authorization: token(),
              //  ApiKey: "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
            },
          }
          : {
            headers: {
              //  ApiKey: "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
            },
          }
      );
      if (response.success && response.status === 200) {
        return {
          message: response.data.message,
          response: response.data,
        };
      } else {
        return {
          message: response.data.message,
          response: response.data,
        };
      }
    } catch (err) {
      console.log(err);

      if (err.response) {
        const err_response = err.response.data;

        if (err.response.status === 403) {
          return {
            message:
              "Access Forbidden: You do not have permission to access this resource.",
          };
        } else if (err.response.status === 401) {
          return handleUnauthorizedError();
        } else if (
          err_response.success !== undefined &&
          err_response.data !== undefined
        ) {
          if (err_response.data.length > 0) {
            return { message: err_response.data };
          } else {
            return { message: err_response.message };
          }
        }
      }

      // Default error message
      return { message: err.message };
    }
  },
  Delete: async ({ url, hasToken, data = null }) => {
    try {
      const response = await axios.delete(
        url,
        hasToken
          ? {
            headers: {
              Authorization: token(),
              //    ApiKey: "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
            },
            // params : data ? data : {}
          }
          : {
            headers: {
              //  ApiKey: "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
            },
          },
        data
      );

      if (response.success && response.status === 200) {
        return {
          message: response.data.message,
          response: response.data,
        };
      } else {
        return {
          message: response.data.message,
          response: response.data,
        };
      }
    } catch (err) {
      console.log(err);

      if (err.response) {
        const err_response = err.response.data;

        if (err.response.status === 403) {
          return {
            message:
              "Access Forbidden: You do not have permission to access this resource.",
          };
        } else if (err.response.status === 401) {
          return handleUnauthorizedError();
        } else if (
          err_response.success !== undefined &&
          err_response.data !== undefined
        ) {
          if (err_response.data.length > 0) {
            return { message: err_response.data };
          } else {
            return { message: err_response.message };
          }
        }
      }

      // Default error message
      return { message: err.message };
    }
  },
};
