import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    margin-inline: 40px;
    .filters-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5%;
      .item.keyword {
        width: 30%;
      }
      .item.providers {
        width: 20%;
      }
      .item.countries {
        width: 20%;
      }
      .item.button {
        width: fit-content;
      }
      @media (max-width: 900px) {
        flex-wrap: wrap;
        .item.keyword {
          width: 58.5%;
          margin-bottom: 20px;
        }
        .item.providers {
          width: 40%;
          margin-bottom: 20px;
        }
        .item.countries {
          width: 100%;
          margin-bottom: 20px;
        }
        .item.button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      @media (max-width: 768px) {
        flex-wrap: wrap;
        .item.keyword {
          width: 100%;
          margin-bottom: 20px;
        }
        .item.providers {
          width: 100%;
          margin-bottom: 20px;
        }
        .item.countries {
          width: 100%;
          margin-bottom: 20px;
        }
        .item.button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  `
);
