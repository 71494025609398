import React, { useEffect, useState } from "react";
import DashboardComponent from "../../../components/user-dashboard/dashboard";
import { Helper } from "../../../tools/Helper";
import { api_Routes } from "../../../api_Routes";
import MainLoader from "../../../components/common/main-loader";

const Dashbaord = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState();

  const getData = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.dashboard.overView.view,
      hasToken: true,
    });
    if (response) {
      setPageData(response?.data);
    } else {
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return isLoading ? (
    <MainLoader isFull={true} />
  ) : pageData ? (
    <DashboardComponent data={pageData} />
  ) : (
    <div
      className="title"
      style={{
        fontSize: "24px",
        fontWeight: "600",
        color: "#474747",
        marginBottom: "15px",
        textAlign: "center",
      }}
    >
      There are no data yet
    </div>
  );
};
export default Dashbaord;
