import styled from "styled-components";
export const Styles = styled.div`
  .logo-container {
    width: 120px;
    height: 120px;
    border-radius: 7px;
    overflow: hidden;
    background: radial-gradient(circle, #51aafd, #002d68);
    .final-logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      .final-logo {
        font-weight: 700;
        line-height: 50px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
