import React from "react";

const DashboardIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.0079 2.38672H9.72701C9.43147 2.38672 9.19189 2.6263 9.19189 2.92183V6.40005C9.19189 6.69558 9.43147 6.93516 9.72701 6.93516H14.0079C14.3034 6.93516 14.543 6.69558 14.543 6.40005V2.92183C14.543 2.6263 14.3034 2.38672 14.0079 2.38672Z"
                fill="white"
                stroke="white"
                strokeWidth="0.8"
            />
            <path
                d="M7.05135 8.53906H2.77046C2.47493 8.53906 2.23535 8.77864 2.23535 9.07417V12.5524C2.23535 12.8479 2.47493 13.0875 2.77046 13.0875H7.05135C7.34688 13.0875 7.58646 12.8479 7.58646 12.5524V9.07417C7.58646 8.77864 7.34688 8.53906 7.05135 8.53906Z"
                fill="white"
                stroke="white"
                strokeWidth="0.8"
            />
            <path
                d="M14.0079 8.53906H9.72701C9.43147 8.53906 9.19189 8.77864 9.19189 9.07417V12.5524C9.19189 12.8479 9.43147 13.0875 9.72701 13.0875H14.0079C14.3034 13.0875 14.543 12.8479 14.543 12.5524V9.07417C14.543 8.77864 14.3034 8.53906 14.0079 8.53906Z"
                fill="white"
                stroke="white"
                strokeWidth="0.8"
            />
            <path
                d="M7.05135 2.38672H2.77046C2.47493 2.38672 2.23535 2.6263 2.23535 2.92183V6.40005C2.23535 6.69558 2.47493 6.93516 2.77046 6.93516H7.05135C7.34688 6.93516 7.58646 6.69558 7.58646 6.40005V2.92183C7.58646 2.6263 7.34688 2.38672 7.05135 2.38672Z"
                fill="white"
                stroke="white"
                strokeWidth="0.8"
            />
        </svg>
    );
};

export default DashboardIcon;
