import { React, useRef, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UserPhoto from "../../Assets/userphoto.png";
import { CircularProgress, TextField, Typography } from "@mui/material";
import Facebook from "../../Assets/Svg/Facebook.svg";
import Instagram from "../../Assets/Svg/instagram.svg";
import Telegram from "../../Assets/Svg/Telegram.svg";
import Whatsaap from "../../Assets/Svg/whatsaap.svg";
import { Helper } from "../../tools/Helper";
import { api_Routes } from "../../api_Routes";
import Files from "react-files";
import { useNavigate } from "react-router-dom";
import MainLoader from "../common/main-loader";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading, setProfileData } from "../../store/slices/profileSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.profile.profileData);
  const isProfileDataLoading = useSelector((state) => state.profile.isLoading);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [editedProfile, setEditedProfile] = useState({
    ...profileData,
    photoPreview: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handleEdit = async () => {
    const formData = new FormData();
    // Add all fields from editedProfile except photoPreview and photo
    Object.keys(editedProfile).forEach((key) => {
      if (
        key !== "photo" &&
        key !== "photoPreview" &&
        key !== "sex" &&
        key !== "description" &&
        editedProfile[key]?.length !== 0
      ) {
        formData.append(key, editedProfile[key]);
      } else if (editedProfile[key]?.length === 0) {
        formData.append(key, "null");
      }
    });
    // Append only the binary file for photo
    if (selectedPhoto) {
      formData.append("photo", selectedPhoto); // Only the binary file
    }
    setIsEditLoading(true);
    const { response, message } = await Helper.Post({
      url: api_Routes.profile.updateprofile,
      hasToken: true,
      data: formData,
    });

    if (response) {
      dispatch(
        setProfileData({
          id: response.user.id,
          name: response.user.name,
          email: response.user.email,
          photo: "https://subwebsite.rentchicken.net" + response.user.photo,
          description: response.user.description,
          whatsapp: response.user.whatsapp,
          telegram: response.user.telegram,
          instagram: response.user.instagram,
          facebook: response.user.facebook,
          sex: response.user.sex,
          about: response.user.about,
        })
      );
      setSelectedPhoto(null);
      setIsEditing(false);
    } else {
      console.error(message);
    }
    dispatch(setIsLoading());
    setIsEditLoading(false);
  };

  const handleCancel = () => {
    setSelectedPhoto(null);
    setIsEditing(false);
  };

  const handleInputChange = (field, value) => {
    setEditedProfile((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedPhoto(file);
      setEditedProfile((prev) => ({
        ...prev,
        photoPreview: URL.createObjectURL(file),
      }));
    }
  };

  const handleFileError = (error) => {
    console.error("File upload error:", error);
  };

  return (
    <>
      {isProfileDataLoading ? (
        <MainLoader />
      ) : (
        <Box
          sx={{
            marginBlock: "30px",
            background: "#E5EDFF",
            borderRadius: "20px",
            marginInline: { xs: "10px", md: "50px", lg: "100px" },
            padding: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "20px", sm: 0 },
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "160px",
                height: "160px",
                position: "relative",
                cursor: isEditing ? "pointer" : "default",
              }}
            >
              <img
                src={
                  editedProfile.photoPreview || profileData?.photo || UserPhoto
                }
                alt="UserPhoto"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #3F78E0",
                  borderRadius: "10%",
                }}
              />
              {isEditing && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    borderRadius: "10%",
                  }}
                >
                  <Files
                    onChange={handleFileChange}
                    onError={handleFileError}
                    accepts={["image/*"]}
                    multiple={false}
                    clickable
                  >
                    Click to Upload
                  </Files>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                width: { xs: "100%", lg: "70%" },
                justifyContent: "space-evenly",
                gap: { xs: "10px", sm: 0 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                {profileData?.name && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "300",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#474747",
                      }}
                    >
                      name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "28px",
                        fontWeight: "400",
                        color: "#303030",
                      }}
                    >
                      {profileData?.name}
                    </Typography>
                  </Box>
                )}
                {profileData?.email && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "300",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#474747",
                      }}
                    >
                      email
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "28px",
                        fontWeight: "400",
                        color: "#303030",
                      }}
                    >
                      {profileData?.email}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "20px",
                position: "absolute",
                top: "-30px",
                right: "-32px",
              }}
            >
              <Button
                onClick={() => {
                  if (isEditing) {
                    handleEdit();
                  } else {
                    setIsEditing(true);
                  }
                }}
                variant="outlined"
                sx={{
                  backgroundColor: "white",
                  color: "#004571",
                  padding: "5px 10px",
                  borderRadius: "80px",
                  border: "none",
                  boxShadow:
                    "-2px 2px 4px #00000040, inset -2px 4px 4px #FFFFFF",
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
              >
                {isEditing ? (
                  isEditLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    "Save Changes"
                  )
                ) : (
                  "Edit Profile"
                )}
              </Button>
              {isEditing && (
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  sx={{
                    color: "white",
                    border: "1px solid #004571",
                    padding: "5px 10px",
                    borderRadius: "80px",
                    fontSize: "12px",
                    backgroundColor: "#1976d2",
                  }}
                >
                  Cancel
                </Button>
              )}
            </Box>
          </Box>

          {isEditing ? (
            <Box sx={{ marginTop: "40px" }}>
              <Typography
                sx={{
                  lineHeight: "33px",
                  fontWeight: "600",
                  color: "#474747",
                  fontSize: "22px",
                }}
              >
                About
              </Typography>
              <TextField
                value={editedProfile.about || ""}
                onChange={(e) => handleInputChange("about", e.target.value)}
                size="small"
                variant="standard"
                fullWidth
                multiline
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  border: "none",
                  boxShadow: "none",
                  marginTop: "20px",
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
          ) : (
            profileData?.about &&
            profileData?.about !== "null" && (
              <Box sx={{ marginTop: "40px" }}>
                <Typography
                  sx={{
                    lineHeight: "33px",
                    fontWeight: "600",
                    color: "#474747",
                    fontSize: "22px",
                  }}
                >
                  About
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "28px",
                    fontWeight: "400",
                    color: "#303030",
                    marginTop: "20px",
                  }}
                >
                  {profileData?.about}
                </Typography>
              </Box>
            )
          )}

          {(isEditing ||
            (profileData?.whatsapp && profileData?.whatsapp !== "null") ||
            (profileData?.telegram && profileData?.telegram !== "null") ||
            (profileData?.instagram && profileData?.instagram !== "null") ||
            (profileData?.facebook && profileData?.facebook !== "null")) && (
            <Box sx={{ marginTop: "40px" }}>
              <Typography>Social Links</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  justifyContent: "space-between",
                  gap: "14px",
                  marginTop: "10px",
                }}
              >
                {profileData &&
                  ["facebook", "instagram", "telegram", "whatsapp"]?.map(
                    (field) => (
                      <Box
                        key={field}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          marginTop: "10px",
                          gap: "14px",
                        }}
                      >
                        {(isEditing || profileData[field] !== "null") && (
                          <img
                            src={
                              field === "facebook"
                                ? Facebook
                                : field === "instagram"
                                ? Instagram
                                : field === "telegram"
                                ? Telegram
                                : Whatsaap
                            }
                            alt={field}
                          />
                        )}
                        {isEditing ? (
                          <TextField
                            value={
                              editedProfile[field] !== "null"
                                ? editedProfile[field] || ""
                                : ""
                            }
                            onChange={(e) => {
                              let value = e.target.value;
                              if (field === "whatsapp") {
                                // Validate WhatsApp: starts with "+" and has numbers, max 13 characters
                                if (
                                  /^\+?[0-9]*$/.test(value) &&
                                  value.length <= 13
                                ) {
                                  handleInputChange(field, value);
                                }
                              } else {
                                handleInputChange(field, value);
                              }
                            }}
                            variant="standard"
                            size="small"
                            sx={{
                              backgroundColor: "#ffffff",
                              borderRadius: "4px",
                              padding: "4px 8px",
                              border: "none",
                              boxShadow: "none",
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        ) : (
                          profileData[field] !== "null" && (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#303030",
                                lineHeight: "18px",
                                fontWeight: "300",
                              }}
                            >
                              {profileData[field]}
                            </Typography>
                          )
                        )}
                      </Box>
                    )
                  )}
              </Box>
            </Box>
          )}

          <Box sx={{ marginTop: "40px" }}>
            <Typography>Password</Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography>********</Typography>
              <Button
                onClick={() => {
                  navigate("/profile/change-password/verify");
                }}
              >
                Change Password
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default Profile;
