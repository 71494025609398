import React from 'react';
import {
    Box,
    Typography,
    Container,
    Paper,
} from '@mui/material';

const TermsOfUse = () => {
    return (
        <Box>
            <Container
                sx={{
                    "@media (min-width: 1200px)": {
                        maxWidth: "1400px",
                    },
                }}
            >
                <Typography
                    variant="h4"
                    component="h1"
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: '700', color: '#303030', marginBlock: "30px", fontSize: "35px", lineHeight: "75px" }}
                >
                    Terms of Use
                </Typography>
                <Paper elevation={3} sx={{ p: 4, borderRadius: "25px", background: "#E5EDFF", marginBottom: "40px" }}>

                    <Box>
                        <Typography sx={{ fontWeight: "600", fontSize: "30px", lineHeight: "45px" }}>
                            Effective Date: January 16, 2025
                        </Typography>
                        <Typography sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: "#474747", marginTop: "10px" }}>
                            Welcome to BooksMM! By accessing or using our website (<a href="https://booksmm.com/">https://booksmm.com/</a>) and services, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, you should not use our website or services.
                        </Typography>
                    </Box>

                    {[{
                        title: "1. Acceptance of Terms",
                        content: "By using our website, you acknowledge that you have read, understood, and agree to these Terms of Use. We reserve the right to update or modify these terms at any time, and it is your responsibility to review them periodically."
                    }, {
                        title: "2. Eligibility",
                        content: "You must be at least 18 years old or the age of majority in your jurisdiction to use our services. By using our website, you represent and warrant that you meet these requirements."
                    }, {
                        title: "3. Use of the Website",
                        content: (
                            <>
                                <Typography sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: "#474747", marginTop: "10px" }}>
                                    You agree to use our website for lawful purposes only and in compliance with all applicable laws and regulations. You are prohibited from:
                                </Typography>
                                <ol style={{ marginTop: "10px", paddingLeft: "20px" }}>
                                    <li>Violating any intellectual property rights.</li>
                                    <li>Disrupting or interfering with the website’s functionality.</li>
                                    <li>Engaging in any activity that could harm BooksMM, its users, or its services.</li>
                                    <li>Attempting to gain unauthorized access to any part of the website or systems.</li>
                                </ol>
                            </>
                        )
                    }, {
                        title: "4. Intellectual Property",
                        content: "All content, including but not limited to text, graphics, logos, and images, is the property of BooksMM or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or modify any content without our prior written consent."
                    }, {
                        title: "5. User Accounts",
                        content: "If you create an account on our website, you are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account."
                    }, {
                        title: "6. Third-Party Links",
                        content: "Our website may contain links to third-party websites. We are not responsible for the content, privacy policies, or practices of any third-party sites. Accessing these links is at your own risk."
                    }, {
                        title: "7. Disclaimers",
                        content: "BooksMM provides its services on an 'as-is' and 'as-available' basis without warranties of any kind, either express or implied. We do not guarantee the accuracy, reliability, or completeness of the content on our website."
                    }, {
                        title: "8. Limitation of Liability",
                        content: "To the fullest extent permitted by law, BooksMM and its affiliates shall not be liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, arising out of your use of our website or services."
                    }, {
                        title: "9. Indemnification",
                        content: "You agree to indemnify and hold harmless BooksMM, its affiliates, and its employees from any claims, damages, losses, or expenses (including legal fees) arising from your use of our website or violation of these Terms of Use."
                    }, {
                        title: "10. Governing Law",
                        content: "These Terms of Use shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of laws principles."
                    }, {
                        title: "11. Termination",
                        content: "We reserve the right to terminate or suspend your access to our website or services at our sole discretion, without notice, for conduct that we believe violates these terms or is harmful to BooksMM or others."
                    }, {
                        title: "12. Changes to Terms",
                        content: "We may revise these Terms of Use at any time. Any changes will be posted on this page with the updated effective date. Continued use of our website constitutes acceptance of the revised terms."
                    }, {
                        title: "13. Contact Us",
                        content: "If you have any questions about these Terms of Use, please contact us at: [Email Address], [Business Address]."
                    }].map((section, index) => (
                        <Box sx={{ mt: 3 }} key={index}>
                            <Typography sx={{ fontWeight: "500", fontSize: "26px", lineHeight: "40px", color: "#474747" }}>
                                {section.title}
                            </Typography>
                            {typeof section.content === 'string' ? (
                                <Typography sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: "#474747", marginTop: "10px" }}>
                                    {section.content}
                                </Typography>
                            ) : (
                                section.content
                            )}
                        </Box>
                    ))}
                </Paper>
            </Container>
        </Box>
    );
};

export default TermsOfUse;
