import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    padding: 30px 40px;
    @media (max-width: 600px) {
      padding: 30px 15px;
    }
    .title {
      text-align: center;
      font-weight: 700;
      font-size: 40px;
      color: #004571;
      margin-bottom: 20px;
    }
  `
);
