import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import Sidebar from "../../UserDashbaord/layout/Sidebar";
import { useLocation } from "react-router-dom";
import routes from "../../Routes/AppRoutes";
import { Box } from "@mui/material";
import { Helper, isLogin } from "../../tools/Helper";
import { api_Routes } from "../../api_Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsLoading,
  setIsSettingsChanges,
  setProfileData,
} from "../../store/slices/profileSlice";
import GoogleTag from "../../helpers/GoogleTags";
function GtagEvent() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-11547846789/2IcdCJHI8YsaEIXRuIIr',
        'value': 1.0,
        'currency': 'EUR'
      });
    `;
    document.body.appendChild(script);

    // Cleanup to prevent duplicate scripts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // No UI rendered
}
function AppContent() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDashboard = location.pathname.startsWith("/dashboard");

  const profileData = useSelector((state) => state.profile.profileData);
  const isProfileDataLoading = useSelector((state) => state.profile.isLoading);
  const isProfileDataSettingsChanges = useSelector(
    (state) => state.profile.isSettingsChanges
  );

  const [isOpen, setIsOpen] = useState(true);

  const get_profile = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.profile.showprofile,
      hasToken: true,
      data: { results: 100 },
    });
    if (response) {
      dispatch(
        setProfileData({
          id: response.user.id,
          name: response.user.name,
          email: response.user.email,
          photo: response.user.photo,
          description: response.user.description,
          whatsapp: response.user.whatsapp,
          telegram: response.user.telegram,
          instagram: response.user.instagram,
          facebook: response.user.facebook,
          sex: response.user.sex,
          about: response.user.about,
        })
      );
    } else {
    }
    dispatch(setIsLoading());
  };

  useEffect(() => {
    if ((!profileData || isProfileDataSettingsChanges) && isLogin) {
      get_profile();
    }
  }, [location]);

  const [isSmallScreenIndicator, setIsSmallScreenIndicator] = useState(
    window.innerWidth < 1300
  );

  useEffect(() => {
    if (isSmallScreenIndicator) setIsOpen(false)

  }, [])

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth <= 1300;
      setIsSmallScreenIndicator(isSmallScreen);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main
      className="content"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <GoogleTag />
      <GtagEvent />
      <Header />
      <Box
        sx={{
          display: isDashboard ? "flex" : undefined,
          flex: isDashboard ? 1 : undefined,
          minHeight: {
            sm: "calc(100vh - 60px)", // Adjust for footer height
            md: "calc(100vh - 60px)",
            lg: "calc(100vh - 60px)",
          },
        }}
      >
        {isDashboard && <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />}
        <div
          style={{
            width: isDashboard
              ? isSmallScreenIndicator
                ? "100%"
                : isOpen
                  ? "calc(100% - 250px)"
                  : "100%"
              : "100%",
            paddingInlineStart: isDashboard
              ? !isSmallScreenIndicator
                ? isOpen
                  ? "250px"
                  : "0px"
                : "0px"
              : "0px",
            transition: "all 0.3s ease-in-out",
            height: "100%",
          }}
        >
          <Routes>
            {routes.map(({ path, element, children }, index) => (
              <Route key={index} path={path} element={element}>
                {children &&
                  children.map(({ path, element }, childIndex) => (
                    <Route key={childIndex} path={path} element={element} />
                  ))}
              </Route>
            ))}
          </Routes>
        </div>

      </Box>
      <Footer />
    </main>
  );
}

export default AppContent;
