import { React, useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
} from "@mui/material";
import { Helper } from "../../../tools/Helper";
import { api_Routes } from "../../../api_Routes";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { showIconByServiceId } from "../../../helpers/showIconByServiceId";
import SwitchButton from "./switch-button";

const OrderHistory = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [option, setOption] = useState("service");
  const baseApi =
    option === "service"
      ? api_Routes.dashboard.orderhistory.view
      : api_Routes.dashboard.orderhistory.viewByOffer;
  const [history, setHistory] = useState([]);
  const [pageloading, setPageLoading] = useState(true); // State for loading
  const [selectedFilter, setSelectedFilter] = useState("all");

  useEffect(() => {
    Order_History();
  }, [selectedFilter, option]);

  const Order_History = async () => {
    setPageLoading(true); // Show loader
    let url = baseApi;

    if (selectedFilter !== "all") {
      url = `${baseApi}?status=${selectedFilter}`;
    }

    const { response, message } = await Helper.Get({
      url,
      hasToken: true,
    });

    if (response) {
      setHistory(
        response.data.map((history) => ({
          request_id: history.request_id,
          panel_url: history.panel_url,
          title: history.title,
          description: history.description,
          status: history.status,
          status_payment: history.status_payment,
          panelName: history.panelName,
          userName: history.userName,
          ourServiceName: history.ourServiceName,
          ourServiceNameId: history.ourServiceNameId,
          ourServiceTypeId: history.ourServiceTypeId,
          report: history.report,
        }))
      );
    } else {
    }
    setPageLoading(false); // Hide loader
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const handleDownload = async (report) => {
    try {
      const link = document.createElement("a");
      link.href = report;
      link.setAttribute("download", "image.jpg");
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      enqueueSnackbar(error || "Failed to download report", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: "#474747",
            fontSize: "24px",
            lineHeight: "30px",
            fontWeight: "600",
          }}
        >
          Your Ads Subscriptions
        </Typography>
        <Box>
          <SwitchButton orderOption={option} setOrderOption={setOption} />
        </Box>
        <Box
          sx={{
            display: { sm: "flex", xs: "grid" },
            gridTemplateColumns: { sm: "auto", xs: "1fr 1fr" },
            flexDirection: "row",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          {["all", "pending", "in_progress", "complete"].map((filter) => (
            <Button
              key={filter}
              variant={selectedFilter === filter ? "contained" : "outlined"}
              sx={{
                "&:hover": {
                  backgroundColor: "#3F78E0",
                  color: "white",
                },
                borderRadius: "5px",
                border: "2px solid #3F78E0",
                color: selectedFilter === filter ? "white" : "#303030",
                fontSize: "14px",
                fontWeight: "600",
                paddingBlock: "10px",
                lineHeight: "18px",
                width: { sm: "130px", xs: "100%" },
                backgroundColor:
                  selectedFilter === filter ? "#3F78E0" : "white",
              }}
              onClick={() => handleFilterChange(filter)}
            >
              {filter.replace("_", " ")}
            </Button>
          ))}
        </Box>

        {/* Show loader when pageloading is true */}
        {pageloading && (
          <Box sx={{ width: "100%", marginTop: "10px" }}>
            <LinearProgress />
          </Box>
        )}

        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            border: "2px solid #3F78E0",
            marginTop: "20px",
          }}
        >
          {history?.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#D1DDE4",
                  }}
                >
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    Icon
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    Service name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    Panel url
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    Description
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    Status payment
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      borderInlineEnd: "1px solid #3F78E0",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "#E5EDFF",
                      borderBlock: "2px solid #3F78E0",
                    }}
                  >
                    <TableCell
                      sx={{
                        textAlign: "center",
                        color: "#333333",
                        borderInlineEnd: "1px solid #3F78E0",
                      }}
                    >
                      {row.request_id}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderInlineEnd: "1px solid #3F78E0",
                        color: "#333333",
                      }}
                    >
                      <Box
                        className="icons"
                        sx={{
                          height: "40px",
                          display: "flex",
                          gap: "8px",
                          justifyContent: "center",
                          overflow: "hidden",
                          width: "100%",
                          paddingInline: "5px",
                        }}
                      >
                        {row?.ourServiceNameId ? (
                          <img
                            src={showIconByServiceId(row?.ourServiceNameId)}
                            alt="icon"
                            key={index}
                            style={{
                              height: "100%",
                              maxWidth: "50px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          "x"
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderInlineEnd: "1px solid #3F78E0",
                        color: "#333333",
                      }}
                    >
                      {row.ourServiceName}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderInlineEnd: "1px solid #3F78E0",
                        color: "#333333",
                      }}
                    >
                      {row.panel_url}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderInlineEnd: "1px solid #3F78E0",
                        color: "#333333",
                      }}
                    >
                      {row.title}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderInlineEnd: "1px solid #3F78E0",
                        color: "#333333",
                      }}
                    >
                      {row.description}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderInlineEnd: "1px solid #3F78E0",
                        color: "#333333",
                      }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderInlineEnd: "1px solid #3F78E0",
                        color: "#333333",
                      }}
                    >
                      {row.status_payment}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        color: "#333333",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {row?.ourServiceNameId === 1 ||
                      row?.ourServiceNameId === 10 ||
                      row?.ourServiceNameId === 11 ||
                      row?.ourServiceNameId === 9 ? (
                        <Button
                          sx={{
                            background: "#ECA601",
                            boxShadow: `
                          0px 3px 3px #00000040, 
                          inset 0px 4px 7px #FFFFFF40
                        `,
                            color: "white",
                            borderRadius: "6px",
                            width: "85px",
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            navigate(
                              `/dashboard/order-history/service-statistic/${row?.request_id}`
                            )
                          }
                        >
                          Statistics
                        </Button>
                      ) : (
                        row?.report && (
                          <Button
                            sx={{
                              backgroundColor: "#ECA601",
                              boxShadow: `
                         0px 3px 3px #00000040, 
                         inset 0px 4px 7px #FFFFFF40
                       `,
                              color: "white",
                              borderRadius: "6px",
                              width: "85px",
                              fontSize: "12px",
                            }}
                            onClick={() => handleDownload(row?.report)}
                          >
                            Report
                          </Button>
                        )
                      )}
                      <Button
                        sx={{
                          backgroundColor: "#3F78E0",
                          color: "#fff",
                          boxShadow: `
                          0px 3px 3px #00000040, 
                          inset 0px 4px 7px #FFFFFF40
                        `,
                          borderRadius: "6px",
                          width: "85px",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          navigate(
                            `/OurServices/${row?.ourServiceNameId}/${row?.ourServiceTypeId}`
                          );
                        }}
                      >
                        Re Order
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#D1DDE4" }}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#333333",
                      textAlign: "center",
                      border: "1px solid #3F78E0",
                    }}
                  >
                    there are no data yet
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          )}
        </TableContainer>
      </Box>
    </>
  );
};

export default OrderHistory;
