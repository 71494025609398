import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import EmailIcon from "../../../../../Assets/ContactIcons/email.png";
import WhatsappIcon from "../../../../../Assets/ContactIcons/whatsaap.svg";
import InstagramIcon from "../../../../../Assets/ContactIcons/instagram.png";
import TelegramIcon from "../../../../../Assets/ContactIcons/Telegram.svg";

const ContactInfo = ({ email, whatsapp, telegram, instagram }) => {
    const contactMethods = [
        {
            type: "Email",
            value: email,
            href: `mailto:${email}`,
            icon: EmailIcon,
        },
        {
            type: "WhatsApp",
            value: whatsapp,
            href: `https://wa.me/${whatsapp}`,
            icon: WhatsappIcon,
        },
        {
            type: "Telegram",
            value: telegram,
            href: `https://t.me/${telegram}`,
            icon: TelegramIcon,
        },
        {
            type: "Instagram",
            value: instagram,
            href: `https://instagram.com/${instagram}`,
            icon: InstagramIcon,
        },
    ];

    return (
        <Box>
            <Typography
                sx={{
                    color: "#474747",
                    lineHeight: "25px",
                    fontSize: "20px",
                    fontWeight: "600",
                }}
            >
                Contact info
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: "5px",
                    justifyContent: "space-between",
                }}
            >
                {contactMethods.map(
                    ({ type, value, href, icon }) =>
                        value && (
                            <Grid key={type} item xs={5} sm={5} md={5} lg={5}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "4px",
                                    }}
                                >
                                    <img src={icon} alt={type} style={{ width: "20px", height: "20px" }} />
                                    <Typography
                                        component="a"
                                        href={href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            lineHeight: "20px",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            color: "#474747",
                                            textDecoration: "none",
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {value}
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                )}
            </Grid>
        </Box>
    );
};

export default ContactInfo;
