import { styled, css, keyframes } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    padding-inline: 20px;
    .title {
      font-size: 24px;
      font-weight: 600;
      color: #474747;
      margin-bottom: 15px;
    }
    .container {
      display: flex;
    }
  `
);
