// src/UserDashboard/UserApp.js
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./layout/Sidebar";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";

const UserApp = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flex: 1 }}>
        <div style={{ flex: 1, overflowY: "auto", padding: "20px" }}>
          <Outlet /> {/* This is where child routes will be rendered */}
        </div>
      </div>
    </div>
  );
};

export default UserApp;
