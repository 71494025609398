import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    .footer {
      background: linear-gradient(135deg, #357dab, #30719B, #153245);
      color: white;
      font-family: system-ui;
      z-index: 999;
      
    }

    .social-icons {
      margin-top: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
      gap: 15px;
      justify-items: center;
    }

    .social-icons .item {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    .social-icons .item:hover {
      transform: scale(1.1);
      background-color: #e0e0e0;
    }

    .social-icons img {
      width: 24px;
      height: 24px;
    }

    .footerroute {
      color: inherit;
      text-decoration: none;
      margin-bottom: 0.8rem;
      font-weight: 500;
      transition: color 0.3s ease;
    }

    .footerroute:hover {
      text-decoration: underline;
      color: #f1c40f;
    }

    .footer-title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 1rem;
    }

    .footer-description {
      font-size: 14px;
      line-height: 1.6;
      padding-top: 10px;
    }

    .footer-contact {
      margin-top: 20px;
      font-size: 14px;
    }

    .footer-contact span {
      display: block;
      margin-bottom: 8px;
    }

    .footer-divider {
      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px solid white;
      text-align: center;
    }

    .footer-divider p {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      .social-icons {
        grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
      }

      .footer-description {
        text-align: center;
      }
    }
  `
);
