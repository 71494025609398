import { React, useState, useEffect, useRef } from "react";
import { useAuth } from "../../../AuthContext/AuthContext";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Helper } from "../../../tools/Helper";
import { api_Routes } from "../../../api_Routes";
import { useSnackbar } from "notistack";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import dayjs from "dayjs";
import Flag from "react-world-flags";
import DataTableColumn from "./PanelDetails/data-table-column";
import Details from "./PanelDetails/Details/GeneralDetails";
import FeedbackReviews from "./Reveiws/FeedbackReviews";
import RatingStatistics from "./Reveiws/RatingStatistics";
import MainLoader from "../../../components/common/main-loader";
import NoPanelWrapper from "./no-panel";
const MyPanel = () => {
  const [Panel, setPanel] = useState();
  const [ratingPercentages, setRatingPercentages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [averageRating, setAverageRating] = useState("2.6");
  const [isLoading, setIsLoading] = useState(true);
  const [isPanelsLoading, setIsPanelsLoading] = useState(true);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [columns, setColumns] = useState([]);
  const [Review, setReview] = useState([]);

  const baseApi = api_Routes.Providers.getPanelByAuth;
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    get_Panels(signal);

    return () => {
      controller.abort();
    };
  }, [page, limit]);

  const panelIdRef = useRef(null);
  const calculateDuration = (date) => {
    if (!date) return "Unknown";

    const now = dayjs();
    const registeredDate = dayjs(date);
    const years = now.diff(registeredDate, "year");
    const months = now.diff(registeredDate.add(years, "year"), "month");

    // Build the duration string
    const yearText = years > 0 ? `${years} year${years > 1 ? "s" : ""}` : "";
    const monthText =
      months > 0 ? `${months} month${months > 1 ? "s" : ""}` : "";

    return [yearText, monthText].filter(Boolean).join(" ");
  };
  // const duration = calculateDuration(Panel.domain_registered);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const get_Panels = async (signal) => {
    let url = `${baseApi}?page=${page + 1}&limit=${limit}`;
    const { response, message } = await Helper.Get({
      url: url,
      hasToken: true,
      signal: signal,
    });
    if (response && response.success) {
      const { data, pagination } = response;
      panelIdRef.current = response.data.panel_details.id;
      setIsLoading(false);
      setPanel({
        id: response.data.panel_details.id,
        name: response.data.panel_details.name,
        email: response.data.panel_details.email,
        logo: response.data.panel_details.logo,
        customLogo: response.data.panel_details.customLogo,
        description: response.data.panel_details.description,
        trust: response.data.panel_details.trust,
        screenShot: response.data.panel_details.screenShot,
        url: response.data.panel_details.url,
        whatsapp: response.data.panel_details.whatsapp,
        rate: response.data.rate.avg,
        telegram: response.data.panel_details.telegram,
        instagram: response.data.panel_details.instagram,
        tel1: response.data.panel_details.tel1,
        tel2: response.data.panel_details.tel2,
        tel3: response.data.panel_details.tel3,
        trust: response.data.panel_details.trust,
        domain_registered: response.data.panel_details.domain_registered,
        payment_method: response.data.payment_method,
        count_review: response.data.count_review,
        country_code: response.data.panel_details.country_code,
        country_name: response.data.panel_details.country_name,
      });
      setReview([]);
      response.data.reviews.forEach((ele) => {
        setReview((prev) => [
          ...prev,
          {
            id: ele.id,
            username: ele.user_name,
            rate: ele.rate,
            description: ele.description,
            subject: ele.subject,
            user_photo: ele.user_photo,
            created_at: ele.created_at,
          },
        ]);
      });
      setTotalItems(data.pagination.total);
      setDataTable(
        data.services.map((panel) => ({
          id: panel.id,
          name: panel.name,
          panelUrl: panel.panelUrl,
          rate_per_1000: panel.rate_per_1000,
          rate: panel.rate,
          order_range: `${panel.min_order} / ${panel.max_order}`,
          category: panel.category,
          action: <Typography style={{ color: "#2F64AE" }}>Review</Typography>,
        }))
      );
      setColumns([
        { id: "id", label: "ID", minWidth: 50 },
        { id: "name", label: "Service", minWidth: 100 },
        { id: "rate", label: "Rate", minWidth: 50 },
        { id: "order_range", label: "Min & Max", minWidth: 100 },
        { id: "category", label: "Category", minWidth: 200 },
        { id: "action", label: "Action", minWidth: 100 },
      ]);
      const percentages = data.rate.percentage;
      setRatingPercentages([
        { label: "5 Stars", value: percentages.percentage_rate_5 },
        { label: "4 Stars", value: percentages.percentage_rate_4 },
        { label: "3 Stars", value: percentages.percentage_rate_3 },
        { label: "2 Stars", value: percentages.percentage_rate_2 },
        { label: "1 Star", value: percentages.percentage_rate_1 },
      ]);
      setAverageRating(data.rate.avg || 0);
    } else {
      setIsLoading(false);
      enqueueSnackbar(message, { variant: "error" });
    }
    setIsPanelsLoading(false);
  };
  return isPanelsLoading ? (
    <MainLoader isFull={true} />
  ) : isLoading || isPanelsLoading || Panel ? (
    <>
      <div>
        <Details Panel={Panel} />
        <Box sx={{ padding: { xs: "30px", sm: "30px 50px", lg: "30px 80px" } }}>
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "25px", lg: "30px" },
              lineHeight: "40px",
              fontWeight: "700",
              color: "#474747",
              marginBottom: "20px",
            }}
          >
            Panel Services
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <CardContent sx={{ background: "#F8FAFF" }}>
                  {isLoading && <LinearProgress />}
                  {dataTable?.length === 0 ? (
                    <Typography sx={{ textAlign: "center" }}>
                      There are no services for this panel yet
                    </Typography>
                  ) : (
                    <TableContainer style={{ background: "#F8FAFF" }}>
                      <Table stickyHeader sx={{ background: "#F8FAFF" }}>
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                key={column.id}
                                style={{
                                  background: "#F8FAFF",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "#474747",
                                  border: "1px solid #00000080",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataTable.map((row) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column, index) => {
                                const value = row[column.id];
                                return (
                                  <DataTableColumn
                                    row={row}
                                    column={column}
                                    value={value}
                                  />
                                );
                              })}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {dataTable?.length > 0 && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                        count={totalItems}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleLimitChange}
                        sx={{
                          "& .MuiTablePagination-toolbar": {
                            justifyContent: "space-between",
                            width: "100%",
                            padding: 0,
                          },
                          "& .MuiTablePagination-actions": {
                            display: "flex",
                            justifyContent: "flex-end",
                            "& .MuiIconButton-root": {
                              color: "#004571",
                              backgroundColor: "#F1F7FF",
                              margin: "0 4px",
                              borderRadius: "0%",
                              "&:hover": {
                                backgroundColor: "#357DAB",
                              },
                              "& svg": {
                                fontSize: "2rem",
                              },
                            },
                          },
                          "& .MuiTablePagination-selectLabel": {
                            color: "#004571",
                            fontWeight: "bold",
                            fontSize: "0.875rem",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#F1F7FF",
                            borderRadius: "4px",
                            padding: "4px",
                            marginLeft: "8px",
                            "& .MuiSelect-select": {
                              color: "#004571",
                              padding: "4px 8px",
                              fontWeight: "bold",
                            },
                            "&:hover": {
                              backgroundColor: "#E0F3FF",
                            },
                          },
                          "& .Mui-disabled .MuiSvgIcon-root": {
                            color: "#E0F3FF",
                          },
                          "& .MuiTablePagination-displayedRows": {
                            color: "#004571", // Customize text color
                            fontSize: "0.875rem", // Adjust font size
                            fontWeight: "bold", // Make text bold
                            marginRight: "16px", // Add some spacing on the right if needed
                          },
                        }}
                      />
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ padding: { xs: "30px", sm: "30px 50px", lg: "30px 80px" } }}>
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "25px", lg: "30px" },
              fontWeight: "700",
              color: "#474747",
              marginBottom: "20px",
              textAlign: { xs: "center", md: "start" },
            }}
          >
            Feedback & Reviews
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "60px",
            }}
          >
            <FeedbackReviews reviews={Review} isLoading={isLoading} />
            <RatingStatistics
              averageRating={averageRating}
              ratingPercentages={ratingPercentages}
            />
          </Box>
        </Box>
      </div>
    </>
  ) : (
    <NoPanelWrapper />
  );
};
export default MyPanel;
