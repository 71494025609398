import React from "react";
import { Avatar, Box, Typography } from "@mui/material";

const FeedbackReviews = ({ reviews, isLoading }) => {
    return (
        <Box
            sx={{
                background: "#F8FAFF",
                width: { xs: "100%", md: "50%" },
                boxShadow: "-4px 4px 4px #00000040",
                border: "1px solid #959595",
                borderRadius: "25px",
                maxHeight: "700px",
                overflow: "hidden",
            }}
        >
            <Box
                sx={{
                    overflow: "auto",
                    height: "100%",
                    "&::-webkit-scrollbar": { width: "6px" },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#3F78E0",
                        borderRadius: "10px",
                    },
                }}
            >
                {isLoading ? (
                    <Typography sx={{ padding: "20px" }}>Loading...</Typography>
                ) : reviews.length > 0 ? (
                    reviews.map((review) => (
                        <Box
                            key={review.id}
                            sx={{
                                padding: "30px",
                                borderBottom: "1px solid #D9D9D9",
                            }}
                        >
                            <Box sx={{ display: "flex", gap: "10px" }}>
                                <Avatar
                                    src={review.user_photo}
                                    alt="person"
                                    sx={{
                                        border: "1px solid #959595",
                                        width: "60px",
                                        height: "60px",
                                    }}
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: "500",
                                            fontSize: "25px",
                                        }}
                                    >
                                        {review.username}
                                    </Typography>
                                    <Typography sx={{ color: "#959595" }}>
                                        {review.created_at}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: "10px" }}>
                                <Typography>{review.subject}</Typography>
                                <Typography>{review.description}</Typography>
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Typography sx={{ padding: "20px" }}>
                        No reviews yet
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default FeedbackReviews;
