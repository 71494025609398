import { Box, Typography } from "@mui/material";
import React from "react";

const WhyUs = () => {

    return (
        <>
            <Box sx={{ marginTop: "80px", display: "flex", flexDirection: "column", alignItems: "center", gap: "80px", marginInline: "60px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Typography sx={{
                        fontWeight: "700", fontSize: "35px", lineHeight: "35px",
                        color: "#474747", textAlign: "center"
                    }} >

                        Why Us
                    </Typography>

                    <Typography sx={{ marginTop: "20px", textAlign: "center" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nulla nec ultricies lorem. Proin quis magna ut eros variu
                        s dignissim. Sed sit amet dui varius, commodo turpis ac, auctor
                        lorem. Vestibulum faucibus ex non risus cursus, non sollicitudin odio malesuada. Suspendisse potenti. Integer at arcu non orci convallis varius at at ligula. In scelerisque justo nec facilisis
                        pharetra. Cras varius augue eget turpis gravida, ut scelerisque libero elementum.
                    </Typography>
                </Box>
                {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Typography sx={{
                        fontWeight: "700", fontSize: "35px", lineHeight: "35px",
                        color: "#474747", textAlign: "center"
                    }} >

                        Our Features
                    </Typography>

                    <Typography sx={{ textAlign: "center", marginTop: "20px" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec ultricies lorem.
                    </Typography>

                </Box> */}
            </Box>


        </>
    )
}
export default WhyUs;