import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { api_Routes } from "../../../api_Routes";
import { Helper } from "../../../tools/Helper";
import React, { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import MainLoader from "../../../components/common/main-loader";

const Tickets = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [category, setCategory] = useState([]);
  const [selectedGategories, setselectedGategories] = useState(null);
  const [History, setHistory] = useState([]);
  const [pageloading, setPageLoading] = useState(true);
  const [formData, setFormData] = useState({
    message: "",
    title: "",
  });

  useEffect(() => {
    get_categories();
    get_History();
  }, []);
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const get_categories = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.dashboard.tickets.view,
      hasToken: true,
      data: {
        results: 190,
      },
    });
    if (response) {
      setCategory(
        response.data.map((category) => ({
          id: category.id,
          name: category.name,
        }))
      );
    } else {
    }
  };
  const get_History = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.dashboard.tickets.get_history,
      hasToken: true,
      data: {
        results: 190,
      },
    });
    if (response) {
      setPageLoading(false);
      setHistory(
        response.data.map((history) => ({
          id: history.id,
          title: history.title,
          category: history.category,
          status: history.status,
          lastUpdate: history.updated_at,
        }))
      );
    } else {
      setPageLoading(false);
    }
    setPageLoading(false);
  };
  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  const handleSubmit = async () => {
    const { message, title } = formData;
    const requiredFields = ["message", "title"];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (!selectedGategories) {
      missingFields.push("category");
    }

    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Please fill in all required fields: ${missingFields.join(", ")}`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    const form_data = {
      message,
      title,
      category_id: selectedGategories, // Send the selected category ID
    };

    const { response, message: ApiMessage } = await Helper.Post({
      url: api_Routes.dashboard.tickets.add,
      data: form_data,
      hasToken: true,
    });

    if (response) {
      enqueueSnackbar(ApiMessage, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setFormData({ message: "", title: "" });
      setselectedGategories(null);
      get_History();
    } else {
      let errorApiMessage = "";
      if (typeof ApiMessage === "string") {
        errorApiMessage = ApiMessage;
      } else if (typeof ApiMessage === "object") {
        errorApiMessage = Object.values(ApiMessage).flat().join(", ");
      }
      console.error("Error submitting ticket:", errorApiMessage);
    }

    setIsLoading(false);
  };

  return (
    <>
      {pageloading ? (
        <MainLoader isFull={true} />
      ) : (
        <Box>
          <Typography
            sx={{
              color: "#474747",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "30px",
            }}
          >
            Send Your Tickets here
          </Typography>
          <Container
            sx={{
              background: "#EBF2FF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "50px",
              padding: { xs: "20px", md: "50px" },
              marginTop: "50px",
              borderRadius: "26px",
            }}
          >
            <FormControl
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
            >
              <Autocomplete
                options={category}
                getOptionLabel={(option) => option.name || ""}
                value={
                  category.find((cat) => cat.id === selectedGategories) || null
                }
                onChange={(event, newValue) => {
                  setselectedGategories(newValue ? newValue.id : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Category"
                    variant="outlined"
                    InputLabelProps={{
                      sx: {
                        color: "#474747",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "20px",
                        "&.Mui-focused": {
                          color: "#007BFF",
                        },
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                ListboxProps={{
                  sx: {
                    maxHeight: "200px",
                    backgroundColor: "#f5f8fe",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    "& .MuiAutocomplete-option": {
                      "&[aria-selected='true']": {
                        backgroundColor: "#007bff",
                        color: "#fff",
                      },
                      "&[data-focus='true']": {
                        backgroundColor: "#007bff",
                      },
                    },
                  },
                }}
                sx={{
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#707275",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#707275",
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: "10px 12px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            </FormControl>

            <TextField
              fullWidth
              placeholder="Type your Ticket title here."
              value={formData.title}
              onChange={(e) => handleChange("title", e.target.value)}
              variant="outlined"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "-2px 4px 4px #00000040",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& input::placeholder": {
                    color: "black",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "700",
                  },
                },
              }}
            />
            <TextField
              fullWidth
              placeholder="Type your Ticket message here."
              variant="outlined"
              value={formData.message}
              onChange={(e) => handleChange("message", e.target.value)}
              multiline
              rows={6}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "-2px 4px 4px #00000040",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& textarea::placeholder": {
                    color: "black",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "700",
                  },
                },
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  background: "#ECA601",
                  padding: "10px 50px",
                  borderRadius: "10px",
                  color: "#F1F5FD",
                  fontWeight: "700",
                  fontSize: "18px",
                  boxShadow: "0px 3px 3px #00000040",
                }}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <CircularProgress size={22} sx={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                width: "100%",
                gap: "20px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "30px",
                  color: "#474747",
                  textAlign: "left",
                }}
              >
                Your Tickets History
              </Typography>
              {History?.length > 0 ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    border: "2px solid #3F78E0",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: "#D1DDE4",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#333333",
                            textAlign: "center",
                            border: "1px solid #3F78E0",
                          }}
                        >
                          ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#333333",
                            textAlign: "center",
                            border: "1px solid #3F78E0",
                          }}
                        >
                          title
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#333333",
                            textAlign: "center",
                            border: "1px solid #3F78E0",
                          }}
                        >
                          category
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#333333",
                            textAlign: "center",
                            border: "1px solid #3F78E0",
                          }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#333333",
                            textAlign: "center",
                            border: "1px solid #3F78E0",
                          }}
                        >
                          Last Update
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {History.map((row) => (
                        <TableRow
                          key={row.index}
                          sx={{
                            backgroundColor: "#E5EDFF",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#bec5dc",
                            },
                          }}
                          onClick={() => {
                            navigate(`/dashboard/tickets/${row.id}`);
                          }}
                        >
                          <TableCell
                            sx={{
                              textAlign: "center",
                              color: "#333333",
                              border: "1px solid #3F78E0",
                            }}
                          >
                            {row.id}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              color: "#333333",
                              border: "1px solid #3F78E0",
                            }}
                          >
                            {row.title}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              color: "#333333",
                              border: "1px solid #3F78E0",
                            }}
                          >
                            {row.category}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              color: "#333333",
                              border: "1px solid #3F78E0",
                            }}
                          >
                            {row.status}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              color: "#333333",
                              border: "1px solid #3F78E0",
                            }}
                          >
                            {row.lastUpdate}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer
                  fullWidth
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#D1DDE4" }}>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#333333",
                            textAlign: "center",
                            border: "1px solid #3F78E0",
                          }}
                        >
                          there are no tickets yet
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Tickets;
