import React from 'react';
import { Box, Typography, Grid, Paper, Container } from '@mui/material';

import OurCompany from "./OurCompany"
import WhyUs from './WhyUs';
import OurFeatures from "./OurFeatures"

const AboutUs = () => {
    return (
        <Container
            sx={{
                marginBlock: "30px",
                "@media (min-width: 1200px)": {
                    maxWidth: "1400px",
                },

            }}
        >
            <OurCompany />
            <WhyUs />
            <OurFeatures />
        </Container>
    );
};

export default AboutUs;
