import React from "react";
import { Styles } from "./styles";
import TotalCard from "./total-card";

const TotalServices = ({ data }) => {
  return (
    <Styles>
      <div className="title">Total Services</div>
      <div className="cards">
        <TotalCard
          countServicesPending={data?.countServicesPending?.toString()}
          countOffersPending={data?.countOffersPending?.toString()} //
          backgroundColor={"#F0F0F0"}
        />
        <TotalCard
          countServicesActive={data?.countServicesActive?.toString()}
          countOffersActive={data?.countOffersActive?.toString()}
          backgroundColor={"#BDFDAE"}
        />
        <TotalCard
          countServicesCOMPLETE={data?.countServicesCOMPLETE?.toString()}
          countOffersCOMPLETE={data.countOffersCOMPLETE?.toString()}
          backgroundColor={"#9BC4DE"}
        />
      </div>
    </Styles>
  );
};

export default TotalServices;
