import BlukSkype from "../Assets/images/dashboard/icons/BlukSkype.svg";
import BlukTelegram from "../Assets/images/dashboard/icons/BlukTelegram.svg";
import BlukWhatsapp from "../Assets/images/dashboard/icons/BlukWhatsapp.svg";
import MainPage from "../Assets/images/dashboard/icons/mainPage.svg";
import pinDown from "../Assets/images/dashboard/icons/pinDown.svg";
import PinUp from "../Assets/images/dashboard/icons/PinUp.svg";
import searchResult from "../Assets/images/dashboard/icons/searchResult.svg";
import skypeGroup from "../Assets/images/dashboard/icons/skypeGroup.svg";
import ticket from "../Assets/images/dashboard/icons/ticket.svg";
import whatsappGroup from "../Assets/images/dashboard/icons/whatsappGroup.svg";
import emailGroup from "../Assets/images/dashboard/icons/emailBulk.svg";

export const showIconByServiceId = (id) => {
  if (id === 6) {
    return BlukSkype;
  }
  if (id === 7) {
    return BlukTelegram;
  }
  if (id === 5) {
    return BlukWhatsapp;
  }
  if (id === 8) {
    return emailGroup;
  }
  if (id === 9) {
    return MainPage;
  }
  if (id === 11) {
    return pinDown;
  }
  if (id === 10) {
    return PinUp;
  }
  if (id === 1) {
    return searchResult;
  }
  if (id === 2) {
    return skypeGroup;
  }
  if (id === 4) {
    return ticket;
  }
  if (id === 3) {
    return whatsappGroup;
  }
};
