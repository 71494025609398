import { React } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Helper } from "../../../../tools/Helper";
import { api_Routes } from "../../../../api_Routes";
const PinUp = ({ pinup }) => {
  const navigate = useNavigate();
  const pinClick = async (id) => {
    const { response, message } = await Helper.Post({
      url: api_Routes.Pin.AddStatistics + id,
      data: { eventType: "click" },
      hasToken: false,
    });
  };

  return (
    pinup?.length > 0 && (
      <>
        <Box sx={{ background: "#92BBD5" }}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", padding: "30px" }}
          >
            {pinup.map((up, index) => (
              <Grid
                item
                xs={12}
                sm={pinup.length < 2 ? 12 : 6}
                md={pinup?.length > 3 ? 3 : pinup.length > 1 ? 6 : 12}
                key={index}
                sx={{ position: "relative" }}
              >
                <Box sx={{ position: "absolute" }}>
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1233 14.4635L16.8588 18.2585C17.4559 18.8651 18.2635 19.2546 19.1933 19.3824C19.1936 19.3801 19.194 19.3778 19.192 19.3758C19.098 18.4662 18.7312 17.6652 18.1361 17.0606L14.4006 13.2657L13.1233 14.4635Z"
                      fill="#4D4D4D"
                    />
                    <path
                      d="M8.68752 17.176C9.6105 16.9272 11.035 16.3258 13.1405 14.4849C13.3508 14.2995 13.5696 14.1041 13.7929 13.8947L13.8474 13.8436C14.0489 13.6546 14.2406 13.4689 14.4222 13.2889C16.4562 11.2636 17.1445 9.8718 17.4403 8.96984C17.7397 7.87994 17.1031 7.27307 16.3164 6.83833C14.9096 6.21524 13.2702 6.82172 11.6416 7.71853C11.6212 7.72977 11.6009 7.74101 11.5805 7.75224L7.6758 11.4101C7.66692 11.4223 7.66005 11.4366 7.65116 11.4489C6.66216 13.0087 5.96756 14.591 6.55081 15.9923C6.96438 16.7769 7.55982 17.4244 8.68752 17.176Z"
                      fill="#F90B0B"
                    />
                    <path
                      d="M3.23935 6.70044C3.81984 6.2739 4.46527 5.7138 5.13216 5.09038C5.15122 5.0725 5.17029 5.05462 5.18935 5.03674C5.8566 4.41102 6.47887 3.79015 6.96181 3.22727C7.81958 2.22845 8.23867 1.41712 7.69118 1.10568C6.85683 0.633152 5.40935 1.65276 3.5508 3.3721C3.53173 3.38998 3.51267 3.40786 3.4936 3.42574C0.983787 5.75778 0.600494 6.86353 1.02432 7.34199C1.41469 7.78114 2.22826 7.4424 3.23935 6.70044Z"
                      fill="#F90B0B"
                    />
                    <path
                      d="M8.05825 11.8862C8.35854 11.9758 9.04442 11.635 9.81283 11.0323C10.0196 10.8698 10.2339 10.6885 10.4459 10.4916C10.4623 10.4763 10.4786 10.4609 10.495 10.4456C10.7237 10.2311 10.9343 10.014 11.1228 9.80381C11.7706 9.0785 12.1469 8.4232 12.0672 8.12675L11.9093 7.97433L11.6397 7.71647L6.95984 3.22811C6.47689 3.79099 5.85427 4.41415 5.18738 5.03758C5.16831 5.05546 5.14925 5.07334 5.13019 5.09121C4.4633 5.71464 3.81787 6.27474 3.23738 6.70127L7.65132 11.4489L7.90853 11.7261L8.05825 11.8862Z"
                      fill="#AE0002"
                    />
                  </svg>
                </Box>

                <Box
                  sx={{
                    background: "#F8FAFF",
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    padding: "20px",
                    borderRadius: "16px",
                    gap: "10px",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      background: "#E0E8FF",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  onClick={() => {
                    navigate(`/PanelsDetails/${up?.panelId}`);
                    pinClick(up?.id);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      lineHeight: "16px",
                      color: "#474747",
                    }}
                  >
                    {up.label}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#3F78E0",
                      fontWeight: "400",
                      lineHeight: "15px",
                      fontSize: "12px",
                    }}
                  >
                    {up.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    )
  );
};
export default PinUp;
