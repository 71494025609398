import { styled, css, borderRadius, minWidth } from "@mui/system";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #00000027",
  borderRadius: "15px",
  p: 4,
  pt: 1,
};

export const Styles = styled("div")(
  ({ theme }) => css`
    .modal-data {
      max-height: 400px;
      @media (max-height: 500px) {
        max-height: 600px;
      }
      overflow-y: auto;
      overflow-x: hidden;
      .main-data {
        word-break: break-all;
        padding-inline: 20px;
      }
    }

    .title {
      text-align: center;
      padding-top: 5px;
      font-size: 20px;
      color: #004571;
    }
    .description {
      font-size: 14px;
      color: #303030;
      text-align: center;
    }
  `
);
