import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";

const CustomDateInput = ({ label, value, onChange }) => {
  // Separate the date values or initialize as empty
  const [day, setDay] = useState(value ? value.split("-")[2] : "");
  const [month, setMonth] = useState(value ? value.split("-")[1] : "");
  const [year, setYear] = useState(value ? value.split("-")[0] : "");

  const handleDayChange = (val) => {
    setDay(val);
    // Call onChange with updated date
    onChange(`${year}-${month}-${val}`);
  };

  const handleMonthChange = (val) => {
    setMonth(val);
    // Call onChange with updated date
    onChange(`${year}-${val}-${day}`);
  };

  const handleYearChange = (val) => {
    setYear(val);
    // Call onChange with updated date
    onChange(`${val}-${month}-${day}`);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      sx={{ marginTop: "30px" }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          color: "#474747",
        }}
      >
        {label}:
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap={1}
        sx={{
          width: "50%",
        }}
      >
        <TextField
          value={day}
          onChange={(e) => handleDayChange(e.target.value)}
          placeholder="DD"
          required
          inputProps={{ maxLength: 2 }}
          sx={inputStyles}
        />
        <TextField
          value={month}
          onChange={(e) => handleMonthChange(e.target.value)}
          placeholder="MM"
          required
          inputProps={{ maxLength: 2 }}
          sx={inputStyles}
        />
        <TextField
          value={year}
          onChange={(e) => handleYearChange(e.target.value)}
          placeholder="YYYY"
          required
          inputProps={{ maxLength: 4 }}
          sx={{ ...inputStyles, width: "20% !important" }}
        />
      </Box>
    </Box>
  );
};

// Define shared input styles for reusability
const inputStyles = {
  width: "14%",
  textAlign: "center",
  boxShadow: "-2px 2px 4px #00000040",
  borderRadius: "10px",
  backgroundColor: "white",
  border: "0.5px solid #959595",
  "& .MuiInputBase-root": {
    color: "black !important",
    borderRadius: "10px",
    padding: "4px 8px", // Reduced padding
  },
  "& .MuiOutlinedInput-root": {
    borderWidth: "1px", // Adjusted border width
    "&:hover fieldset": {
      borderColor: "black !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black !important",
    },
  },
  "& .MuiInputBase-input": {
    padding: "3px", // Adjust input padding
    fontSize: "13px", // Adjust font size if needed
  },
};

export default CustomDateInput;
