import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";

// First SVG
const UserSVG1 = () => (
    <svg width="105" height="239" viewBox="0 0 105 239" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.2612 52.6049C28.378 50.9219 48.236 44.8844 48.5571 61.4207C48.5571 61.4207 85.7573 60.5124 67.9869 39.8086C61.8315 32.6491 62.4738 26.7185 62.6611 21.2153C62.8484 15.7121 58.834 -0.316645 45.0512 1.28623C45.0512 1.28623 41.4383 -0.183072 36.9421 2.14109C26.7187 7.45729 3.80985 58.6958 29.2612 52.6049Z" fill="#163560" />
        <path d="M90.0662 71.0914C90.0662 71.0914 92.4748 67.4048 94.4285 67.3513C96.3822 67.2712 99.9951 66.256 99.7007 66.9773C99.4063 67.6986 97.1583 67.7788 96.9442 68.2062C96.7301 68.6336 100.263 69.3015 101.28 69.3549C102.297 69.4351 104.679 69.3549 104.598 69.8625C104.518 70.3701 100.29 72.4538 97.3188 72.16C94.3482 71.8661 90.6549 73.0148 90.6549 73.0148L90.0662 71.0914Z" fill="#FFB27D" />
        <path d="M55.0337 72.2668C55.0337 72.2668 56.3451 74.8581 58.727 77.8501C61.6174 81.4566 66.0332 85.5974 71.5999 86.4255C74.2494 86.8262 76.8721 86.2385 79.254 85.1966C86.025 82.2046 91.1099 75.3123 91.1099 75.3123C92.3142 72.0531 90.3605 69.8625 90.3605 69.8625C90.3605 69.8625 76.7115 72.9347 75.4002 72.2668C74.0888 71.6257 66.5149 49.7999 64.6951 47.0483C62.8752 44.2967 56.7198 42.8808 56.7198 42.8808L55.0337 72.2668Z" fill="#B0D0FF" />
        <path d="M58.7537 77.8502C61.6441 81.4566 66.06 85.5974 71.6266 86.4255C74.2761 86.8262 76.8989 86.2385 79.2808 85.1967C79.0131 85.2234 72.2689 86.0515 69.5659 81.2429C66.8093 76.3274 63.8387 68.5535 59.7707 71.1448C58.0044 72.2668 58.0847 75.0451 58.7537 77.8502Z" fill="#98BAF4" />
        <path d="M4.07747 219.437C4.07747 219.437 -0.0975127 223.231 0.732132 225.929C1.53501 228.627 5.30856 230.417 5.81705 233.73C6.32554 237.015 8.35951 238.832 10.1794 238.832C11.9992 238.832 18.904 238.832 18.904 238.832C18.904 238.832 20.2422 236.508 17.0039 235.813C13.7388 235.119 12.5345 231.245 12.7218 227.639C12.9359 224.032 12.4274 219.25 12.4274 219.25L5.52266 217.647L4.07747 219.437Z" fill="#233862" />
        <path d="M39.3508 221.067C39.3508 221.067 37.852 237.149 39.6719 238.057L63.0893 238.004C63.0893 238.004 63.0893 235.306 59.3158 234.718C55.5422 234.13 48.9586 231.913 47.8345 227.024C46.7373 222.109 39.3508 221.067 39.3508 221.067Z" fill="#233862" />
        <path d="M22.6776 105.66C27.4949 94.8673 30.4388 91.7684 29.8768 88.4291C29.4485 85.9447 31.1614 41.9725 31.1614 41.9725C31.7234 41.7855 32.8742 41.6252 33.5165 41.4916C35.4969 41.0642 37.1295 39.675 37.7985 37.7516C38.6282 35.4541 39.2705 32.0614 39.2437 27.8405L39.8593 27.9741L50.2432 30.0311C50.2432 30.0311 50.0023 33.2903 50.0023 36.3624C50.0023 36.3892 50.0023 36.4159 50.0023 36.4426C50.0023 39.114 51.7419 41.4649 54.3111 42.2129C55.6225 42.5869 56.7733 42.9075 56.7733 42.9075C56.7733 42.9075 60.5736 53.7803 61.323 64.9203C62.0456 76.087 54.3914 80.9223 61.3765 103.229C68.4954 125.99 20.0013 111.617 22.6776 105.66Z" fill="#FFB27D" />
        <path d="M50.2432 30.0311C50.2432 30.0311 50.0023 33.2903 50.0023 36.3624C44.1948 36.2022 41.1171 30.9127 39.8593 27.9741L50.2432 30.0311Z" fill="#E8945B" />
        <path d="M52.1969 32.943C52.1969 32.943 38.4676 33.1567 38.4408 24.6348C38.4408 16.0861 36.6477 10.3692 45.4259 9.8616C54.2041 9.35402 55.9169 12.6132 56.6127 15.4717C57.3086 18.3301 55.7563 32.6491 52.1969 32.943Z" fill="#FFB27D" />
        <path d="M51.6884 9.62117C51.6884 9.62117 47.9148 19.6391 42.1608 21.1351C36.3801 22.6044 33.8912 21.0817 33.8912 21.0817C33.8912 21.0817 38.307 17.5821 39.2437 11.4378C39.2169 11.4378 48.8248 4.86598 51.6884 9.62117Z" fill="#163560" />
        <path d="M50.7517 10.4493C50.7517 10.4493 53.535 11.4912 54.6858 13.5215C55.8098 15.4984 56.7733 18.1431 56.5324 21.3756C56.5324 21.3756 59.3693 14.5634 56.1845 10.7165C52.8124 6.60243 50.7517 10.4493 50.7517 10.4493Z" fill="#163560" />
        <path d="M49.1994 27.6268C49.1994 27.6268 50.1361 28.8824 52.0363 28.7755C53.5618 28.6954 54.1506 27.3864 54.1506 27.3864C54.1506 27.3864 51.3405 26.7185 49.1994 27.6268Z" fill="#EBF3FA" />
        <path d="M21.3394 61.4207C21.3394 61.4207 20.9915 66.3362 21.3394 72.2134C21.5803 76.1137 25.5412 81.3498 26.1032 82.6588C27.5216 85.9179 29.1006 86.4255 29.1006 86.4255L41.3847 89.4443L45.7203 90.5129L59.3425 86.4522C59.3425 86.4522 65.5515 69.1412 63.0625 59.4171C60.5736 49.6663 56.7733 42.9075 56.7733 42.9075C56.7733 42.9075 45.64 53.7803 31.1614 41.9725L21.3394 61.4207Z" fill="#B0D0FF" />
        <path d="M24.9524 73.8163C25.1933 77.7166 25.5412 81.3498 26.1032 82.6588C27.5216 85.9179 29.1006 86.4255 29.1006 86.4255L41.3847 89.4443L46.7373 87.8681C46.7373 87.8681 28.9133 85.1699 30.8937 69.8625L24.9524 73.8163Z" fill="#F2CCAA" />
        <path d="M58.4861 89.0703C58.4861 89.0703 65.2303 110.041 65.6585 117.201C66.0867 124.36 52.7589 218.663 52.2236 221.948C51.7151 225.047 47.8613 227.024 47.8613 227.024C47.8613 227.024 46.095 228.066 43.5525 227.345C40.0466 226.356 38.9226 225.181 38.9226 225.181C38.9226 225.181 33.6771 157.914 36.9154 133.897C40.1804 109.881 40.2072 89.097 40.2072 89.097H58.4861V89.0703Z" fill="#ED7D2B" />
        <path d="M29.8768 88.4291C29.8768 88.4291 19.5731 102.214 18.6096 111.537C17.6729 120.861 17.1109 153.613 15.077 162.722C13.043 171.805 5.52266 189.303 5.52266 217.647C5.52266 217.647 6.29878 219.277 8.70743 220.132C9.48355 220.399 10.5005 220.666 11.5175 220.907C13.498 221.361 15.5587 220.399 16.4419 218.556C21.5268 208.351 37.7182 175.412 38.5479 168.573C39.1367 163.737 44.2483 131.707 55.3816 112.926C58.0579 108.412 53.7491 90.4861 53.7491 90.4861L29.8768 88.4291Z" fill="#F99746" />
        <path d="M49.4938 27.8672C49.4938 27.8672 49.7347 28.8824 52.2504 28.7221C52.2504 28.7221 54.3647 28.5885 54.2844 27.4932C54.2844 27.4665 52.2504 26.6918 49.4938 27.8672Z" fill="white" />
        <path d="M59.5566 85.8111C59.5566 85.8111 56.8536 87.9482 51.4743 88.0551C45.0512 88.1887 29.1006 86.4255 29.1006 86.4255C29.1006 86.4255 28.4048 88.4024 28.4316 90.4861C28.4316 90.4861 47.8881 95.3749 59.1819 91.2074C59.1552 91.2074 60.6004 88.4825 59.5566 85.8111Z" fill="#233862" />
        <path d="M50.0826 90.2724C50.0826 88.4558 51.394 86.9598 53.0265 86.9598C54.6323 86.9598 55.9704 88.4558 55.9704 90.2724C55.9704 92.089 54.659 93.585 53.0265 93.585C51.394 93.6117 50.0826 92.1157 50.0826 90.2724ZM51.0461 90.2724C51.0461 91.5547 51.9292 92.6233 52.9998 92.6233C54.0703 92.6233 54.9534 91.5814 54.9534 90.2724C54.9534 88.9901 54.0703 87.9215 52.9998 87.9215C51.9292 87.9483 51.0461 88.9901 51.0461 90.2724Z" fill="white" />
        <path d="M8.14305 89.5972L28.0853 106.93L51.8468 79.6898L31.9046 62.3569L8.14305 89.5972Z" fill="#B5453C" />
        <path d="M33.5968 89.6847C33.5968 89.6847 40.903 90.5396 40.8762 92.276C40.8495 94.0125 39.0564 97.5655 38.1464 97.7258C37.2365 97.8861 32.2051 92.9172 32.2051 92.9172L33.5968 89.6847Z" fill="#FFB27D" />
        <path d="M31.1614 41.9725C31.1614 41.9725 21.4197 42.6671 17.6729 51.7233C13.9262 60.8063 6.83404 76.0336 6.83404 78.5982C6.83404 81.1628 10.7949 89.0703 32.0713 93.6117C32.0713 93.6117 34.2658 91.2876 34.132 89.471C34.132 89.471 20.8577 78.0906 20.8845 76.7549C20.9648 74.2437 23.0523 70.9044 34.7208 59.2568C37.9056 56.0778 31.1614 41.9725 31.1614 41.9725Z" fill="#B0D0FF" />
    </svg>

);

// Second SVG
const UserSVG2 = () => (
    <svg width="92" height="267" viewBox="0 0 92 267" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.4988 266.319C61.4988 266.319 62.1375 261.471 63.5974 258.58C65.0573 255.689 74.3033 257.526 74.3033 257.526C74.3033 257.526 76.1586 259.092 76.5539 266.44L61.4988 266.319Z" fill="#233862" />
        <path d="M29.9286 266.229C32.1185 264.904 30.8715 255.087 30.8715 255.087C30.8715 255.087 20.713 247.046 19.3444 253.189C17.9453 259.393 9.70302 262.133 5.0192 262.886C1.76485 263.428 0.761176 265.205 0.457031 266.229H29.9286Z" fill="#233862" />
        <path d="M50.945 194.858C50.5192 197.116 50.2759 198.953 50.215 200.278C49.2722 218.498 34.7341 260.146 32.1185 261.109C28.1038 262.615 21.7471 259.423 18.432 258.369C17.0633 257.947 16.1205 256.562 16.4246 255.177C23.6632 222.382 26.3093 175.193 27.2521 147.247C27.7388 133.184 30.1719 123.457 30.1719 123.457C30.1719 123.457 65.6352 114.091 65.8481 125.926C66.0306 136.436 54.1081 177.572 50.945 194.858Z" fill="#213F7A" />
        <path d="M50.945 194.858L27.2521 147.247C27.7388 133.184 30.1719 123.457 30.1719 123.457C30.1719 123.457 65.6352 114.091 65.8481 125.926C66.0306 136.436 54.1081 177.572 50.945 194.858Z" fill="#233862" />
        <path d="M43.0677 125.986L61.803 260.507C61.803 260.507 70.0453 262.465 76.4323 260.899C76.4323 260.899 81.9069 189.347 81.4507 172.061C80.9945 154.776 85.4046 125.776 80.447 122.583C75.5199 119.391 43.0677 125.986 43.0677 125.986Z" fill="#213F7A" />
        <path d="M84.1271 123.758C84.2184 124.962 83.4276 126.077 82.2414 126.408C79.6258 127.161 76.6148 127.673 70.8056 128.877C65.331 129.992 58.0011 130.985 48.9681 131.046C34.6124 131.136 29.1683 126.227 29.1683 126.227C28.56 126.227 29.5028 123.276 30.3544 109.122C30.6281 104.334 30.9323 98.251 31.1148 90.5719C31.2973 83.6757 31.4189 75.4545 31.4189 65.7276C31.4493 54.0131 49.7588 41.4253 50.0934 41.4253C50.0934 43.1117 52.7699 46.2135 56.9062 46.4845C61.8029 46.8157 67.825 44.3765 67.825 41.5759C67.825 41.5457 76.706 55.6393 79.8387 70.6362C81.4507 78.1949 80.812 77.9841 84.1271 123.758Z" fill="#B0D0FF" />
        <path d="M50.0934 41.3952C50.0934 41.3952 33.0613 41.2446 28.9554 46.9362C24.8494 52.6278 15.8771 83.224 20.561 93.4327C25.2448 103.611 34.0346 89.2168 38.9009 82.9831C43.7672 76.7494 50.0934 41.3952 50.0934 41.3952Z" fill="#B0D0FF" />
        <path d="M49.9109 38.8354V40.5821C49.9109 40.5821 49.4243 46.0328 56.2067 46.8158C62.9891 47.6288 68.0075 45.7919 67.825 41.606C67.2167 38.3536 66.6389 35.0109 66.6084 32.3006C66.578 31.3069 66.6693 30.2227 66.8518 29.0483L53.7127 28.7773L49.8501 29.4097L49.9109 38.8354Z" fill="#FFB27D" />
        <path d="M49.8805 29.4097L49.9413 38.8354C51.3404 39.4678 52.922 39.8593 54.686 39.8593C60.708 39.8593 64.6315 35.4626 66.6693 32.3006C66.6389 31.3069 66.7301 30.2227 66.9126 29.0483L53.7736 28.7773L49.8805 29.4097Z" fill="#E2915A" />
        <path d="M67.8858 26.9403C66.3651 35.0712 55.7809 34.8001 51.2187 34.2882C49.9413 34.1376 49.1201 33.987 49.1201 33.987C47.6603 33.5956 46.8695 31.337 46.4741 28.3858C45.7137 22.5135 46.6566 13.9008 47.6603 11.7024C49.181 8.38985 52.0095 4.80624 62.6546 7.39607C73.33 9.95579 69.8324 16.5508 67.8858 26.9403Z" fill="#FFB27D" />
        <path d="M32.6659 64.2218C32.6659 64.2218 26.066 78.6165 26.2789 82.953C26.2789 82.953 29.5332 82.8325 32.6659 83.7661C37.8364 85.2718 32.6659 64.2218 32.6659 64.2218Z" fill="#98BAF4" />
        <path d="M44.3755 3.96304C44.3755 3.96304 49.0593 3.60166 54.5947 1.22263C59.0961 -0.704687 64.7532 1.40332 66.578 2.36698C66.5172 2.06583 66.3955 1.67435 66.1218 1.28286C66.1218 1.28286 66.943 1.85503 67.0647 2.66812C67.1559 2.72835 67.1863 2.75846 67.1559 2.78858C67.1559 2.78858 68.0987 2.60789 68.707 2.87892C68.707 2.87892 68.2812 2.81869 68.1292 3.08972C68.0988 3.11984 68.0988 3.14995 68.0683 3.14995C75.0637 6.49264 75.7328 17.4543 73.4517 20.014C70.7448 23.0555 66.6389 29.9818 66.6389 29.9818C66.6389 29.9818 66.3043 28.3255 66.7909 25.6454C67.2776 22.9652 64.9357 22.9652 61.9855 21.7305C59.0353 20.4958 61.4988 17.0929 58.9136 15.015C56.3284 12.9371 46.8391 14.1718 46.9607 12.5757C43.4631 11.3109 44.3755 4.32441 44.3755 3.96304Z" fill="#233862" />
        <path d="M70.0149 91.2043C67.2471 102.256 37.1673 107.978 30.324 109.122C30.5977 104.334 30.9019 98.2511 31.0844 90.5719L70.0149 91.2043Z" fill="#98BAF4" />
        <path d="M63.4149 94.7879C63.6886 94.7879 70.1061 93.8544 71.2314 93.2822C72.3872 92.71 77.6185 85.332 74.1816 83.4649C70.7448 81.5677 61.5292 89.1264 61.5292 89.1264L63.4149 94.7879Z" fill="#FFB27D" />
        <path d="M24.5148 96.9863C24.5148 96.9863 49.2418 98.3113 65.8481 94.7879C65.8481 94.7879 66.2739 90.0298 61.0426 87.9218C61.0426 87.9218 35.1599 82.5013 28.9553 84.007C22.7508 85.5127 24.5148 96.9863 24.5148 96.9863Z" fill="#B0D0FF" />
        <path d="M81.329 77.7432C76.189 83.8865 69.4674 88.8855 62.0767 90.06C62.0159 89.9696 59.826 86.6871 60.2518 81.8689C60.4343 79.6404 61.1947 77.0807 62.9587 74.3704C63.5062 73.5272 63.9928 72.5635 64.4186 71.4794C68.4637 61.2706 67.8554 41.606 67.8554 41.606C67.8554 41.606 85.0092 43.3827 90.3621 50.9414C93.6165 55.5489 89.4497 68.0765 81.329 77.7432Z" fill="#B0D0FF" />
        <path d="M51.1883 34.2882C49.9109 34.1376 49.0897 33.987 49.0897 33.987C47.6298 33.5956 46.839 31.337 46.4436 28.3858C49.1505 29.0483 50.3975 31.1262 50.945 32.0296C51.1883 32.4211 51.2187 33.2944 51.1883 34.2882Z" fill="#E2915A" />
        <path d="M44.2842 38.8957C44.2842 38.8957 38.3534 29.1989 42.9764 28.7773C47.5994 28.3557 49.4243 31.4574 50.0934 32.5717C50.7625 33.716 49.9109 38.8354 49.9109 38.8354L44.2842 38.8957Z" fill="#FFB27D" />
        <path d="M66.8822 81.5677C66.6997 82.8024 66.3347 83.9166 65.8785 84.9405C64.4186 88.1326 62.1071 90.0901 62.1071 90.0901C46.7174 83.224 43.6151 59.5842 43.0677 46.6049C42.8548 41.3952 43.0677 37.9019 43.0677 37.9019C50.0022 35.6132 51.9487 39.5883 51.9487 39.5883C51.9487 39.5883 60.1606 58.8916 64.3882 71.5095C66.0306 76.2977 67.0646 80.1523 66.8822 81.5677Z" fill="#B0D0FF" />
        <path d="M81.329 77.7432C76.189 83.8865 69.4674 88.8855 62.0767 90.06C46.687 83.1939 43.5847 59.5541 43.0372 46.5748C43.0372 46.5748 50.3063 72.7442 60.2214 81.8689C62.0159 83.5251 63.9015 84.6093 65.8177 84.9104C65.8177 84.9104 78.318 81.4171 80.9336 78.1046C81.177 77.8938 81.2986 77.7733 81.329 77.7432Z" fill="#98BAF4" />
    </svg>

);

const OurFeatures = () => {
    const features = [
        {
            title: "Our 24/7 Support!",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec ultricies lorem. Proin quis magna ut eros varius dignissim. Sed sit amet dui varius, commodo turpis ac, auctor lorem. Vestibulum faucibus ex non risus cursus, non sollicitudin odio malesuada. Suspendisse potenti.",
            svg: <UserSVG1 />,
        },
        {
            title: "More than 1.5M services!",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec ultricies lorem. Proin quis magna ut eros varius dignissim. Sed sit amet dui varius, commodo turpis ac, auctor lorem. Vestibulum faucibus ex non risus cursus, non sollicitudin odio malesuada. Suspendisse potenti.",
            svg: <UserSVG2 />,
        },
        {
            title: "New feature for left alignment!",
            description:
                "This is an example of another feature with the SVG aligned to the left. It continues the alternation pattern.",
            svg: <UserSVG1 />,
        },
    ];

    return (
        <Box sx={{ padding: 4, marginTop: "40px" }}>
            <Box sx={{ maxWidth: "1200px", margin: "0 auto", textAlign: "center" }}>
                <Typography
                    variant="h4"
                    component="h2"
                    sx={{ fontWeight: "bold", marginBottom: 4, color: "#474747" }}
                >
                    Our Features
                </Typography>
                <Typography sx={{ textAlign: "center", marginTop: "20px" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec
                    ultricies lorem.
                </Typography>
                <Grid container spacing={4} sx={{ marginTop: "30px" }}>
                    {features.map((feature, index) => (
                        <Grid
                            item
                            xs={12}
                            md={12}
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: index % 2 === 0 ? "row" : "row-reverse", // Alternate direction
                                gap: 2,
                                marginBlock:"20px "

                            }}
                        >
                            <Box sx={{ flexShrink: 0 }}>{feature.svg}</Box>
                            <Paper
                                elevation={3}
                                sx={{
                                    padding: 2,
                                    borderRadius: 2,
                                    textAlign: "left",
                                    flex: 1,
                                    backgroundColor: "#E5EDFF",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="h3"
                                    sx={{
                                        fontWeight: "600",
                                        marginBottom: 4,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                    }}
                                >
                                    {feature.title}
                                </Typography>
                                <Typography variant="body1">{feature.description}</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default OurFeatures;
