import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme, progressPercent }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    .progress-title {
      width: 100%;
      color: #303030;
      font-weight: 500;
      font-size: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .progress-bar {
      position: relative;
      width: calc(100% - 114px);
      background: #cacaca;
      height: 4px;
      border-radius: 15px;
      &::after {
        position: absolute;
        content: "";
        border-radius: 15px;
        left: 0;
        top: 0;
        height: 4px;
        width: ${progressPercent};
        background: #3f78e0;
      }
    }
    .progress-percent {
      width: 28px;
      color: #303030;
      font-weight: 500;
      font-size: 13px;
    }
  `
);
