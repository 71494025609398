import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const RatingStatistics = ({ averageRating, ratingPercentages }) => {
    const displayAverageRating = Number.isFinite(averageRating)
        ? averageRating.toFixed(2)
        : "0.00";
    return (
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Typography sx={{ fontSize: { xs: "20px", md: "25px" }, fontWeight: "500" }}>
                Ratings Statistics
            </Typography>
            <Typography sx={{ fontSize: { xs: "20px", md: "24px" }, color: "#959595", marginTop: "10px" }}>
                {displayAverageRating} out of 5.00
            </Typography>
            {ratingPercentages.map((item, index) => (
                <Box key={index} sx={{ marginTop: "20px" }}>
                    <Typography>{item.label}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <LinearProgress
                            variant="determinate"
                            value={item.value}
                            sx={{ flexGrow: 1, marginRight: "10px" }}
                        />
                        <Typography>{item.value.toFixed(1)}%</Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
export default RatingStatistics;