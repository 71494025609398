import React, { useEffect, useState } from "react";
import CustomTitle from "./title";
import { Box, Typography } from "@mui/material";
import { Styles } from "./styles";

const Header = () => {
  const sentences = ["SMM World", "Providers", "ALL SMM Services"];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % sentences.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Styles>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <Box>
          <Typography>
            <CustomTitle />
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
          className="description"
        >
          <Typography
            className="main-description"
            sx={{
              color: "#000000",
              fontWeight: "600",
              lineHeight: "32px",
            }}
          >
            Your Smart Gateway to
          </Typography>
            {sentences.map((sentence, index) => {
              return (
                <Typography
                  key={index}
                  className={`service ${
                    currentIndex === index ? "visible" : ""
                  } ease-in-out`}
                >
                  {sentence}
                </Typography>
              );
            })}
        </Box>
      </Box>
    </Styles>
  );
};

export default Header;
