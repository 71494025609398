import { styled, css, keyframes } from "@mui/system";

const moveSentence = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
    
  }
  50% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
`;

const moveSentenceMobile = keyframes`
  0% {
    transform: translate(-50%, 0px);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -20px);
    opacity: 0;
  }
`;

export const Styles = styled("div")(
  ({ theme }) => css`
    .description {
      position: relative;
      width: 510px;
      height: 32px;
      font-family: "Instrument_Sans";
      font-weight: 500;
      font-size: 26px;
      @media (max-width: 992px) {
        width: 395px;
      }
      @media (max-width: 768px) {
        width: 171px;
        padding-bottom: 40px;
      }
      overflow: hidden;
      .main-description {
        font-family: "Instrument_Sans";
        font-weight: 500;
        font-size: 26px;
        @media (max-width: 992px) {
          font-size: 20px;
        }
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      .service {
        position: absolute;
        color: #eca601;
        font-weight: 600;
        font-family: "Instrument_Sans";
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        width: 230px;
        opacity: 0;
        transform: translateY(-20px);
        transition: all 1s ease-in-out;
        @media (max-width: 992px) {
          font-size: 20px;
          width: 180px;
        }
        @media (max-width: 768px) {
          font-size: 16px;
          width: 160px;
          right: auto;
          left: 50%;
          bottom: 0px;
          transform: translate(-50%, -20px);
        }
      }

      .service.visible {
        opacity: 1;
        transform: translateY(0px);
        animation: 2s ${moveSentence} 1s forwards ease-in-out;

        @media (max-width: 768px) {
          opacity: 1;
          transform: translate(-50%, 0px);
          animation: 2s ${moveSentenceMobile} 1s forwards ease-in-out;
        }
      }
    }
  `
);
