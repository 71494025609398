import { React, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useAuth } from "../../AuthContext/AuthContext";
import { api_Routes } from "../../api_Routes";
import { Helper } from "../../tools/Helper";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import Slider from "@mui/material/Slider";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Files from "react-files";
import { useNavigate, useLocation } from "react-router-dom";

const AddPanel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [selectedFont, setSelectedFont] = useState("Mockads");
  const [selectedColor, setSelectedColor] = useState("#EBF2FF");
  const [logoName, setLogoName] = useState("LOREM");
  const [fontSize, setFontSize] = useState(44);
  const [formData, setFormData] = useState({
    smmpanel_name: "",
    smmpanel_url: "",
    description: "",
    smmpanel_email: "",
    smmpanel_instagram: "",
    smmpanel_skybe: "",
    smmpanel_telegram: "",
    smmpanel_fb: "",
    smmpanel_twitter: "",
    smmpanel_whatsapp: "",
    smmpanel_tel1: "",
    customLogo: "",
    logo: null,
  });
  const [inputValue, setInputValue] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);

  const handleAddMethod = () => {
    if (inputValue.trim() !== "") {
      setPaymentMethod([...paymentMethod, inputValue.trim()]);
      setInputValue(""); // Clear the input field after adding
    }
  };

  const handleDeleteMethod = (index) => {
    const updatedMethods = paymentMethod.filter((_, i) => i !== index);
    setPaymentMethod(updatedMethods);
  };
  const [uploadOption, setUploadOption] = useState("logo");

  const fonts = ["Mockads", "Escopedia", "Great Vibes"];
  const colors = ["#FFFFFF", "#303030", "#ECA601"];

  const handleUploadOptionChange = (option) => {
    setUploadOption(option);
    if (option === "customLogo") {
      setFormData((prev) => ({ ...prev, customLogo: "" }));
    } else {
      setFormData((prev) => ({ ...prev, logo: null }));
    }
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      setFormData((prev) => ({ ...prev, logo: files[0] })); // Store the first file
    }
  };

  const handleFontChange = (font) => {
    setSelectedFont(font);
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const handleFontSizeChange = (event, newValue) => {
    setFontSize(newValue);
  };

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  const handleButtonClick = async () => {
    if (!auth) {
      enqueueSnackbar("please login first ", { variant: "warning" });
      navigate("/login", { state: { from: location } });
    } else {
      await handleSubmit();
    }
  };
  const handleSubmit = async () => {
    const requiredFields = ["smmpanel_name", "smmpanel_url", "smmpanel_email"];

    const updatedFormData = {
      ...formData,
    };

    const missingFields = requiredFields.filter(
      (field) => !updatedFormData[field]
    );

    if (uploadOption === "logo") {
      if (!formData.logo) {
        missingFields.push("logo");
      }
    } else if (uploadOption === "customLogo") {
      // Validate individual properties for custom logo
      if (!logoName || !selectedColor || !selectedFont || !fontSize) {
        missingFields.push(
          "customLogo properties (logoName, color, fontFamily, fontSize)"
        );
      }
    }

    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Please fill in all required fields: ${missingFields.join(", ")}`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const form_data = new FormData();

    // Append form data based on the active upload option
    if (uploadOption === "logo" && formData.logo) {
      form_data.append("logo", formData.logo);
    } else if (uploadOption === "customLogo") {
      updatedFormData.customLogo = {
        fontSize: `${fontSize}px`,
        color: selectedColor,
        fontFamily: selectedFont,
        logoName: logoName,
      };
      Object.keys(updatedFormData.customLogo).forEach((key) => {
        form_data.append(`customLogo[${key}]`, updatedFormData.customLogo[key]);
      });
    }

    // Append other form data fields
    Object.keys(updatedFormData).forEach((key) => {
      if (key !== "logo" && key !== "customLogo") {
        form_data.append(key, updatedFormData[key]);
      }
    });

    // Append payment methods as an array
    paymentMethod.forEach((method, index) => {
      form_data.append(`paymentMethod[${index}]`, method);
    });

    const { response, message } = await Helper.Post({
      url: api_Routes.AddPanel.add,
      data: form_data,
      hasToken: true,
    });

    if (response) {
      enqueueSnackbar(message, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      navigate("/");
      setIsLoading(false);
    } else {
      let errorMessage = "";
      if (typeof message === "string") {
        errorMessage = message;
      } else if (typeof message === "object") {
        errorMessage = Object.values(message).flat().join(", ");
      }
      enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setIsLoading(false);
    }
  };

  const toggleUploadOption = () => {
    if (uploadOption === "logo") {
      handleUploadOptionChange("customLogo");
    } else {
      handleUploadOptionChange("logo");
    }
  };

  return (
    <>
      <Container
        sx={{
          marginBlock: "30px",
          "@media (min-width: 1200px)": {
            maxWidth: "1400px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Typography
            sx={{
              color: "#474747",
              fontSize: { xs: "20px", sm: "25px", md: "40px" },
              lineHeight: "40px",
              fontWeight: "700",
            }}
          >
            Add Your Own Panel
          </Typography>
          <Typography
            sx={{
              color: "#474747",
              fontSize: "18px",
              lineHeight: "20px",
              fontWeight: "400",
            }}
          >
            add your panel to our database that makes your services reachable
            and your clients increase over time.
          </Typography>
        </Box>
        <Box
          sx={{
            background: "#EBF2FF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "50px",
            padding: { xs: "20px", md: "50px" },
            marginTop: "50px",
            borderRadius: "26px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              position: "relative",
            }}
          >
            <Box sx={{ marginBottom: { xs: "20px", md: 0 } }}>
              <Typography
                sx={{
                  color: "#474747",
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "30px",
                }}
              >
                Main Details
              </Typography>
              <Grid container spacing={1} sx={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={6} md={10}>
                  <TextField
                    label="Panel name"
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                      handleChange("smmpanel_name", e.target.value);
                    }}
                    name="smmpanel_name"
                    size="small" // Makes the TextField smaller
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Panel Url"
                    onChange={(e) => {
                      handleChange("smmpanel_url", e.target.value);
                    }}
                    name="smmpanel_url"
                    size="small" // Makes the TextField smaller
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    label="Description"
                    minRows={10} // increases the initial height
                    maxRows={14} // optional, sets a maximum height
                    onChange={(e) => {
                      handleChange("description", e.target.value);
                    }}
                    name="description"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: { md: "100%", lg: "50%" } }}>
              <Typography
                sx={{
                  color: "#474747",
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "30px",
                }}
              >
                Contacts
              </Typography>
              <Grid container spacing={1} sx={{ marginTop: "28px" }}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                      handleChange("smmpanel_email", e.target.value);
                    }}
                    name="smmpanel_email"
                    label="Panel Email"
                    size="small"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      width: "95%",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="smmpanel_instagram"
                    onChange={(e) => {
                      handleChange("smmpanel_tel1", e.target.value);
                    }}
                    label="Phone number"
                    size="small"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      width: "95%",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      handleChange("smmpanel_skybe", e.target.value);
                    }}
                    variant="outlined"
                    name="smmpanel_skybe"
                    label="Skype"
                    size="small"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      width: "95%",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="smmpanel_instagram"
                    onChange={(e) => {
                      handleChange("smmpanel_instagram", e.target.value);
                    }}
                    label="Instagram"
                    size="small"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      width: "95%",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                      handleChange("smmpanel_fb", e.target.value);
                    }}
                    name="smmpanel_fb"
                    label="Facebook"
                    size="small"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      width: "95%",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="smmpanel_telegram"
                    onChange={(e) => {
                      handleChange("smmpanel_telegram", e.target.value);
                    }}
                    label="Telegram"
                    size="small"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      width: "95%",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="smmpanel_whatsapp"
                    onChange={(e) => {
                      handleChange("smmpanel_whatsapp", e.target.value);
                    }}
                    label="Whatsapp"
                    size="small"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      width: "95%",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="smmpanel_twitter"
                    onChange={(e) => {
                      handleChange("smmpanel_twitter", e.target.value);
                    }}
                    label="Twitter"
                    size="small"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        color: "#5F5F5F",
                        lineHeight: "24px",
                        fontWeight: "600",
                      },
                    }}
                    sx={{
                      width: "95%",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginTop: "10px",
                      border: "0.5px solid #959595",
                      "& .MuiInputBase-root": {
                        color: "black !important",
                        borderRadius: "10px",
                        padding: "4px 8px", // Reduced padding
                      },
                      "& .MuiOutlinedInput-root": {
                        borderWidth: "1px", // Adjusted border width
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px", // Adjust input padding
                        fontSize: "16px", // Adjust font size if needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ position: "relative", marginTop: "20px" }}
                >
                  <Typography
                    sx={{
                      marginBottom: "10px",
                      fontWeight: "600",
                      fontSize: "24px",
                      lineHeight: "30px",
                      color: "#474747",
                    }}
                  >
                    Payment Methods
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="smmpanel_tel1"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      size="small"
                      placeholder="Type your Payment methods"
                      sx={{
                        width: "95%",
                        boxShadow: "-2px 2px 4px #00000040",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        marginTop: "10px",
                        border: "0.5px solid #959595",
                        "& .MuiInputBase-root": {
                          color: "black !important",
                          borderRadius: "10px",
                          padding: "4px 8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderWidth: "1px",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        "& .MuiInputBase-input": {
                          padding: "8px",
                          fontSize: "16px",
                        },
                      }}
                    />
                    <Button
                      onClick={handleAddMethod}
                      sx={{
                        backgroundColor: "#ECA601",
                        color: "black",
                        padding: "4px 8px",
                        borderRadius: "10px",
                        minWidth: "40px",
                        minHeight: "40px",
                        marginTop: "10px",
                        "&:hover": {
                          backgroundColor: "#FFD700",
                        },
                      }}
                    >
                      <svg
                        width="19"
                        height="17"
                        viewBox="0 0 19 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.851562 8.51283H9.07813M17.3047 8.51283H9.07813M9.07813 8.51283V0.923828M9.07813 8.51283V15.5784"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "20px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {paymentMethod.map((method, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#D7D7D7",
                          borderRadius: "9px",
                          padding: "10px 12px",
                          fontSize: "14px",
                          "&:hover": {
                            backgroundColor: "#dcdcdc",
                          },
                        }}
                      >
                        {method}
                        <IconButton
                          onClick={() => handleDeleteMethod(index)}
                          size="small"
                          sx={{
                            padding: "1px",
                            borderRadius: "2px",
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            backgroundColor: "white",
                            boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                            "&:hover": {
                              backgroundColor: "#f8d7da",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{ fontSize: "12px", color: "black" }}
                          />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
            <Typography
              sx={{
                marginBottom: "10px",
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "30px",
                color: "#474747",
              }}
            >
              Logo Image *
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "20px 0",
              }}
            >
              <Button
                variant="outlined"
                onClick={toggleUploadOption}
                sx={{
                  fontWeight: "600",
                  border: "1px solid #3F78E0",
                  textTransform: "capitalize",
                  textAlign: "center",
                  borderRadius: "10px",
                  padding: "3px 40px",
                  background: "#FFFFFF",
                  color: "#3F78E0",
                  boxShadow:
                    "0px 5px 5px #00000040, inset 4px 7px 5px #FFFFFF30",
                  "&:hover": {
                    background: "none", // Optional: Darken background on hover
                  },
                }}
              >
                {uploadOption === "logo"
                  ? "Switch to Custom Logo"
                  : "Switch to Photo Upload"}
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                position: "relative",
                justifyContent: "space-between",
                width: "100%",
                gap: { xs: "40px", md: 0 },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "45%" },
                  opacity: uploadOption === "customLogo" ? 0.5 : 1,
                  pointerEvents:
                    uploadOption === "customLogo" ? "none" : "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "10px",
                    alignItems: "center",
                    height: "300px",
                    textAlign: "center",
                    backgroundColor: "#FFFFFF",
                    cursor:
                      uploadOption === "customLogo" ? "not-allowed" : "pointer",
                    position: "relative",
                    border: "#303030 solid 1px",
                    borderRadius: "15px",
                  }}
                >
                  <Files
                    onChange={(fileList) => handleFileChange(fileList)}
                    onError={(error) =>
                      console.error("File upload error:", error)
                    }
                    accepts={["image/*"]}
                    clickable
                    multiple={false}
                    maxFileSize={10485760} // 10MB limit
                    disabled={uploadOption === "customLogo"}
                    style={{
                      opacity: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                    }}
                  />
                  {formData.logo ? (
                    <Box
                      sx={{
                        position: "relative",
                        width: "80%", // Adjust the size of the image
                        height: "80%",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={URL.createObjectURL(formData.logo)}
                        alt="Uploaded Logo"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain", // Ensure the image is properly contained
                          borderRadius: "10px",
                        }}
                      />
                      <IconButton
                        onClick={() => {
                          setFormData((prev) => ({ ...prev, logo: null }));
                        }}
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          color: "white",
                          backgroundColor: "red",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: "red",
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Click to upload a logo
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  opacity: uploadOption === "logo" ? 0.5 : 1,
                  pointerEvents: uploadOption === "logo" ? "none" : "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    border: "1px solid #303030",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                      md: "column",
                      lg: "row",
                    },
                    justifyContent: {
                      xs: "space-between",
                      sm: "space-around",
                      md: "space-between",
                    },
                    alignItems: "center",
                    height: "100%",
                    padding: {
                      xs: "20px 20px",
                      sm: "0 20px",
                      md: "20px 20px",
                      lg: "0 20px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "90%",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: "#474747",
                          fontSize: "20px",
                          fontWeight: "600",
                          lineHeight: "30px",
                        }}
                      >
                        Pick a Custom Font
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={logoName}
                        onChange={(e) => setLogoName(e.target.value)}
                        placeholder="Type Your Logo Name here"
                        size="small"
                        InputLabelProps={{
                          style: {
                            fontSize: "16px",
                            color: "#5F5F5F",
                            lineHeight: "24px",
                            fontWeight: "600",
                          },
                        }}
                        sx={{
                          width: "95%",
                          boxShadow: "-2px 2px 4px #00000040",
                          borderRadius: "10px",
                          backgroundColor: "white",
                          marginTop: "10px",
                        }}
                      />
                    </Box>

                    <Box>
                      <Typography>Choose a Font</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          marginTop: "4px",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {fonts.map((font) => (
                          <Box
                            key={font}
                            sx={{
                              background: "#E5EDFF",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              gap: "4px",
                              border: "1px solid #959595",
                              borderRadius: "10px",
                              padding: {
                                xs: "4px 6px",
                                sm: "4px 12px",
                                md: "4px 25px",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: font,
                                fontSize: "20px",
                                lineHeight: "24px",
                                fontWeight: "600",
                                color: "#474747",
                              }}
                            >
                              lorem
                            </Typography>
                            <Radio
                              checked={selectedFont === font}
                              onChange={() => handleFontChange(font)}
                              sx={{
                                color: "primary.main",
                              }}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Typography>Choose a color and font size</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "6px",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          {colors.map((color) => (
                            <Box
                              key={color}
                              onClick={() => handleColorClick(color)}
                              sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                backgroundColor: color,
                                cursor: "pointer",
                                border: "1px #303030 solid",
                                transition: "border 0.3s ease",
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "6px",
                        }}
                      >
                        <Slider
                          value={fontSize}
                          onChange={handleFontSizeChange}
                          min={20}
                          max={50}
                          step={1}
                          valueLabelDisplay="auto"
                          sx={{ width: "100px" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "6px",
                      height: "200px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "28px",
                        color: "#474747",
                      }}
                    >
                      Your Logo demo
                    </Typography>
                    <Box
                      sx={{
                        background: "radial-gradient(circle, #51AAFD, #002D68)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        padding: "40px",
                        borderRadius: "15px",
                        width: "100px",
                        height: "80px",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: `${fontSize}px`,
                          fontWeight: "700",
                          lineHeight: "50px",
                          color: "#EBF2FF",
                          color: selectedColor,
                          fontFamily: selectedFont,
                        }}
                      >
                        {logoName || "LOREM"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                whiteSpace: "nowrap",
                background: "#ECA601",
                padding: "5px 50px",
                borderRadius: "10px",
                // Adding inner shadow using a pseudo-element
                color: "#F1F5FD",
                position: "relative",
                lineHeight: "38px",
                overflow: "hidden",
                fontWeight: "700",
                fontSize: "28px",
                boxShadow: "0px 3px 3px #00000040", // Outer shadow
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "0 20px 20px 0",
                  background: "rgba(255, 255, 255, 0.1)", // Color for the inner shadow
                  filter: "blur(4px)",
                  zIndex: 1,
                },
                "&:hover:before": {
                  background: "rgba(255, 255, 255, 0.2)", // Darker on hover for inner shadow
                },
              }}
              onClick={handleButtonClick}
            >
              {IsLoading ? (
                <CircularProgress size={22} sx={{ color: "white" }} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AddPanel;
