import React from "react";
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import NotficationsIcon from "../../Assets/Svg/Notfications.svg";

const NotificationsList = ({
  showNotifications,
  toggleNotifications,
  notifications,
  handleClickAway,
}) => {
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: "relative" }}>
        <IconButton
          onClick={toggleNotifications}
          style={{
            width: "30px",
            height: "30px",
            cursor: "pointer",
            padding: 0,
          }}
        >
          <img
            src={NotficationsIcon}
            alt="Notifications"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </IconButton>
        {showNotifications && (
          <Box
            sx={{
              position: "absolute",
              top: "60px",

              right: "-70px",
              width: "300px",
              maxHeight: "300px",
              overflowY: "auto",
              backgroundColor: "white",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              zIndex: 99999,
              p: 2,
            }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}>
              Notifications
            </Typography>
            {notifications.length > 0 ? (
              <List>
                {notifications.map((notification) => (
                  <ListItem key={notification.id} sx={{ mb: 1 }}>
                    <ListItemText
                      primary={notification.message}
                      secondary={new Date(
                        notification.sent_at
                      ).toLocaleString()}
                      primaryTypographyProps={{
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                      secondaryTypographyProps={{
                        fontSize: "12px",
                        color: "gray",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2" sx={{ color: "gray" }}>
                No notifications available.
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default NotificationsList;
