import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./slices/profileSlice";

const toolkitReducers = {
  profile: profileReducer,
};

const store = configureStore({
  reducer: toolkitReducers,
});
export default store;
