import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography, CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import Arrow from "../../../Assets/Svg/offersarrow.svg";
import { Styles } from "./styles";
import { showIconByServiceId } from "../../../helpers/showIconByServiceId";

const OfferCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Styles>
      <Card
        sx={{
          boxShadow: "-4px 4px 4px #00000040",
          border: "1px solid #999696",
          borderRadius: "12px",
          width: "100%",
          height: "100%",
        }}
        className="card"
      >
        <Box
          sx={{
            background: "rgb(26,85,152)",
            background:
              "linear-gradient(90deg, rgba(26,85,152,1) 43%, rgba(73,157,238,1) 100%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBlock: "20px",
          }}
        >
          <div className="offer-discount">
            <img src={Arrow} alt="arrow" />
            <Box className="offer-discount-data">
              <Box className="offer-discount-number">
                <div className="number">{Math.round(data?.discount)}</div>
                <div className="offer-discount-percentage">
                  <div>{data?.discount_type}</div>
                  <span>OFF</span>
                </div>
              </Box>
              <Box className="offer-discount-title">
                <Typography>SPECIAL</Typography>
                <Typography>OFFER</Typography>
              </Box>
            </Box>
          </div>
          <div className="icons">
            {data?.offerDetails.map((detailObj, index) => (
              <img
                src={showIconByServiceId(detailObj?.ourServiceId)}
                alt="icon"
                key={index}
              />
            ))}
          </div>
        </Box>
        <CardContent
          sx={{
            padding: "16px 16px 16px 16px",
            height: "calc(100% - 105px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "18px",
              color: "#303030",
              marginBlock: "10px",
              textAlign: "center",
            }}
          >
            {data?.title}
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #80808050",
              paddingBottom: "10px",
              flexGrow: "1",
            }}
          >
            {data?.offerDetails?.length > 0 ? (
              <ul className="offer-details">
                {data?.offerDetails.map((detailObj, index) => (
                  <li key={index}>{detailObj.detail}</li>
                ))}
              </ul>
            ) : (
              <span>No details provided</span>
            )}
          </Box>
          <Box
            sx={{
              borderBottom: "2px solid #80808050",
              paddingBlock: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#666666",
                }}
              >
                price {data?.total_price} $
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#ED1818",
                }}
              >
                Discount {data?.discount}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: "400",
                color: "#004571",
              }}
            >
              Final Price {data?.final_price} $
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Typography>
                {" "}
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.47332 0.892578L8.46184 4.28293L12.3233 5.1133L9.69082 8.03903L10.0888 11.9426L6.47332 10.3604L2.85782 11.9426L3.25582 8.03903L0.62332 5.1133L4.48479 4.28293L6.47332 0.892578Z"
                    fill="#3F78E0"
                  />
                </svg>
              </Typography>
              <Typography>{data?.note}</Typography>
              <Typography>
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.47332 0.892578L8.46184 4.28293L12.3233 5.1133L9.69082 8.03903L10.0888 11.9426L6.47332 10.3604L2.85782 11.9426L3.25582 8.03903L0.62332 5.1133L4.48479 4.28293L6.47332 0.892578Z"
                    fill="#3F78E0"
                  />
                </svg>
              </Typography>
            </Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  sx={{
                    textAlign: "center",
                    borderRadius: "10px",
                    padding: " 4px 20px",
                    background: "#3F78E0",
                    color: "white",
                    boxShadow:
                      "0px 5px 5px #00000040, inset 4px 7px 5px #FFFFFF30",
                    "&:hover": {
                      background: "#2E5DBA", // Optional: Darken background on hover
                    },
                  }}
                  onClick={() => navigate(`/OffersDetails/${data?.offer_id}`)}
                >
                  Get Offer
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Styles>
  );
};

export default OfferCard;
