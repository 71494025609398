import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext/AuthContext'; // Adjust the path as necessary

const ProtectedRoute = ({ children, redirectPath = "/login" }) => {
    const { auth, loading } = useAuth();

    // Show a loader or return null while auth state is loading
    if (loading) {
        return <div>Loading...</div>; // Replace with your loading indicator or spinner
    }

    // Redirect to login if user is not authenticated
    if (!auth) {
        return <Navigate to={redirectPath} replace />;
    }

    // Render the protected children components
    return children;
};

export default ProtectedRoute;
