import React, { useState, useEffect, useRef } from "react";
import { api_Routes } from "../api_Routes";
import { Helper } from "../tools/Helper";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext/AuthContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import loginimage from "../Assets/loginomage.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginSvg from "../Assets/Svg/login-logo.svg";
import ReCAPTCHA from "react-google-recaptcha";
const Signup1 = () => {
  // const fullNameInputRef = useRef(null);
  const usernameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const confirmPasswordInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const recaptchaRef = useRef(null);
  const { login } = useAuth();
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      enqueueSnackbar("Please complete the CAPTCHA", { variant: "error" });
      return;
    }

    setIsLoading(true);

    // Validate form data
    const requiredFields = [
      "name",
      "password",
      "password_confirmation",
      "email",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);
    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Please fill in all required fields: ${missingFields.join(", ")}`,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
      setIsLoading(false);
      return;
    }

    // if (formData.password !== formData.password_confirmation) {
    //     enqueueSnackbar("The passwords you entered do not match. Please try again.", {
    //         variant: "error",
    //         anchorOrigin: { vertical: "top", horizontal: "right" },
    //     });
    //     setIsLoading(false);
    //     return;
    // }
    const form_data = new FormData();
    Object.keys(formData).forEach((key) => {
      form_data.append(key, formData[key]);
    });
    const email = `${formData.email}`;
    const name = `${formData.name}`;

    const { response, message } = await Helper.Post({
      url: api_Routes.Auth.add,
      data: form_data,
      hasToken: true,
    });

    if (response && response.success) {
      // const user_id = response.data.id;

      // localStorage.setItem('user_id', user_id);
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      // localStorage.setItem('phone_number', phone_number)
      navigate("/VerifyAccount"); // Navigate to verification page
      setIsLoading(false);
      enqueueSnackbar(message, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      if (typeof message === "string") {
        setErrorMessage(message.split(",").map((err) => err.trim()));
      } else {
        setErrorMessage([message]);
      }
    }

    setIsLoading(false);

    // if (!recaptchaToken) {
    //     enqueueSnackbar("Please verify you're human using the reCAPTCHA.", {
    //         variant: "error",
    //     });
    //     setIsLoading(false);
    //     return;
    // }

    // Combine phone code and phone number
    // const phoneNumber = `${formData.phone_code}${formData.phone}`;

    // // Check if the phone number exists
    // try {
    //     setIsLoading(true); // Show loading before checking phone number
    //     const phoneExists = await checkAllExists(phoneNumber, formData.email, formData.username);
    //     if (phoneExists === null) {
    //         setIsLoading(false); // Hide loading when an error occurs
    //         return; // Exit function if there's an error
    //     }

    //     if (phoneExists) {
    //         enqueueSnackbar("This phone number is already registered.", {
    //             variant: "error",
    //         });
    //         setIsLoading(false); // Hide loading if phone exists
    //         return;
    //     }
    // } catch (error) {
    //     setIsLoading(false); // Hide loading in case of exception
    //     return;
    // }

    // Check if the username exists

    // Send verification code
    // try {
    //     setIsLoading(true); // Show loading before sending the verification code
    //     await sendVerificationCode(phoneNumber, recaptchaToken);
    //     setIsLoading(false); // Hide loading after sending code
    // } catch (error) {
    //     enqueueSnackbar("Error sending verification code. Please try again.", {
    //         variant: "error",
    //     });
    //     initializeRecaptcha();
    //     setIsLoading(false); // Hide loading if sending fails
    // }
  };
  return (
    <>
      <Box
        sx={{
          marginBlock: "30px",
          paddingTop: { xs: "40px", sm: "80px" },
          paddingBottom: "10px",
          background: "#E5EDFF",
          borderRadius: "20px",
          marginInline: { xs: "10px", md: "50px", lg: "100px" },
          paddingInline: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "space-around" },
            alignItems: { xs: "center", sm: "flex-start" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" },
              width: "65%",
            }}
          >
            <img src={LoginSvg} alt="" />
            <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "25px", sm: "20px", lg: "35px" },
                  lineHeight: "50px",
                  color: "#5F5F5F",
                  textAlign: { xs: "center", sm: "left" },
                  width: "100%",
                }}
              >
                Join the BookSMM Community
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  alignItems: { xs: "center", sm: "flex-start" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: { xs: "14px", sm: "16px", lg: "20px" },
                    lineHeight: "30px",
                    color: "#5F5F5F",
                    width: { xs: "65%", sm: "80%" },
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  Be part of a growing network! Sign up now and unlock a world
                  of opportunities tailored for you.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: "80%", sm: "80%", md: "50%", lg: "30%" },
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {errorMessage.map((error, index) => (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  key={index}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00045 15.747C7.62497 15.747 6.28038 15.3391 5.13671 14.5749C3.99304 13.8108 3.10166 12.7246 2.57529 11.4538C2.04891 10.183 1.91119 8.78473 2.17953 7.43568C2.44787 6.08663 3.11023 4.84744 4.08284 3.87482C5.05545 2.90221 6.29463 2.23987 7.64368 1.97153C8.99273 1.70318 10.3911 1.8409 11.6618 2.36727C12.9326 2.89364 14.0188 3.78502 14.7829 4.92869C15.5471 6.07236 15.955 7.41696 15.955 8.79244C15.955 10.6369 15.2223 12.4058 13.9181 13.71C12.6138 15.0143 10.8449 15.747 9.00045 15.747Z"
                      stroke="#EB563F"
                      stroke-width="1.15909"
                    />
                    <path
                      d="M8.77655 4.24841L9.05387 10.1425L9.3307 4.25083C9.33241 4.21316 9.32642 4.17554 9.3131 4.14027C9.29978 4.105 9.2794 4.07281 9.25322 4.04568C9.22704 4.01855 9.1956 3.99705 9.16083 3.98248C9.12605 3.96791 9.08867 3.96058 9.05097 3.96095V3.96095C9.01393 3.96131 8.97733 3.96909 8.94335 3.98383C8.90936 3.99857 8.87867 4.01997 8.85309 4.04676C8.82751 4.07356 8.80756 4.10521 8.79442 4.13984C8.78127 4.17447 8.7752 4.21139 8.77655 4.24841V4.24841Z"
                      stroke="#EB563F"
                      stroke-width="1.54838"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.04108 13.6056C8.84908 13.6056 8.66139 13.5487 8.50175 13.442C8.34211 13.3353 8.21768 13.1837 8.14421 13.0063C8.07073 12.8289 8.05151 12.6338 8.08897 12.4454C8.12642 12.2571 8.21888 12.0842 8.35464 11.9484C8.49041 11.8126 8.66338 11.7202 8.85169 11.6827C9.04 11.6453 9.23519 11.6645 9.41258 11.738C9.58996 11.8114 9.74158 11.9359 9.84824 12.0955C9.95491 12.2551 10.0118 12.4428 10.0118 12.6348C10.0118 12.8923 9.90957 13.1392 9.72752 13.3213C9.54546 13.5033 9.29854 13.6056 9.04108 13.6056Z"
                      fill="#EB563F"
                    />
                  </svg>

                  <Typography
                    color="error"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {error}
                  </Typography>
                </Box>
              </Box>
            ))}
            <Box>
              <TextField
                label=" Username"
                type="text"
                fullWidth
                InputLabelProps={{
                  style: {
                    color: "#959595",
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                  },
                }}
                onChange={(e) => handleChange("name", e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    emailInputRef.current.focus();
                  }
                }}
                sx={{
                  boxShadow: "-2px 2px 4px #00000040",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  marginTop: "10px",

                  "& .MuiInputBase-root": {
                    color: "black !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderWidth: "10px",
                    "&:hover fieldset": {
                      borderColor: "#3F78E0 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3F78E0 !important",
                    },
                  },
                }}
                inputRef={usernameInputRef}
              />
            </Box>
            <Box>
              <TextField
                label=" Email"
                type="text"
                fullWidth
                InputLabelProps={{
                  style: {
                    color: "#959595",
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                  },
                }}
                onChange={(e) => handleChange("email", e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    passwordInputRef.current.focus();
                  }
                }}
                sx={{
                  boxShadow: "-2px 2px 4px #00000040",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  marginTop: "10px",

                  "& .MuiInputBase-root": {
                    color: "black !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderWidth: "10px",
                    "&:hover fieldset": {
                      borderColor: "#3F78E0 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3F78E0 !important",
                    },
                  },
                }}
                inputRef={emailInputRef}
              />
            </Box>
            <Box sx={{}}>
              <TextField
                label="Enter your password"
                onChange={(e) => handleChange("password", e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    confirmPasswordInputRef.current.focus();
                  }
                }}
                type={showPassword ? "text" : "password"}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: "#959595",
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                  },
                }}
                // onChange={(e) => setemail(e.target.value)}
                // onKeyDown={(e) => {
                //     if (e.key === 'Enter') {
                //         e.preventDefault();  // Prevent form submission if wrapped in a form
                //         passwordInputRef.current.focus();  // Focus on the password input field
                //     }
                // }}
                sx={{
                  boxShadow: "-2px 2px 4px #00000040",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  marginTop: "10px",

                  "& .MuiInputBase-root": {
                    color: "black !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderWidth: "10px",
                    "&:hover fieldset": {
                      borderColor: "#3F78E0 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3F78E0 !important",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={passwordInputRef}
              />
            </Box>
            <Box sx={{}}>
              <TextField
                label="Enter your password again"
                onChange={(e) =>
                  handleChange("password_confirmation", e.target.value)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
                type={showPassword ? "text" : "password"}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: "#959595",
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                  },
                }}
                // onChange={(e) => setemail(e.target.value)}
                // onKeyDown={(e) => {
                //     if (e.key === 'Enter') {
                //         e.preventDefault();  // Prevent form submission if wrapped in a form
                //         passwordInputRef.current.focus();  // Focus on the password input field
                //     }
                // }}
                sx={{
                  boxShadow: "-2px 2px 4px #00000040",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  marginTop: "10px",

                  "& .MuiInputBase-root": {
                    color: "black !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderWidth: "10px",
                    "&:hover fieldset": {
                      borderColor: "#3F78E0 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3F78E0 !important",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={confirmPasswordInputRef}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", sm: "row" },
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {/* Remember me checkbox */}
              {/* <Box sx={{ marginTop: "10px" }}>
              <FormControlLabel
                control={<Checkbox />}
                label="Remember me"
                sx={{ color: "#434343" }}
              />
            </Box> */}

              {/* Forgot password button */}
              <Box sx={{}}>
                <Button
                  variant="text"
                  sx={{ color: "#3F78E0", textTransform: "none" }}
                  onClick={() => {
                    navigate(`/ForgetPassswordByEmail`);
                  }}
                >
                  Forgot password?
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={handleCaptchaChange}
                ref={recaptchaRef}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  whiteSpace: "nowrap",
                  background: "#ECA601",
                  padding: "10px 30px",
                  borderRadius: "10px",
                  // Adding inner shadow using a pseudo-element
                  color: "#F1F5FD",
                  position: "relative",
                  lineHeight: "22px",
                  overflow: "hidden",
                  fontWeight: "500",
                  fontSize: "18px",
                  boxShadow: "0px 3px 3px #00000040", // Outer shadow
                  textTransform: "capitalize",
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: "0 20px 20px 0",
                    background: "rgba(255, 255, 255, 0.1)", // Color for the inner shadow
                    filter: "blur(4px)",
                    zIndex: 1,
                  },
                  "&:hover:before": {
                    background: "rgba(255, 255, 255, 0.2)", // Darker on hover for inner shadow
                  },
                }}
                // onClick={handleSearchClick}
                // disabled={loading} // Disable button when loading
                onClick={(e) => handleSubmit(e)}
              >
                {/* {loading ? (
                                    <CircularProgress size={24} color="inherit" /> // Show loader when loading
                                ) : (
                                    "Search"
                                )} */}
                {isloading ? (
                  <CircularProgress size={22} sx={{ color: "white" }} />
                ) : (
                  "Signup"
                )}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBlock: "20px",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: "#141414CC",
            }}
          >
            Already part of our community?
          </Typography>
          <Button
            variant="text"
            sx={{
              color: "#3F78E0",
              textTransform: "none",
              marginLeft: "5px",
              textDecoration: "underline",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "18px",
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Sign in and continue exploring!
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default Signup1;
