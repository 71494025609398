import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Rightshape from "../../Assets/Svg/Profilepopup/Rightshape.svg"
import Leftshape from "../../Assets/Svg/Profilepopup/Leftshape.svg"

const UserProfilePopup = ({ userPhoto, userName, userEmail, onLogout, onProfileClick, profileRef, DefaultUserPhoto }) => {
    return (
        <Box
            ref={profileRef}
            sx={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                padding: "36px 30px",
                width: "184px",
                height: "176px",
                borderRadius: "22px",
                boxShadow: "-6px 6px 9px #00000040",
                position: "absolute",
                top: "70px",
                right: 20,
                backgroundColor: "white",
                zIndex: 999,
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <img src={Leftshape} alt='Shape' style={{ position: "absolute", top: "0", left: "0", padding: "6px" }} />
                <img src={Rightshape} alt='Shape' style={{ position: "absolute", bottom: "0", right: "0", padding: "6px" }} />
                {/* User Info */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer"
                    }}

                >
                    <img
                        src={userPhoto ? userPhoto : DefaultUserPhoto}
                        alt="User"
                        style={{
                            border: "1px solid #296D99",
                            borderRadius: "50%",
                            width: "70px",
                            height: "70px",
                            objectFit: "cover",
                        }}
                    />


                    <Typography
                        sx={{
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "18px",
                            color: "#303030",
                        }}
                    >
                        {userName}
                    </Typography>

                </Box>

                {/* Action Buttons */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <Button
                        sx={{
                            textAlign: "center",
                            borderRadius: "10px",
                            background: "#3F78E0",
                            width: "150px",
                            color: "white",
                            boxShadow:
                                "0px 5px 5px #00000040, inset 4px 7px 5px #FFFFFF30",
                            "&:hover": {
                                background: "#2E5DBA",
                            },
                        }}
                        onClick={onProfileClick}
                    >
                        Profile
                    </Button>
                    <Button
                        sx={{

                            color: "#3F78E0",
                            border: "solid 2px #3F78E0",
                            width: "150px",
                            borderRadius: "8px",
                            fontSize: "15px",
                            lineHeight: "20px",
                            fontWeight: "500",
                        }}
                        onClick={onLogout}
                    >
                        LOG OUT
                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                            style={{ marginLeft: "10px" }}>
                            <path d="M12.5353 13.8888V15.5102C12.5353 15.9402 12.3644 16.3527 12.0603 16.6567C11.7563 16.9608 11.3439 17.1316 10.9138 17.1316H4.42808C3.99805 17.1316 3.58563 16.9608 3.28155 16.6567C2.97747 16.3527 2.80664 15.9402 2.80664 15.5102V5.78159C2.80664 5.35156 2.97747 4.93914 3.28155 4.63506C3.58563 4.33099 3.99805 4.16016 4.42808 4.16016H10.5895C11.485 4.16016 12.5353 4.88615 12.5353 5.78159V7.40303" stroke="#3F78E0" stroke-width="1.29932" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.1279 13.89L18.3713 10.6467L15.1279 7.40332" stroke="#3F78E0" stroke-width="1.29932" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.3457 10.6475H17.7229" stroke="#3F78E0" stroke-width="1.29932" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </Button>

                </Box>
            </Box>
        </Box>
    );
};

export default UserProfilePopup;
