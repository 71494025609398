import React from "react";
import { Styles } from "./styles";

const TotalCard = ({
  backgroundColor,
  countServicesPending,
  countServicesActive,
  countServicesCOMPLETE,
  countOffersPending,
  countOffersActive,
  countOffersCOMPLETE
}) => {
  return (
    <Styles backgroundColor={backgroundColor}>
      <div className="title">
        {countServicesPending
          ? "Pending"
          : countServicesActive
            ? "Active"
            : countServicesCOMPLETE && "Completed"}
      </div>
      <div className="data">
        <div className="item">
          <div className="number">
            {countServicesPending ||
              countServicesActive ||
              countServicesCOMPLETE}
          </div>
          <div className="name">Services</div>
        </div>
        {countOffersPending && ( // Conditionally render countOffersPending
          <div className="item">
            <div className="number">{countOffersPending}</div>
            <div className="name"> Offers</div>
          </div>
        )}
        {countOffersActive && ( // Conditionally render countOffersActive
          <div className="item">
            <div className="number">{countOffersActive}</div>
            <div className="name"> Offers</div>
          </div>
        )}
        {countOffersCOMPLETE && (
          <div className="item">
            <div className="number">{countOffersCOMPLETE}</div>
            <div className="name"> Offers </div>
          </div>
        )}
      </div>
    </Styles>
  );
};

export default TotalCard;
