import { styled, css, keyframes } from "@mui/system";

// Keyframes for moving "M" letters
const moveS = keyframes`
  from {
    left: -38px;
    width: 88px;
  }
  to {
    left: 0;
    width: 38px;
  }
`;

const moveM = keyframes`
  from {
    left: -20px;
    width: 88px;
  }
  to {
    left: 38px;
    width: 50px;
  }
`;

const moveSecondM = keyframes`
  from {
    left: -20px;
  }
  to {
    left: 88px;
  }
`;

const moveLine = keyframes`
  from {
    width: 0;
  }
  to {
    width: 138px;
  }
`;

const moveStart = keyframes`
  from {
    width: 0;
    height: 0;
    transform: rotate(360deg);
    top: -7px;
      right: -9.5px;
  }
  to {
    width: 36px;
    height: 36px;
    transform: rotate(0deg);
    top: -25px;
      right: -27.5px;
  }
`;

export const Styles = styled("div")(
  ({ theme }) => css`
    position: relative;
    display: flex;
    margin-top: 20px;
    margin-bottom: 30px;
    .smm {
      position: relative;
      display: flex;
      background: #fff;
      width: 138px;
      overflow: hidden;
      div {
        background: #fff;
      }
      .s-letter {
        position: absolute;
        left: -38px;
        top: 0;
        width: 88px;
        height: 48px;
        overflow: hidden;
        z-index: 3;
        animation: 0.5s ${moveS} 0s forwards ease-in-out;
      }
      .m-letter {
        position: absolute;
        left: -20px;
        top: 0;
        width: 88px;
        height: 48px;
        overflow: hidden;
        z-index: 2;
        animation: 0.5s ${moveM} 0.5s forwards ease-in-out;
      }
      .second-m-letter {
        position: absolute;
        left: -20px;
        top: 0;
        width: 50px;
        height: 48px;
        overflow: hidden;
        z-index: 1;
        animation: 0.5s ${moveSecondM} 1s forwards ease-in-out;
      }
    }
    .line {
      position: absolute;
      width: 0;
      height: 19px;
      bottom: -20px;
      left: 138px;
      animation: 0.5s ${moveLine} 1.5s forwards ease-in-out;
      overflow: hidden;
      img {
        width: 138px;
        height: 100%;
        object-fit: contain;
      }
    }
    .star {
      position: absolute;
      width: 0px;
      height: 0px;
      top: -7px;
      right: -9.5px;
      transform: rotate(360deg);
      animation: 0.5s ${moveStart} 2s forwards ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        width: 36px;
        height: 100%;
        object-fit: contain;
      }
    }
  `
);
