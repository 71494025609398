import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AccordionItem = ({ title, data }) => {
  return (
    <Accordion
      sx={{
        borderRadius: "15px",
        overflow: "hidden",
        marginBottom: "20px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <AddCircleOutlineIcon
            sx={{
              fontWeight: "600",
              fontSize: "24px",
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          backgroundColor: "#D2E2EC",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontWeight: "600",
            fontSize: "21px",
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "#cddaec",
        }}
      >
        {data}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionItem;
