import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { Styles } from "./styles";

const Filters = ({
  searchTerm,
  setSearchTerm,
  handleSearchClick,
  handleCountryChange,
  handleFilterChange,
  loading,
  sortedOptions,
  filter,
}) => {
  return (
    <Styles>
      <div className="filters-container">
        <div className="item keyword">
          <TextField
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              style: { color: "#434343", fontSize: "13px" },
            }}
            // onChange={(e) => {
            //     handleChange("smmpanel_name", e.target.value);
            // }}
            placeholder="Type a keyword to search for a SMM panel"
            sx={{
              background: "white",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none", // removes the outline
                },
                "& input": {
                  borderRadius: "10px",
                  padding: "10px ",
                },
              },
            }}
          />
        </div>
        {/* <div className="item providers">
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              boxShadow: "1px 2px 4px #454040",
              borderRadius: "8px",
              background: "#F5F8FE",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  borderColor: "#6B9DFE",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#007BFF",
                },
              },
            }}
          >
            <InputLabel
              id="dropdown-label"
              sx={{
                color: "#474747",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "14px",
                textAlign: "start",
                width: "100%",
                "&.Mui-focused": {
                  color: "#474747",
                },
              }}
            >
              Filter By Trust
            </InputLabel>
            <Select
              labelId="dropdown-label"
              defaultValue=""
              value={filter}
              onChange={handleFilterChange}
              // value={selectedGategories}
              // onChange={handleGategoriesChange}
              label="Categories"
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "#F5F8FE",
                    borderRadius: "8px",
                    boxShadow: "1px 2px 4px #454040",
                    mt: 1,
                    maxHeight: "200px", // Set maximum height for the dropdown
                    overflowY: "auto", // Enable scrolling
                    "& .MuiMenuItem-root": {
                      fontSize: "14px",
                      color: "#474747",
                      borderBottom: "1px solid #9E9E9E",
                      "&:hover": {
                        bgcolor: "#6B9DFE",
                        color: "#FFFFFF",
                      },
                      "&.Mui-selected": {
                        bgcolor: "#007BFF",
                        color: "#FFFFFF",
                        "&:hover": {
                          bgcolor: "#0056b3",
                        },
                      },
                    },
                    "&::-webkit-scrollbar": {
                      width: "8px", // Width of the scrollbar
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#F5F8FE", // Color of the scrollbar track
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#004571", // Color of the scrollbar thumb
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#004571", // Color of the scrollbar thumb on hover
                    },
                    "&::-moz-scrollbar": {
                      width: "8px",
                      background: "#004571",
                    },
                    "&::-moz-scrollbar-thumb": {
                      background: "#004571",
                      borderRadius: "4px",
                    },
                    "&::-moz-scrollbar-thumb:hover": {
                      background: "#007BFF",
                    },
                  },
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
              sx={{
                "& .MuiSelect-select": {
                  padding: "10px 12px", // Adjust this for select input height and padding
                  // height: '48px', // Adjust this for select input height
                  display: "flex",
                  alignItems: "center",
                },
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <MenuItem value="All">All provider</MenuItem>
              <MenuItem value="Trusted">Trusted</MenuItem>
              <MenuItem value="Not Trust">Not Trust</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="item countries">
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              boxShadow: "1px 2px 4px #454040",
              borderRadius: "8px",
              background: "#F5F8FE",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  borderColor: "#6B9DFE",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#007BFF",
                },
              },
            }}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={sortedOptions} // Use sortedOptions directly
              getOptionLabel={(option) => option.label}
              onChange={handleCountryChange}
              renderTags={(selectedOptions) =>
                selectedOptions.length === 1
                  ? selectedOptions[0].label
                  : `${selectedOptions.length} selected`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Countries"
                  variant="outlined"
                  InputLabelProps={{
                    sx: {
                      color: "#474747",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "14px",
                      "&.Mui-focused": {
                        color: "#007BFF",
                      },
                    },
                  }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    padding: "5px",
                    color: "#333",
                    fontSize: "14px",
                    borderBottom: "1px solid #9E9E9E",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    sx={{
                      color: "#007BFF",
                      "&.Mui-checked": {
                        color: "#007BFF",
                      },
                    }}
                  />
                  {option.label}
                </li>
              )}
              ListboxProps={{
                sx: {
                  maxHeight: "200px",
                  backgroundColor: "#f5f8fe",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#F5F8FE",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#004571",
                    borderRadius: "4px",
                  },
                },
              }}
              sx={{
                "& .MuiAutocomplete-popupIndicator": {
                  color: "#707275",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: "#707275",
                },
                "& .MuiOutlinedInput-root": {
                  padding: "10px 12px",
                  height: "43px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            />
          </FormControl>
        </div> */}
        <div className="item button">
          <Button
            variant="contained"
            color="primary"
            sx={{
              whiteSpace: "nowrap",
              background: searchTerm ? "#ECA601" : "#ccc", // Change background color when disabled
              padding: "10px 30px",
              borderRadius: "10px",
              color: searchTerm ? "#F1F5FD" : "#666", // Adjust text color when disabled
              position: "relative",
              lineHeight: "22px",
              overflow: "hidden",
              fontWeight: "500",
              fontSize: "18px",
              boxShadow: searchTerm ? "0px 3px 3px #00000040" : "none", // Remove shadow when disabled
              textTransform: "capitalize",
              "&:hover": {
                background: searchTerm ? "#dca301" : "#ccc", // Adjust hover effect when disabled
              },
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "0 20px 20px 0",
                background: searchTerm ? "rgba(255, 255, 255, 0.1)" : "transparent",
                filter: "blur(4px)",
                zIndex: 1,
              },
              "&:hover:before": {
                background: searchTerm ? "rgba(255, 255, 255, 0.2)" : "transparent",
              },
            }}
            onClick={handleSearchClick}
            disabled={!searchTerm || loading} // Disable button if searchTerm is empty or loading
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" /> // Show loader when loading
            ) : (
              "Search"
            )}
          </Button>

        </div>
      </div>
    </Styles>
  );
};

export default Filters;
