import React from "react";
import { Styles } from "./styles";
import Progress from "./progress";

const VisitorsStatisticsCard = ({ data }) => {
  // Convert the object into an array of values
  const countryData = Object.values(data);

  const formatPercentage = (value) => {
    return value !== undefined ? `${parseFloat(value).toFixed(1)}%` : "0.0%";
  };

  return (
    <Styles>
      <div className="card-item">
        <div className="title">Country statistics</div>
      </div>
      <div className="card-item">
        {countryData.length > 0 &&
          countryData.map((item, idx) => (
            <Progress
              key={idx}
              title={item?.country}
              percentage={formatPercentage(item?.percentage)}
            />
          ))}
      </div>
    </Styles>
  );
};

export default VisitorsStatisticsCard;
