import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CountryFilter from "../filters/countryFilter";
import ArrowImg from "../../../Assets/images/icons/arrow-down.png";

const CustomArrowSVG = () => (
  <img
    src={ArrowImg} // Replace with your custom arrow image path
    alt="arrow"
    style={{ width: "14px", height: "14px", transform: "rotate(0deg)" }}
  />
);

const Filters = (props) => {
  const {
    selectedFeature,
    toggleFeatureFilter,
    valueMap,
    marks,
    handleChange,
    value,
    Slider,
    selectedFilters,
    toggleFilter,
    handleCountryChange,
    sortedOptions,
    setSelectedSubcategory,
    getSubCategories,
    setSelectedCategories,
    selectedCategories,
    categories,
    handleSearchClick,
    maxPrice,
    minPrice,
    selectedSubcategory,
    selectedCountry,
    searchTerm,
    isLoading,
    handleKeyDown,
    setSearchTerm,
  } = props;
  return (
    <Box
      sx={{
        padding: { xs: "30px 15px", sm: "30px 40px" },
        marginTop: "40px",
        background: "#D2E2EC",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={12}>
          <Box
            display="flex"
            alignItems="center"
            sx={{ width: "100%", maxWidth: 1000, margin: "0 auto" }}
          >
            <TextField
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type here what you are looking for ..."
              sx={{
                width: "90%", // Adjust this percentage as needed
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                    borderRadius: "8px 0 0 15px",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },

                  boxShadow: "0px 3px 3px #00000040",
                  borderRadius: "15px 0 0 15px",
                },
              }}
              InputProps={{
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "14px",
                    backgroundColor: "white",
                    color: "#333",
                    fontSize: "16px",
                    outline: "none",
                    borderRadius: "15px 0 0 15px",
                  },
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={
                isLoading || // Prevent clicking while loading
                !(
                  searchTerm.trim() !== "" ||
                  selectedCountry.length > 0 ||
                  selectedCategories ||
                  selectedSubcategory.length > 0 ||
                  selectedFilters.length > 0 ||
                  selectedFeature.length > 0 ||
                  minPrice ||
                  maxPrice
                )
              }
              sx={{
                whiteSpace: "nowrap",
                background: "#ECA601",
                padding: "12px 44px",
                borderRadius: "0 15px 15px 0",
                // Adding inner shadow using a pseudo-element
                position: "relative",
                overflow: "hidden",
                fontSize: "15px",
                boxShadow: "0px 3px 3px #00000040", // Outer shadow
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "0 20px 20px 0",
                  background: "rgba(255, 255, 255, 0.1)", // Color for the inner shadow
                  filter: "blur(4px)",
                  zIndex: 1,
                },
                "&:hover:before": {
                  background: "rgba(255, 255, 255, 0.2)", // Darker on hover for inner shadow
                },
              }}
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          lg={1}
          sx={{ display: { xs: "none", lg: "block" } }}
        ></Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              boxShadow: "1px 2px 6px #45404060",
              borderRadius: "8px",
              background: "#F5F8FE",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "0px solid black",
                },
                "&:hover fieldset": {
                  borderColor: "#6B9DFE",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#607d8b", // Border color on focus
                  borderWidth: "2px", // Increase border width for better visibility
                },
              },
            }}
          >
            <Autocomplete
              options={categories}
              getOptionLabel={(option) => option.label || ""}
              popupIcon={<CustomArrowSVG />}
              value={
                categories.find((cat) => cat.id === selectedCategories) || null
              }
              onChange={(event, newValue) => {
                setSelectedCategories(newValue ? newValue.id : "");
                setSelectedSubcategory(""); // Reset subcategory when category changes
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Category"
                  variant="outlined"
                  InputLabelProps={{
                    sx: {
                      color: "#474747",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      fontFamily: "Instrument_Sans",
                      "&.Mui-focused": {
                        color: "black",
                      },
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    padding: "10px",
                    color: "#333",
                    fontSize: "14px",
                    borderBottom: "1px solid #9E9E9E",
                  }}
                >
                  {option.label}
                </li>
              )}
              ListboxProps={{
                sx: {
                  maxHeight: "200px", // Set the maximum height of the list
                  backgroundColor: "#f5f8fe",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  "& .MuiAutocomplete-option": {
                    // '&[aria-selected="true"]': {
                    //   backgroundColor: "#007bff",
                    //   color: "#fff",
                    // },
                    // '&[data-focus="true"]': {
                    //   backgroundColor: "#007bff",
                    // },
                  },
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#F5F8FE",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#004571",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#004571",
                  },
                },
              }}
              sx={{
                "& .MuiAutocomplete-popupIndicator": {
                  color: "#707275",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: "#707275",
                },
                "& .MuiOutlinedInput-root": {
                  padding: "10px 12px",
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              boxShadow: "1px 2px 6px #45404060",
              borderRadius: "8px",
              background: "#F5F8FE",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "0px solid black", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "#6B9DFE", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#607d8b", // Border color on focus
                  borderWidth: "2px", // Increase border width for better visibility
                },
              },
            }}
          >
            <Autocomplete
              multiple
              options={getSubCategories()}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              popupIcon={<CustomArrowSVG />}
              onChange={(event, newValues) => {
                setSelectedSubcategory(newValues.map((sub) => sub.id));
              }}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    padding: "5px",
                    color: "#333",
                    fontSize: "14px",
                    borderBottom: "1px solid #9E9E9E",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    sx={{
                      color: "#007BFF",
                      "&.Mui-checked": {
                        color: "#007BFF",
                      },
                    }}
                  />
                  {option.label}
                </li>
              )}
              renderTags={(selectedOptions) =>
                selectedOptions.length === 1
                  ? selectedOptions[0].label
                  : `${selectedOptions.length} selected`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select subcategories"
                  variant="outlined"
                  InputLabelProps={{
                    sx: {
                      color: "#474747",
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Instrument_Sans",
                      lineHeight: "20px",
                      "&.Mui-focused": {
                        color: "black",
                      },
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  maxHeight: "240px",
                  backgroundColor: "#f5f8fe",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  "& .MuiAutocomplete-option": {
                    // '&[aria-selected="true"]': {
                    //     backgroundColor: '#007bff',
                    //     color: '#fff',
                    // },
                    // '&[data-focus="true"]': {
                    //     backgroundColor: '#007bff',
                    // },
                  },
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#F5F8FE",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#004571",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#004571",
                  },
                },
              }}
              sx={{
                "& .MuiAutocomplete-popupIndicator": {
                  color: "#707275",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: "#707275",
                },
                "& .MuiOutlinedInput-root": {
                  padding: "10px 12px",
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                },
              }}
            />
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3} lg={2}>
            <PanelFilter
              panels={panels}
              setSelectedPanels={setSelectedPanels}
            />
          </Grid> */}
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CountryFilter
            sortedOptions={sortedOptions}
            handleCountryChange={handleCountryChange}
          />
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          lg={1}
          sx={{ display: { xs: "none", lg: "block" } }}
        ></Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginTop: "10px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          sx={{ order: { xs: 1, sm: 1, md: 1, lg: 1 }, marginBottom: "10px" }}
        >
          <Box
            sx={{
              background: "#F5F8FE",
              padding: "15px 20px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "20px",
                  fontWeight: "600",
                  color: "#474747",
                }}
              >
                Popular
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                {/* Replace the following Typography components with buttons */}
                {["Provider", "Reseller", "trust", "oldDomain"].map(
                  (filter) => {
                    const displayName =
                      filter === "oldDomain"
                        ? "Old Domain"
                        : filter === "trust"
                          ? "Trust"
                          : filter;

                    return (
                      <Typography
                        className="search-filter-fonts"
                        key={filter}
                        onClick={() => toggleFilter(filter)}
                        sx={{
                          boxShadow: "1px 2px 4px #454040",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "16px",
                          color: selectedFilters.includes(filter)
                            ? "#F0F4FB"
                            : "#474747",
                          padding: "8px",
                          background: selectedFilters.includes(filter)
                            ? "#4E7AC9"
                            : "#F0F4FB",
                          border: "2px solid #A7A7A7",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          transition: "all 0.3s",
                          "&:hover": {
                            background: "#4E7AC9",
                            color: "#F0F4FB",
                          },
                        }}
                      >
                        {displayName}
                      </Typography>
                    );
                  }
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={1}
          md={3}
          lg={0}
          sx={{
            order: { xs: 3, sm: 3, md: 3, lg: 2 },
            display: { lg: "none" },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={6}
          lg={4}
          sx={{ order: { xs: 4, sm: 4, md: 4, lg: 3 } }}
        >
          <Box
            sx={{
              background: "#F5F8FE",
              padding: " 15px 30px",
              borderRadius: "10px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "20px",
                  fontWeight: "600",
                  color: "#474747",
                }}
              >
                Price
              </Typography>
              <Slider
                value={value}
                onChange={handleChange}
                step={null} // No intermediate steps
                marks={marks} // Define the fixed values
                min={0}
                max={100}
                valueLabelDisplay="auto"
                valueLabelFormat={(val) => valueMap[val]} // Display the mapped value
                disableSwap // Prevent range handles from crossing each other
                sx={{
                  padding: "11px 0",
                  color: "#0FCF65", // Customize track color
                  "& .MuiSlider-thumb": {
                    backgroundColor: "#0FCF65", // Thumb color
                    border: "2px solid #0FCF65",
                  },
                  "& .MuiSlider-track": {
                    backgroundColor: "#0FCF65", // Track color
                  },
                  "& .MuiSlider-rail": {
                    background: "gray",
                  },
                  "& .MuiSlider-mark": {
                    backgroundColor: "#0FCF65", // Mark color
                    height: "8px",
                    width: "8px",
                    borderRadius: "50%",
                  },
                  "& .MuiSlider-markLabel": {
                    color: "#474747", // Mark label color
                    fontWeight: "500",
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={1}
          md={3}
          lg={0}
          sx={{
            order: { xs: 5, sm: 5, md: 5, lg: 4 },
            display: { lg: "none" },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          sx={{
            order: { xs: 2, sm: 2, md: 2, lg: 5 },
            marginBottom: "10px",
          }}
        >
          <Box
            sx={{
              background: "#F5F8FE",
              padding: " 15px 20px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "20px",
                  fontWeight: "600",
                  color: "#474747",
                }}
              >
                Features
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                {/* Replace the following Typography components with buttons */}
                {["Refill", "New Services", "Dripfeed", "Cancel"].map(
                  (Feature) => (
                    <Typography
                      className="search-filter-fonts"
                      key={Feature}
                      onClick={() => toggleFeatureFilter(Feature)}
                      sx={{
                        boxShadow: "1px 2px 4px #454040",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "16px",
                        color: selectedFeature.includes(Feature)
                          ? "#F0F4FB"
                          : "#474747",
                        padding: "8px",
                        background: selectedFeature.includes(Feature)
                          ? "#4E7AC9"
                          : "#F0F4FB",
                        border: "2px solid #A7A7A7",
                        borderRadius: "8px",
                        textAlign: "center",
                        cursor: "pointer",
                        transition: "all 0.3s",
                        "&:hover": {
                          background: "#4E7AC9",
                          color: "#F0F4FB",
                        },
                      }}
                    >
                      {Feature}
                    </Typography>
                  )
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
