import { React, useState, useEffect, Fragment } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { api_Routes } from "../../../api_Routes";
import LinearProgress from "@mui/material/LinearProgress";
import { Helper } from "../../../tools/Helper";
import { Slider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PinUp from "./PinsDown&PinsUp/PinUp";
import PinDown from "./PinsDown&PinsUp/PinDown";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

import DataTableColumn from "./data-table-column";
import Filters from "../filters";

const ServiceSearch = ({ countries, categories, pinup, pinDown }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [triggerSearch, setTriggerSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const columns = [
    { id: "id", label: "ID", minWidth: 50 },
    { id: "name", label: "Service", minWidth: 100 },
    { id: "panelUrl", label: "Panel", minWidth: 100 },
    { id: "rate_per_1000", label: "Rate", minWidth: 50 },
    { id: "order_range", label: "Min & Max", minWidth: 100 }, 
    { id: "category", label: "Category", minWidth: 200 },
    { id: "description", label: "Description", minWidth: 200 },
    { id: "action", label: "Action", minWidth: 100 },
  ];
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0); 
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [onSee, setOnSee] = useState(true);
  const [sortedOptions, setSortedOptions] = useState([...countries]);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const marks = [
    { value: 0, label: "0" },
    { value: 20, label: "1$" },
    { value: 40, label: "5$" },
    { value: 60, label: "10$" },
    { value: 80, label: "100$" },
    { value: 100, label: "1000$" },
  ];
  const valueMap = {
    0: 0,
    20: 1,
    40: 5,
    60: 10,
    80: 100,
    100: 1000,
  };

  useEffect(() => {
    // Sort whenever country or selectedCountry changes
    setSortedOptions(
      [...countries].sort(
        (a, b) =>
          selectedCountry.includes(b.id) - selectedCountry.includes(a.id)
      )
    );
  }, [countries, selectedCountry]);

  const handleCountryChange = (event, newValues) => {
    setSelectedCountry(newValues.map((Cou) => Cou.id));
  };

  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [value, setValue] = useState([1, 100]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setMinPrice(valueMap[newValue[0]]);
    setMaxPrice(valueMap[newValue[1]]);
  };

  const baseApi = api_Routes.Search.servers;
  const toggleFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = [...prevFilters];

      // Handle Provider and Reseller exclusivity
      if (filter === "Provider") {
        if (newFilters.includes("Reseller")) {
          newFilters.splice(newFilters.indexOf("Reseller"), 1); // Remove Reseller if Provider is selected
        }
      } else if (filter === "Reseller") {
        if (newFilters.includes("Provider")) {
          newFilters.splice(newFilters.indexOf("Provider"), 1); // Remove Provider if Reseller is selected
        }
      }

      // Toggle the selected filter
      if (newFilters.includes(filter)) {
        newFilters.splice(newFilters.indexOf(filter), 1); // Remove if already selected
      } else {
        newFilters.push(filter); // Add if not selected
      }

      return newFilters;
    });
  };

  const toggleFeatureFilter = (Feature) => {
    setSelectedFeature((PrevFeature) => {
      const newFeature = [...PrevFeature];

      if (newFeature.includes(Feature)) {
        newFeature.splice(newFeature.indexOf(Feature), 1); // Remove if already selected
      } else {
        newFeature.push(Feature); // Add if not selected
      }
      return newFeature;
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    get_service(signal);

    return () => {
      controller.abort();
    };
  }, [triggerSearch, page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getSubCategories = () => {
    const category = categories.find((cat) => cat.id === selectedCategories);
    return category ? category.subCategories : [];
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const get_service = async (signal) => {
    setIsLoading(true);
    let url = `${baseApi}?page=${page + 1}&limit=${limit}`;
    if (triggerSearch) {
      url += `&keyword=${triggerSearch}`;
    }
    if (selectedCountry?.length > 0) {
      selectedCountry.forEach((id) => {
        url += `&country_id[]=${id}`;
      });
    }

    if (selectedCategories) {
      url += `&category_id=${selectedCategories}`;
    }
    if (selectedSubcategory?.length > 0) {
      selectedSubcategory.forEach((id) => {
        url += `&sub_category_id[]=${id}`;
      });
    }

    if (selectedFilters?.length > 0) {
      selectedFilters.forEach((filter) => {
        url += `&${filter}=1`; // Add each filter as a query parameter
      });
    }
    if (selectedFeature?.length > 0) {
      selectedFeature.forEach((Feature) => {
        url += `&${Feature}=1`; // Add each filter as a query parameter
      });
    }
    if (minPrice && maxPrice) {
      url += `&minPrice=${minPrice}&maxPrice=${maxPrice}`;
    } else if (minPrice && !maxPrice) {
      url += `&minPrice=${minPrice}&maxPrice=${1000}`;
    } else if (!minPrice && maxPrice) {
      url += `&minPrice=${0}&maxPrice=${maxPrice}`;
    }

    const { response } = await Helper.Get({
      url: url,
      hasToken: true,
      signal: signal,
    });

    if (response) {
      const { data, pagination } = response;
      setTotalItems(pagination.total);

      setDataTable(
        data.map((panel) => ({
          id: panel.id,
          name: panel.name,
          panelUrl: panel.panelUrl,
          rate_per_1000: panel.rate_per_1000,
          order_range: `${panel.min_order} / ${panel.max_order}`,
          description: panel.description,
          category: panel.category,
          search_first: panel.search_first,
          panel_uuid: panel.panel_uuid,
          action: "View",
        }))
      );
    }
    setIsLoading(false);
  };

  const handleSearchClick = () => {
    if (
      searchTerm.trim() !== "" ||
      selectedCountry?.length > 0 ||
      selectedCategories ||
      selectedSubcategory?.length > 0 ||
      selectedFilters?.length > 0 ||
      selectedFeature?.length > 0 ||
      minPrice ||
      maxPrice
    ) {
      setShowTable(true);
      setIsLoading(true); // Disable the button while the search is in progress
      setTimeout(() => {
        if (searchTerm.trim() !== "") {
          if (searchTerm !== triggerSearch) {
            setTriggerSearch(searchTerm);
          } else {
            get_service();
          }
        } else {
          get_service();
        }
      }, 0);

      if (onSee) {
        Helper.Post({
          url: api_Routes.Pin.OnSee,
          hasToken: true,
        });
        setOnSee(false);
      }

      setIsLoading(false); // Re-enable the button after the search completes
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !isLoading && 
      (searchTerm.trim() !== "" ||
        selectedCountry?.length > 0 ||
        selectedCategories ||
        selectedSubcategory?.length > 0 ||
        selectedFilters?.length > 0 ||
        selectedFeature?.length > 0 ||
        minPrice ||
        maxPrice)
    ) {
      handleSearchClick();
    }
  };

  return (
    <>
      <Filters
        selectedFeature={selectedFeature}
        toggleFeatureFilter={toggleFeatureFilter}
        valueMap={valueMap}
        marks={marks}
        handleChange={handleChange}
        value={value}
        Slider={Slider}
        selectedFilters={selectedFilters}
        toggleFilter={toggleFilter}
        handleCountryChange={handleCountryChange}
        sortedOptions={sortedOptions}
        setSelectedSubcategory={setSelectedSubcategory}
        getSubCategories={getSubCategories}
        setSelectedCategories={setSelectedCategories}
        selectedCategories={selectedCategories}
        categories={categories}
        handleSearchClick={handleSearchClick}
        maxPrice={maxPrice}
        minPrice={minPrice}
        selectedSubcategory={selectedSubcategory}
        selectedCountry={selectedCountry}
        searchTerm={searchTerm}
        isLoading={isLoading}
        handleKeyDown={handleKeyDown}
        setSearchTerm={setSearchTerm}
      />

      {showTable && (
        <>
          <PinUp pinup={pinup} />
          {dataTable?.length || isLoading > 0 ? (
            <Container
              fluid={true}
              sx={{
                marginTop: "20px",
                marginBottom: "30px",
                padding: 0,
                maxWidth: "100vw",
                overflowX: "hidden",
                "@media (min-width: 1200px)": {
                  maxWidth: "1600px",
                },
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Card sx={{ borderRadius: "22px" }}>
                    <CardContent sx={{ background: "#D2E2EC" }}>
                      {isLoading && <LinearProgress />}
                      <TableContainer style={{ background: "#D2E2EC" }}>
                        <Table stickyHeader sx={{ background: "#D2E2EC" }}>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, index) => (
                                <TableCell
                                  key={column.id}
                                  style={{
                                    background: "#D2E2EC",
                                    fontSize: "20px",
                                    fontWeight: "bolder",
                                    lineHeight: "20px",
                                    color: "#474747",
                                    border: "1px solid #00000080",
                                    textAlign: "center",
                                    // border: '1px solid #00000080', // Default border for all cells
                                    // borderLeft: (index === 1) ? '1px solid #00000080' : '1px solid #00000080', // No left border for the first column
                                    // borderRight: (index >= 1 && index < 4) ? '1px solid #00000080' : (index === 5 ? '1px solid #00000080' : '1px solid #00000080'), // Remove right border for the first four columns, none for index 5
                                    // borderBottom: '1px solid #00000080',// Optional: Hide the bottom border of body cells
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataTable.map((row) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                style={{
                                  backgroundColor: row.search_first
                                    ? "#93BBD5"
                                    : "#D2E2EC",
                                }}
                              >
                                {columns.map((column, index) => {
                                  const value = row[column.id];
                                  return (
                                    <DataTableColumn
                                      row={row}
                                      column={column}
                                      value={value}
                                      panelUuid={row.panel_uuid}
                                    />
                                  );
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Container
              fluid={true}
              sx={{
                marginTop: "20px",
                marginBottom: "30px",
                padding: 0,
                maxWidth: "100vw",
                overflowX: "hidden",
                "@media (min-width: 1200px)": {
                  maxWidth: "1600px",
                },
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Card sx={{ borderRadius: "22px" }}>
                    <CardContent sx={{ background: "#D2E2EC" }}>
                      {isLoading && <LinearProgress />}
                      <TableContainer style={{ background: "#D2E2EC" }}>
                        <Table stickyHeader sx={{ background: "#D2E2EC" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{
                                  background: "#D2E2EC",
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                  lineHeight: "20px",
                                  color: "#474747",
                                  border: "1px solid #00000080",
                                  textAlign: "center",
                                }}
                              >
                                There are no data with this search data
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          )}

          <PinDown pinDown={pinDown} />
          {/* {isLoading && <LinearProgress />} */}
          {dataTable?.length > 0 && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                background: "#D2E2EC",
                flexWrap: "wrap",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalItems}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleLimitChange}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default ServiceSearch;
