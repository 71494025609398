// MenuItems.jsx
import React from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { NavLink, Link } from "react-router-dom";
import UserPhoto from "../../Assets/userphoto.png";
import NotificationsList from "./NotificationsList";
import UserProfilePopup from "./ProfilePopUp";
import Logo from "../../Assets/Svg/main-logo.svg";
import { isLogin } from "../../tools/Helper";

const MenuItems = ({
  isMobile,
  isSmallScreen,
  navigate,
  showNotifications,
  toggleNotifications,
  notifications,
  showProfile,
  toggleProfile,
  handleLogout,
  handleProfile,
  profileRef,
  setShowNotifications,
  userphoto,
  name,
  handleDashboardClick,
  onLogout,
  DefaultUserPhoto,
  onCloseDrawer,
}) => {
  return (
    <Box
      sx={{
        marginInline: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        
      }}
    >
      <Box>
        <Box display="flex" borderRadius="3px">
          <Link to="/home" style={{ cursor: "pointer" }} onClick={onCloseDrawer}>
            <img src={Logo} alt="Logo" />
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
          }}
        >
          <NavLink to="/" className="drawer-item" activeClassName="active" onClick={onCloseDrawer}>
            <span>Service Finder</span>
          </NavLink>
          <NavLink
            to="/Providers"
            className="drawer-item"
            activeClassName="active"
            onClick={onCloseDrawer}
          >
            <span>Providers</span>
          </NavLink>
          <NavLink
            to="/OurServices"
            className="drawer-item"
            activeClassName="active"
            onClick={onCloseDrawer}
          >
            <span>Our services</span>
          </NavLink>
          <NavLink
            onClick={onCloseDrawer}
            to="/OurOffers"
            className="drawer-item"
            activeClassName="active"
          >
            <span>Our offers</span>
          </NavLink>
          <NavLink
            onClick={onCloseDrawer}
            to="/AddPanel"
            className="drawer-item"
            activeClassName="active"
          >
            <span>Add Panel</span>
          </NavLink>
          {isLogin ? (
            <Box
              position="relative"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                gap: "20px",
                marginTop: "30px"
              }}
            >
              {isMobile && (
                <>

                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                    <img
                      src={userphoto || DefaultUserPhoto}
                      alt="User"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        border: "1px solid #296D99"
                      }}
                    />
                    <Typography sx={{ color: "#303030", fontWeight: "500", fontSize: "18px", lineHeight: "18px" }}>
                      {name}
                    </Typography>
                    <Button
                      sx={{
                        textAlign: "center",
                        borderRadius: "10px",
                        background: "#3F78E0",
                        width: "150px",
                        color: "white",
                        boxShadow: `
      -2px 4px 4px #00000040, /* Drop shadow */
      inset 0px 5px 10px #FFFFFF40 /* Inner shadow */
    `,
                        "&:hover": {
                          background: "#2E5DBA",
                        },
                      }}
                      onClick={handleProfile}
                    >
                      Profile
                    </Button>

                    <Button
                      sx={{
                        color: "#3F78E0",
                        border: "solid 2px #3F78E0",
                        width: "150px",
                        borderRadius: "8px",
                        fontSize: "15px",
                        lineHeight: "20px",
                        fontWeight: "500",
                        boxShadow: `
      -2px 4px 4px #00000040, /* Drop shadow */
      inset 0px 5px 10px #FFFFFF40 /* Inner shadow */
    `,
                      }}
                      onClick={onLogout}
                    >
                      LOG OUT
                      <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: "10px" }}>
                        <path d="M12.5353 13.8888V15.5102C12.5353 15.9402 12.3644 16.3527 12.0603 16.6567C11.7563 16.9608 11.3439 17.1316 10.9138 17.1316H4.42808C3.99805 17.1316 3.58563 16.9608 3.28155 16.6567C2.97747 16.3527 2.80664 15.9402 2.80664 15.5102V5.78159C2.80664 5.35156 2.97747 4.93914 3.28155 4.63506C3.58563 4.33099 3.99805 4.16016 4.42808 4.16016H10.5895C11.485 4.16016 12.5353 4.88615 12.5353 5.78159V7.40303" stroke="#3F78E0" stroke-width="1.29932" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.1279 13.89L18.3713 10.6467L15.1279 7.40332" stroke="#3F78E0" stroke-width="1.29932" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.3457 10.6475H17.7229" stroke="#3F78E0" stroke-width="1.29932" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                    </Button>

                  </Box>
                </>
              )}
            </Box>
          ) : (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "white",
                color: "#3F78E0",
                "&:hover": { backgroundColor: "#f0f0f0" },
                border: "2px solid #3F78E0",
                marginTop: "30px",

              }}
              onClick={() => navigate("/login")}
            >
              Sign In
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "flex-end", marginBottom: "20px" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontWeight: "500", color: "black", fontSize: "14px" }}>
            info@booksmm.com
          </Typography>
          <Typography sx={{ fontWeight: "500", color: "black", fontSize: "14px" }}>
            0013439874565
          </Typography>
        </Box>
      </Box>
    </Box >
  );
};

export default MenuItems;
