import {
  Autocomplete,
  Checkbox,
  FormControl,
  TextField,
  Box,
  Button,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import ArrowImg from '../../../../Assets/images/icons/arrow-down.png'

const CustomArrowSVG = () => (
  <img
    src={ArrowImg} // Replace with your custom arrow image path
    alt="arrow"
    style={{ width: "14px", height: "14px", transform: "rotate(0deg)" }}
  />
);

const CountryFilter = ({ sortedOptions, handleCountryChange }) => {
  const [open, setOpen] = useState(false); // Track whether the dropdown is open
  const [currentPage, setCurrentPage] = useState(0); // Pagination state
  const containerRef = useRef(null); // Ref for the component
  const itemsPerPage = 20; // Items per page


  // Calculate paginated items
  const paginatedItems = sortedOptions.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleNextPage = (e) => {
    e.preventDefault();
    if ((currentPage + 1) * itemsPerPage < sortedOptions.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = (e) => {
    e.preventDefault();
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FormControl
      fullWidth
      variant="outlined"
      //   ref={containerRef}
      sx={{
        boxShadow: "1px 2px 6px #45404060",
        borderRadius: "8px",
        background: "#F5F8FE",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "0px solid black", // Default border color
          },
          "&:hover fieldset": {
            borderColor: "#6B9DFE",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#607d8b", // Border color on focus
            borderWidth: "2px", // Increase border width for better visibility
          },
        },
      }}
    >
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={paginatedItems} // Use paginated items
        getOptionLabel={(option) => option.label}
        onChange={handleCountryChange}
        open={open}
        popupIcon={<CustomArrowSVG />}
        onOpen={() => setOpen(true)}
        renderTags={(selectedOptions) =>
          selectedOptions.length === 1
            ? selectedOptions[0].label
            : `${selectedOptions.length} selected`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Countries"
            variant="outlined"
            onClick={() => setOpen(!open)} // Toggle dropdown on click
            InputLabelProps={{
              sx: {
                color: "#474747",
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Instrument_Sans",
                lineHeight: "20px",
                "&.Mui-focused": {
                  color: "black",
                },
              },
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            key={option.id}
            style={{
              padding: "5px",
              color: "#333",
              fontSize: "14px",
              borderBottom: "1px solid #9E9E9E",
            }}
          >
            <Checkbox
              checked={selected}
              sx={{
                color: "#007BFF",
                "&.Mui-checked": {
                  color: "#007BFF",
                },
              }}
            />
            {option.label}
          </li>
        )}
        ListboxProps={{
          sx: {
            maxHeight: "240px",
            backgroundColor: "#f5f8fe",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#F5F8FE",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#004571",
              borderRadius: "4px",
            },
          },
        }}
        PaperComponent={(props) => (
          <Box {...props} ref={containerRef}>
            {props.children}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px",
                color: "#333",
                fontSize: "14px",
                borderTop: "1px solid #9E9E9E",
                background: "#F5F8FE",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                disabled={currentPage === 0}
                onClick={handlePreviousPage}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                size="small"
                disabled={
                  (currentPage + 1) * itemsPerPage >= sortedOptions.length
                }
                onClick={handleNextPage}
              >
                Next
              </Button>
            </Box>
          </Box>
        )}
        sx={{
          "& .MuiAutocomplete-popupIndicator": {
            color: "#707275",
          },
          "& .MuiAutocomplete-clearIndicator": {
            color: "#707275",
          },
          "& .MuiOutlinedInput-root": {
            padding: "10px 12px",
            height: "48px",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px"

          },
        }}
      />
    </FormControl>
  );
};

export default CountryFilter;
