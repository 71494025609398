
import React from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
const SearchLog = () => {

    return (
        <>
            <Box>
                <Typography sx={{ color: "#474747", fontWeight: "600", fontSize: "24px", lineHeight: "30px" }}>
                    Your Search History
                </Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: "none",
                        borderBottom: "1px solid #00000080",

                        marginTop: "20px",
                    }}
                >
                    <Table>
                        <TableHead sx={{ borderBottom: "1px solid #00000080" }}>
                            <TableRow sx={{ backgroundColor: "#D1DDE4" }}>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Date
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Keyword
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Catagory
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Subcatagory
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Panel
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Country
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Popular
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Price
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >
                                    Features
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#333333",
                                        textAlign: "center",
                                        borderBottom: "1px solid #00000080",
                                    }}
                                >

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[
                                {
                                    id: 1,
                                    date: "2024-12-14",
                                    type: "Order",
                                    panel: "Main",
                                    title: "Sample Title 1",
                                    description: "Description for Row 1",
                                    status: "Completed",
                                    price: "$100",
                                    Features: "Refill",

                                },
                                {
                                    id: 2,
                                    date: "2024-12-13",
                                    type: "Service",
                                    panel: "Sub",
                                    title: "Sample Title 2",
                                    description: "Description for Row 2",
                                    status: "Pending",
                                    price: "$100",
                                    Features: "Refill",
                                },
                            ].map((row, index) => (
                                <TableRow key={index} sx={{ backgroundColor: "#E5EDFF" }}>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.id}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.date}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.type}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.panel}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.title}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.description}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.status}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.price}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >
                                        {row.Features}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                            color: "#333333",
                                            borderBottom: "1px solid #00000080",
                                        }}
                                    >


                                        <Button
                                            sx={{
                                                backgroundColor: "#3F78E0", color: "#fff",
                                                boxShadow: `
      0px 3px 3px #00000040, 
      inset 0px 4px 7px #FFFFFF40
    `,
                                                borderRadius: "10px",
                                            }}
                                            // onClick={() => console.log("ReOrder clicked for row", row.id)}
                                        >
                                            Research
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Box>

        </>
    )
}
export default SearchLog;