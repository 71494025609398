import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { Styles } from "./styles";
import LoaderImg from "../../../Assets/images/icons/loader.svg";

const MainLoader = ({ isFull }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: isFull ? "calc(100dvh - 92px)" : "calc(100dvh - 400px)",
      }}
    >
      <Styles>
        <img src={LoaderImg} alt="Loader" />
      </Styles>
    </Box>
  );
};

export default MainLoader;
