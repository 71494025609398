import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import DashboardIcon from "../../Assets/Dashbaord/DashbaordIcons/Dashbaord.svg";
import OrderHistoryIcon from "../../Assets/Dashbaord/DashbaordIcons/OrderHistory.svg";
import servicesIcon from "../../Assets/Dashbaord/DashbaordIcons/Services.svg";
import TicketsIcon from "../../Assets/Dashbaord/DashbaordIcons/Tickets.svg";
import { ReactComponent as LeftArrow } from "../../Assets/Dashbaord/Sidebar/LeftArrow.svg";
import { ReactComponent as RightArrow } from "../../Assets/Dashbaord/Sidebar/RightArrow.svg";
import MyPanel from "../../Assets/Dashbaord/DashbaordIcons/MyPanel.svg"

const Style = styled.aside`
  position: ${(props) =>
    props.isOpen && props.isSmallScreenIndicator ? "absolute" : "absolute"};
  width: ${(props) =>
    props.isOpen ? (props.isSmallScreenIndicator ? "250px" : "250px") : "0px"};
  height: 100ch; 
 
`;

const SidebarContainer = styled.aside`
  position: ${(props) =>
    props.isOpen && props.isSmallScreenIndicator ? "absolute" : "absolute"};
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-right: ${(props) => (props.isOpen ? "2px solid #3F78E0" : "none")};
  height: 100%;
  transition: width 0.3s ease-in-out;
  z-index: ${(props) =>
    props.isOpen && props.isSmallScreenIndicator ? "2" : "0"};
  width: ${(props) =>
    props.isOpen ? (props.isSmallScreenIndicator ? "100%" : "250px") : "0px"};
  box-shadow: ${(props) => (props.isOpen ? "1px 5px 5px #00000040" : "none")};
  overflow: hidden;
`;

const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(props) => (props.isSmallScreenIndicator ? "fixed" : "absolute")};
  top: 100px;
  left: ${(props) =>
    props.isSmallScreenIndicator
      ? props.isOpen
        ? "100%"
        : "-3px"
      : props.isOpen
        ? "85%"
        : "-3px"};
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1100;
  border-radius: 10px;
  border-left: 2px solid #3f78e0;
  border-top: 2px solid #3f78e0;
  border-bottom: 2px solid #3f78e0;
  border-top-left-radius: ${(props) => (props.isOpen ? "10px" : "0")};
  border-bottom-left-radius: ${(props) => (props.isOpen ? "10px" : "0")};
  border-top-right-radius: ${(props) => (props.isOpen ? "0" : "10px")};
  border-bottom-right-radius: ${(props) => (props.isOpen ? "0" : "10px")};
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease, position 0.3s ease; /* Smooth transition */
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  font-size: 16px;
  color: ${(props) => (props.isActive ? "#fff" : "#555")};
  background-color: ${(props) => (props.isActive ? "#3F78E0" : "transparent")};
  cursor: pointer;

  img {
    width: 28px;
    height: 23px;
    object-fit: contain;
    margin-inline-end: 10px;
    ${(props) =>
    props.isActive ? `filter: contrast(0%) brightness(500%);` : ``}
  }

  &:hover {
    background-color: ${(props) => (props.isActive ? "#3F78E0" : "#f0f0f0")};
  }
`;

const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation();

  const getMenuItemClass = (path) => location.pathname.startsWith(path);
  const toggleSidebar = () => setIsOpen((prev) => !prev);

  const [isSmallScreenIndicator, setIsSmallScreenIndicator] = useState(
    window.innerWidth <= 1300
  );

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth <= 1300;
      isSmallScreen && isOpen && setIsOpen(false)
      setIsSmallScreenIndicator(isSmallScreen);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Style isOpen={isOpen} isSmallScreenIndicator={isSmallScreenIndicator}>
      {/* Sidebar Container */}
      <SidebarContainer
        isOpen={isOpen}
        isSmallScreenIndicator={isSmallScreenIndicator}
      >
        <MenuList>
          <Link
            to="/dashboard/overview"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem isActive={getMenuItemClass("/dashboard/overview")}>
              <img src={DashboardIcon} alt="dashboard" />
              {isOpen && "Dashboard"}
            </MenuItem>
          </Link>
          <Link
            to="/dashboard/mypanel"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem isActive={getMenuItemClass("/dashboard/mypanel")}>
              <img src={MyPanel} alt="dashboard" />
              {isOpen && "MyPanel"}
            </MenuItem>
          </Link>
          <Link
            to="/dashboard/order-history"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem isActive={getMenuItemClass("/dashboard/order-history")}>
              <img src={OrderHistoryIcon} alt="Order History" />
              {isOpen && "Order History"}
            </MenuItem>
          </Link>
          <Link
            to="/dashboard/services"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem isActive={getMenuItemClass("/dashboard/services")}>
              <img src={servicesIcon} alt="Services" />
              {isOpen && "Services"}
            </MenuItem>
          </Link>
          <Link
            to="/dashboard/tickets"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem isActive={getMenuItemClass("/dashboard/tickets")}>
              <img src={TicketsIcon} alt="Tickets" />
              {isOpen && "Tickets"}
            </MenuItem>
          </Link>
        </MenuList>
      </SidebarContainer>

      {/* Toggle Button */}
      <ToggleButton onClick={toggleSidebar} isOpen={isOpen}>
        {isOpen ? (
          <LeftArrow style={{ width: "16px", height: "24px" }} />
        ) : (
          <RightArrow style={{ width: "16px", height: "24px" }} />
        )}
      </ToggleButton>
    </Style>
  );
};

export default Sidebar;
