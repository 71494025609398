import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import { Link, useParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MainStatisticsCard from "./statistic-card/MainStatisticsCard";
import ActionsStatisticsCard from "./statistic-card/ActionsStatisticsCard";
import DevicesStatisticsCard from "./statistic-card/DevicesStatisticsCard";
import VisitorsStatisticsCard from "./statistic-card/VisitorsStatisticsCard";
import ViewsStatisticsCard from "./statistic-card/ViewsStatisticsCard";
import { Helper } from "../../../../tools/Helper";
import { api_Routes } from "../../../../api_Routes";
import MainLoader from "../../../../components/common/main-loader";

const ServiceStatistics = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    setIsLoading(true);
    const response = await Helper.Get({
      url: api_Routes.dashboard.orderhistory.Statistics + id,
      hasToken: true,
    });
    if (response) setData(response?.response?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return isLoading ? (
    <MainLoader isFull={true} />
  ) : data ? (
    <Styles>
      <div className="title">
        <Link to="/dashboard/order-history">Order history</Link>
        <span>
          <ArrowForwardIosIcon sx={{ height: "20px" }} />
        </span>
        <span>Service Statistics</span>
      </div>
      <div className="service-name">Service #{id}</div>
      <div className="cards">
        {/* <MainStatisticsCard />
        <ActionsStatisticsCard /> */}
        <DevicesStatisticsCard data={data?.statisticsUsedDevices} />
        <VisitorsStatisticsCard data={data?.countryStatistics} />
      </div>
      <div className="chart-card">
        <ViewsStatisticsCard
          chartData={data?.dateAndTotalviewsOrClicks}
          overAllData={data?.overall_statistics}
        />
      </div>
    </Styles>
  ) : (
    <Styles>
      <div className="title">
        <Link to="/dashboard/order-history">Order history</Link>
        <span>
          <ArrowForwardIosIcon sx={{ height: "20px" }} />
        </span>
        <span>Service Statistics</span>
      </div>
      <div className="title">There are no data yet</div>
    </Styles>
  );
};

export default ServiceStatistics;
