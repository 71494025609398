import React from "react";
import { Styles } from "./styles";
import Progress from "./progress";

const DevicesStatisticsCard = ({ data }) => {
  const formatPercentage = (value) => {
    return value !== undefined ? `${parseFloat(value).toFixed(1)}%` : "0.00%";
  };

  return (
    <Styles>
      <div className="card-item">
        <div className="title">Used Devices</div>
      </div>
      <div className="card-item">
        <Progress
          title={"Desktop"}
          percentage={formatPercentage(data?.percentage_computer)}
        />
        <Progress
          title={"Mobile"}
          percentage={formatPercentage(data?.percentage_phone)}
        />
        <Progress
          title={"Other"}
          percentage={formatPercentage(data?.percentage_other)}
        />
      </div>
    </Styles>
  );
};

export default DevicesStatisticsCard;
