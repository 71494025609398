import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import AccordionItem from "./Accrordion-item/index";
import { api_Routes } from "../../../api_Routes";
import { Helper } from "../../../tools/Helper";
import MainLoader from "../../common/main-loader/index"

const FAQ = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [views, setViews] = useState([]);

  const get_Faq = () => {
    Helper.Get({
      url: api_Routes.FAQ.view,
      hasToken: true,
      data: {
        results: 190,
      },
    }).then(({ response, message }) => {
      if (response?.data) {
        setViews(response.data.data);
      } else {
        console.error("Failed to fetch FAQ data:", message);
      }
      setIsLoading(false); // Stop loading
    });
  };

  useEffect(() => {
    get_Faq();
  }, []);

  return (
    <Styles>
      <div className="title">FAQ</div>
      {isLoading ? (
        <MainLoader />
      ) : views.length > 0 ? (
        views.map((item) => (
          <AccordionItem
            key={item.id}
            title={item.title}
            data={item.description}
          />
        ))
      ) : (
        <div>No FAQs available at the moment.</div>
      )}
    </Styles>
  );
};

export default FAQ;
