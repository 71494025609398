import React from "react";
import { Styles } from "./styles";
import SpecialOfferImage from "../../../../Assets/images/dashboard/specialOffer.svg";
import ServiceCard from "./service-card";
import { useMediaQuery } from "@mui/material";

const SpecialOffer = ({ data }) => {
  const isTablet = useMediaQuery("(max-width:1300px)");
  return (
    <Styles isTablet={isTablet}>
      {data?.map((offer) => (
        <div className="main-container">
          <div className="title-container">
            <div className="title">
              <img src={SpecialOfferImage} alt="Special Offer" />
              Special Offer
            </div>
            <div className="price">
              {offer.price}$

            </div>
          </div>
          <div className="panel-url">
            <div className="description url">
              <span className="name">Panel </span>

              <a href={offer.panel_url} target="_blank">
                {offer.panel_url}
              </a>
            </div>
          </div>
          <div className="services">
            {offer.services.map((service) => (
              <ServiceCard key={service.id} data={service} />
            ))}
          </div>
        </div>
      ))}
    </Styles>
  );
};

export default SpecialOffer;
