import React from "react";
import CustomModal from "../../../../../../components/common/custom-modal/index";
import { Styles } from "./styles";
import { Grid } from "@mui/material";
const ViewServiceModal = ({ open, setOpen, data }) => {
  return (
    <div>
      <CustomModal
        open={open}
        setOpen={setOpen}
        title={"Service Details"}
        description={
          "Here are all the Details you want to know about the service"
        }
        data={
          <Styles>
            <Grid
              container
              style={{ justifyContent: "space-between" }}
              spacing={1}
            >
              <Grid item xs={5}>
                <span className="name">ID</span>{" "}
                <span className="data">{data?.id}</span>
              </Grid>
              <Grid item xs={5}>
                <span className="name">Category</span>{" "}
                <span className="data">{data?.category}</span>
              </Grid>
              {data?.rate_per_1000 && (
                <Grid item xs={5}>
                  <span className="name">Rate</span>{" "}
                  <span className="data">{data?.rate_per_1000}</span>
                </Grid>
              )}
              <Grid item xs={5}>
                <span className="name">Min & Max</span>{" "}
                <span className="data">{data?.order_range}</span>
              </Grid>
              {data?.panelUrl && (
                <Grid item xs={5}>
                  <span className="name">Panel</span>{" "}
                  <span className="data">
                    <a href={data?.panelUrl} target="_blank">
                      {data?.panelUrl}
                    </a>
                  </span>
                </Grid>
              )}
              <Grid item xs={5}>
                <span className="name">Service</span>{" "}
                <span className="data">{data?.name}</span>
              </Grid>
              <div></div>
            </Grid>
          </Styles>
        }
      />
    </div>
  );
};

export default ViewServiceModal;
