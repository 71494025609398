import { styled, css, keyframes } from "@mui/system";

export const Styles = styled("div")(
  ({ theme, dataCount }) => css`
    margin-top: 40px;
    .contain {
      justify-content: ${dataCount === 1 ? "flex-start" : "center"};
      display: grid;
      grid-template-columns: 50% 50%;
      padding: 15px;
      column-gap: 20px;
      padding-bottom: 20px;
      @media (max-width: 768px) {
        grid-template-columns: 100%;
      }
      .it {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  `
);
