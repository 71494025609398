import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    background: #e5edff;
    padding: 20px;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    box-shadow: -4px 4px 4px #00000038;
    .card-item {
      .title {
        color: #000;
        font-weight: 600;
        font-size: 22px;
      }
      .description {
        color: #303030;
        font-weight: 500;
        font-size: 14px;
        a {
          text-decoration: none;
        }
      }
      &:last-child {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .sub-item {
      &.multi {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .title {
        color: #303030;
        font-weight: 600;
        font-size: 14px;
      }
      .description {
        color: #303030;
        font-weight: 500;
        font-size: 12px;
      }
    }
    .main-data {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 20px;
      border-radius: 10px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        @media (max-width: 992px) {
          flex-direction: column;
        }
      }

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #1f2937;
      }

      .stats {
        display: flex;
        gap: 16px;
        align-items: center;
      }

      .stat {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        color: #374151;
        .number {
          font-size: 18px;
        }
      }

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      .dot.blue {
        background-color: #3b82f6;
      }

      .dot.orange {
        background-color: #f59e0b;
      }

      .chart {
        background: #f5f7fc;
        border-radius: 10px;
        padding: 20px;
      }
    }
  `
);
