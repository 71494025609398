import React from "react";
import { Styles } from "./styles";

const Progress = ({ title, percentage, key }) => {
  return (
    <Styles progressPercent={percentage} key={key}>
      <div className="progress-title">{title}</div>
      <div className="progress-bar"></div>
      <div className="progress-percent">{percentage}</div>
    </Styles>
  );
};

export default Progress;
