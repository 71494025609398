import { React, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/Svg/main-logo.svg";
import { useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";
import { useAuth } from "../../AuthContext/AuthContext";
import { useState } from "react";
import { IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { api_Routes } from "../../api_Routes";
import { Helper, isLogin } from "../../tools/Helper";
import UserProfilePopup from "./ProfilePopUp";
import NotificationsList from "./NotificationsList";
import MenuItems from "./MenuItems";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "../../store/slices/profileSlice";
import { Styles } from "./styles";
import DashboardIcon from "./DashbaordIcon";
import DashbaordIconLayout from "./DashbaordIconLayout";
import DefaultUserPhoto from "../../Assets/userphoto.png"

const Header = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [showProfile, setShowProfile] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const profileRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleLogout = async () => {
    setShowProfile(false);
    dispatch(setProfileData(null));
    logout();
  };
  const handleProfile = () => {
    setShowProfile(false);
    navigate("/profile");
  };

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };
  const handleDashboardClick = () => {
    navigate("/dashboard/overview");
  };
  const handleMenuItemClick = () => {
    setDrawerOpen(false); // Close the drawer
  };

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setShowProfile(false);
    }
  };
  const get_notifications = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.Header.getMyNotifications,
      hasToken: true,
      data: { results: 100 },
    });
    if (response) {
      setNotifications([]);
      setNotifications(
        response.data.map((not) => ({
          id: not.id,
          message: not.message,
          sent_at: not.sent_at,
        }))
      );
    } else {
    }
  };
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    if (!showNotifications) {
      get_notifications();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsSmallScreen(window.innerWidth < 600);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);

      if (drawerOpen && window.innerWidth >= 600) {
        setDrawerOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawerOpen]);


  return (
    <Styles>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          borderRadius: "0 0 16px 16px",
          boxShadow: "0px 4px 5px #00000040",
          border: "2px solid #3F78E0",
        }}
        className="main-container"
      >
        <IconButton
          onClick={toggleDrawer(true)}
          sx={{
            display: { xs: "block", md: "none" },
            fontSize: "0",
            marginBlock: "auto",
            width: "90px",
          }}
        >
          <MenuIcon fontSize="large" style={{ marginTop: "auto" }} />
        </IconButton>

        <Box
          display="flex"
          borderRadius="3px"
          sx={{
            width: "calc(100% - 150px)",
            alignItems: "center",
            gap: { lg: "60px", md: "20px" },
          }}
        >
          <Link

            to="/home"
            style={{
              cursor: "pointer",
              height: "56px",
            }}
            className="logo"
          >
            <img
              src={Logo}
              alt="Logo"
              className="smallscreenlogo"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Link>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: isSmallScreen
                ? "calc(100% - 120px)"
                : "calc(100% - 160px)",
              height: "fit-content",
              gap: "40px",
              justifyContent: "flex-start",
            }}
            className="links-container"
          >
            <NavLink to="/" className="item" activeClassName="active">
              <span>Service Finder</span>
            </NavLink>
            <NavLink to="/Providers" className="item" activeClassName="active">
              <span>Providers</span>
            </NavLink>
            <NavLink
              to="/OurServices"
              className="item"
              activeClassName="active"
            >
              <span>Our services</span>
            </NavLink>
            <NavLink to="/OurOffers" className="item" activeClassName="active">
              <span>Our offers</span>
            </NavLink>
            <NavLink to="/AddPanel" className="item" activeClassName="active">
              <span>Add Panel</span>
            </NavLink>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {!isSmallScreen ? (
            isLogin ? (
              <Box
                position="relative"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",

                  gap: "30px",
                }}
              >
                <NotificationsList
                  showNotifications={showNotifications}
                  toggleNotifications={toggleNotifications}
                  notifications={notifications}
                  handleClickAway={() => setShowNotifications(false)}
                />

                <img
                  src={profileData?.photo || DefaultUserPhoto}
                  alt="User"
                  style={{
                    border: "1px solid #296D99",
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: "40px",
                    height: "40px",
                  }}
                  onClick={toggleProfile}
                />
                {showProfile && (
                  <UserProfilePopup
                    userPhoto={profileData?.photo}
                    userName={profileData?.name}
                    userEmail={profileData?.email}
                    onLogout={handleLogout}
                    onProfileClick={handleProfile}
                    profileRef={profileRef}
                    DefaultUserPhoto={DefaultUserPhoto}
                  />
                )}
                <Box>
                  <DashbaordIconLayout
                    onClick={handleDashboardClick}
                    ariaLabel="Open Dashboard"
                    showText={true}

                  >
                    <DashboardIcon />
                  </DashbaordIconLayout>

                </Box>
              </Box>
            ) : (
              <>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: "#3F78E0",
                      borderTopLeftRadius: {
                        xs: isSmallScreen ? "12px" : 0,
                        sm: "12px",
                      },
                      borderBottomLeftRadius: {
                        xs: isSmallScreen ? "12px" : 0,
                        sm: "12px",
                      },
                      borderTopRightRadius: { xs: "12px", sm: 0 },
                      borderBottomRightRadius: { xs: "12px", sm: 0 },
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                      border: "2px solid #3F78E0",
                      display: "flex",
                      gap: "10px",
                      padding: "10px 12px",
                      boxShadow: "0px 4px 4px #00000040",
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "18px",
                        color: "#3F78E0",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Sign In
                    </Typography>
                    <svg
                      className="sign-in-arrow"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2441 2.8457H14.0653C14.4394 2.8457 14.7982 2.99432 15.0627 3.25885C15.3273 3.52339 15.4759 3.88218 15.4759 4.25629V14.1304C15.4759 14.5045 15.3273 14.8633 15.0627 15.1278C14.7982 15.3924 14.4394 15.541 14.0653 15.541H11.2441"
                        stroke="#3F78E0"
                        strokeWidth="1.41059"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.7168 12.7199L11.2433 9.19346L7.7168 5.66699"
                        stroke="#3F78E0"
                        strokeWidth="1.41059"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.2428 9.19336H2.7793"
                        stroke="#3F78E0"
                        strokeWidth="1.41059"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </Box>
                {!isSmallScreen && ( // Only show Sign Up button if not small screen
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#3F78E0",
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: "12px",
                        "&:hover": {
                          backgroundColor: "#355dab",
                        },
                        border: "2px solid #3F78E0",
                        display: "flex",
                        gap: "10px",
                        padding: "10px 12px",
                        boxShadow: "0px 4px 4px #00000040", // Apply shadow with y-offset and blur
                      }}
                      onClick={() => {
                        navigate("/signup");
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "18px",
                          color: "#F1F5FD",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Sign Up
                      </Typography>
                      <svg
                        className="sign-up-arrow"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.42708 4.54075L5.42708 4.54153C5.428 5.29936 5.74855 6.01748 6.3045 6.54085C6.85915 7.06299 7.60334 7.3505 8.37209 7.35133H8.37278C8.94708 7.35133 9.51136 7.19118 9.99453 6.88726C10.478 6.58313 10.861 6.14691 11.0888 5.62915L10.5028 5.37133L11.0888 5.62915C11.3168 5.11085 11.3772 4.53804 11.2604 3.98498C11.1436 3.43227 10.8564 2.9302 10.4418 2.53992L10.003 3.00604L10.4418 2.53992C10.0277 2.15002 9.50453 1.88845 8.9404 1.78281C8.37638 1.6772 7.79138 1.73111 7.25792 1.93912C6.72424 2.14722 6.26195 2.50202 5.93376 2.9644C5.60519 3.42732 5.42708 3.97595 5.42708 4.54075ZM14.4248 15.6091H15.065V14.9689V14.2088C15.065 10.5315 12.0604 7.54619 8.37278 7.54619C4.68504 7.54619 1.68058 10.5315 1.68058 14.2088V14.9689V15.6091H2.32074H14.4248Z"
                          fill="#3F78E0"
                          stroke="white"
                          strokeWidth="1.28034"
                        />
                      </svg>
                    </Button>
                  </Box>
                )}
              </>
            )
          ) : (
            isLogin ? (
              <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                <NotificationsList
                  showNotifications={showNotifications}
                  toggleNotifications={toggleNotifications}
                  notifications={notifications}
                  handleClickAway={() => setShowNotifications(false)}
                />
                <DashbaordIconLayout
                  onClick={handleDashboardClick}
                  ariaLabel="Open Dashboard"

                >

                  <DashboardIcon />
                </DashbaordIconLayout>

              </Box>
            ) : (
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#3F78E0",
                    color: "#F1F5FD",
                    borderRadius: "12px",
                    "&:hover": { backgroundColor: "#355dab" },
                    border: "2px solid #3F78E0",
                    display: "flex",
                    gap: "10px",
                    padding: "10px 12px",
                    boxShadow: "0px 4px 4px #00000040",
                  }}
                  onClick={() => navigate("/signup")}
                >
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#F1F5FD",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Sign Up
                  </Typography>
                </Button>
              </Box>
            )
          )}

        </Box>
      </Box>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <MenuItems
          isMobile={isMobile}
          isSmallScreen={isSmallScreen}
          navigate={navigate}
          showNotifications={showNotifications}
          toggleNotifications={toggleNotifications}
          notifications={notifications}
          showProfile={showProfile}
          toggleProfile={toggleProfile}
          handleLogout={handleLogout}
          handleProfile={handleProfile}
          profileRef={profileRef}
          setShowNotifications={setShowNotifications}
          userphoto={profileData?.photo}
          name={profileData?.name}
          handleDashboardClick={handleDashboardClick}
          onLogout={handleLogout}
          DefaultUserPhoto={DefaultUserPhoto}
          onCloseDrawer={handleMenuItemClick}
        />
      </Drawer>
    </Styles>
  );
};
export default Header;
