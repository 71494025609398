import Home from "../components/Home/Home.jsx";
import AddPanel from "../components/AddPanel/AddPanel.jsx";
import Providers from "../components/Providers/Providers.jsx";
import PanelsDetails from "../components/Providers/PanelsDetails.jsx";
import OurServices from "../components/OurServices/OurServices.jsx";
import ServicesInvoice from "../components/OurServices/SerivcesInvoice.jsx";
import Login from "../Auth/Login.jsx";
import Signup from "../Auth/Signup.jsx";
import VerifyAccount from "../Auth/VerifyAccount.jsx";
import ForgetPassswordByEmail from "../Auth/ForgetPasswordByEmail.jsx";
import VerficationPasswordByEmail from "../Auth/VerficationPasswordByEmail.jsx";
import ResetPassword from "../Auth/ResetPassword.jsx";
import OurOffers from "../components/Offers/OurOffers.jsx";
import OffersDetails from "../components/Offers/OffersDetails.jsx";
import OffersInvoice from "../components/Offers/OffersInvoice.jsx";
import ProtectedRoute from "../Routes/ProtectedRoutes";
import GuestRoute from "./GuestRoute.js";
import UserApp from "../UserDashbaord/UserApp.js";
import Dashboard from "../UserDashbaord/Pages/Dashboard/Dashbaord.jsx";
import OrderHistory from "../UserDashbaord/Pages/OrderHistory/OrderHistory.jsx";
import Tickets from "../UserDashbaord/Pages/Tickets/Tickets.jsx";
import Services from "../UserDashbaord/Pages/Services/Services.jsx";
import Profile from "../components/Profile/Profile.jsx";
import SearchLog from "../UserDashbaord/Pages/SearchLog/SearchLog.jsx";
import ChangePassword from "../components/Profile/change-password/index.jsx";
import ChangePasswordVerifyCode from "../components/Profile/change-password-verify-code/index.jsx";
import TicketsChat from "../UserDashbaord/Pages/TicketsChat/index.jsx";
import ServiceStatistics from "../UserDashbaord/Pages/OrderHistory/service-statistics/index.jsx";
import MyPanel from "../UserDashbaord/Pages/MyPanel/MyPanel.jsx"
import EditPanel from "../UserDashbaord/Pages/MyPanel/Edit/EditPanel.jsx";
import FAQ from "../components/Home/faq/index.jsx"
import AboutUs from "../components/Home/AboutUs/index.jsx";
import PrivacyPolicy from "../components/Home/PrivacyPolicy/PrivacyPolicy.jsx"
import TermsOfUse from "../components/Home/TermsOfUse.jsx/TermsOfUse.jsx";
function createRoute(path, element, isProtected = false, isGuest = false) {
  const Component = isProtected ? (
    <ProtectedRoute>{element}</ProtectedRoute>
  ) : isGuest ? (
    <GuestRoute>{element}</GuestRoute>
  ) : (
    element
  );

  return { path, element: Component, protected: isProtected };
}

const routes = [
  createRoute("/", <Home />, false),
  createRoute("/Home", <Home />, false),
  createRoute("/FAQ", <FAQ />, false),
  createRoute("/AddPanel", <AddPanel />, false),
  createRoute("/Providers", <Providers />, false),
  createRoute("/PanelsDetails/:paneluuid", <PanelsDetails />, false),
  createRoute("/OurServices", <OurServices />, false),
  createRoute("/OurServices/:id/:typeId", <OurServices />, false),
  createRoute("/ServicesInvoice", <ServicesInvoice />, true),
  createRoute("/login", <Login />, false, true),
  createRoute("/signup", <Signup />, false, true),
  createRoute("/VerifyAccount", <VerifyAccount />, false, true),
  createRoute("/About", <AboutUs />, false),
  createRoute("/PrivacyPolicy", <PrivacyPolicy />, false),
  createRoute("/TermsOfUse", <TermsOfUse />, false),
  createRoute(
    "/ForgetPassswordByEmail",
    <ForgetPassswordByEmail />,
    false,
    true
  ),
  createRoute(
    "/VerficationPasswordByEmail",
    <VerficationPasswordByEmail />,
    false,
    true
  ),
  createRoute("/ResetPassword", <ResetPassword />, false),
  createRoute("/OurOffers", <OurOffers />, false),
  createRoute("/OffersDetails/:offer_id", <OffersDetails />, false),
  createRoute("/OffersInvoice", <OffersInvoice />, true),
  createRoute("/profile", <Profile />, true),
  createRoute("/profile/change-password", <ChangePassword />, true),
  createRoute(
    "/profile/change-password/verify",
    <ChangePasswordVerifyCode />,
    true
  ),
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <UserApp />
      </ProtectedRoute>
    ),
    children: [
      { path: "overview", element: <Dashboard /> },
      { path: "order-history", element: <OrderHistory /> },
      { path: "mypanel", element: <MyPanel /> },
      { path: "mypanel/edit", element: <EditPanel /> },
      {
        path: "order-history/service-statistic/:id",
        element: <ServiceStatistics />,
      },
      { path: "tickets", element: <Tickets /> },
      { path: "tickets/:id", element: <TicketsChat /> },
      { path: "services", element: <Services /> },
      { path: "searchlog", element: <SearchLog /> },
    ],
  },
];

export default routes;
