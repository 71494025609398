import { Box, Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import SubmitOfferCard from "./submit-offer-item";

const SubmitOfferForm = ({

  handleloading,
  handleChange,
  handleButtonClick,
  formData,
  offer,
}) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const validateUrl = (value) => {
    if (value.trim() === "") {
      // Reset error state if the input is empty
      setError(false);
      setHelperText("");
      return;
    }

    const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]{2,}\.[a-z]{2,}(\.[a-z]{2,})?$/;
    if (!urlPattern.test(value)) {
      setError(true);
      setHelperText("Please enter a valid URL (e.g., https://example.com)");
    } else {
      setError(false);
      setHelperText("");
    }
  };
  return (
    <form onSubmit={handleButtonClick}>
      <Box sx={{ marginTop: "40px" }}>
        <TextField
          fullWidth
          onChange={(e) => {
            const url = e.target.value;
            handleChange(undefined, "panel_url", url);
            if (error || url.trim() === "") validateUrl(url); // Live validation
          }}
          onBlur={(e) => validateUrl(e.target.value)} // Validation on blur
          required
          variant="outlined"
          label="Enter the panel URL link"
          placeholder="Enter the panel URL link (e.g., https://example.com)"
          error={error} // Highlight the field in red if there's an error
          helperText={helperText} // Display validation feedback
          sx={{
            marginBlock: "20px",
            background: "#FFF",
            borderRadius: 2,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        />
        <Box>
          {offer?.offerDetails?.map((detail, index) => {
            return (
              <SubmitOfferCard
                index={index}
                detail={detail}
                formData={formData}
                handleChange={handleChange}
              />
            );
          })}
        </Box>

      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            background: "linear-gradient(135deg, #FF8A00, #ECA601)",
            color: "#FFF",
            padding: "10px 70px",
            fontSize: "18px",
            fontWeight: "bold",
            borderRadius: "15px",
            transition: "transform 0.2s",
            "&:hover": {
              background: "linear-gradient(135deg, #ECA601, #FF8A00)",
              transform: "translateY(-5px)",
            },
          }}
          type="submit"
        >
          {handleloading ? (
            <CircularProgress size={22} sx={{ color: "white" }} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </form>
  );
};

export default SubmitOfferForm;
