import React from "react";

const DashboardIconLayout = ({ children, onClick, ariaLabel, showText = false }) => {
    return (
        <button
            aria-label={ariaLabel}
            onClick={onClick}
            style={{
                textAlign: "center",
                borderRadius: "12px",
                padding: "8px 12px", 
                height: "40px",
                background: "linear-gradient(135deg, #3F78E0, #2E5DBA)",
                color: "white",
                border: "1px solid #2E5DBA",
                boxShadow: `
                    -2px 2px 4px #00000040,
                    inset 0px 5px 10px #FFFFFF40
                `,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px", 
                transition: "all 0.3s ease",
                cursor: "pointer",
                fontSize: "14px",
                whiteSpace: "nowrap", 
            }}
            onMouseEnter={(e) =>
                (e.currentTarget.style.background = "linear-gradient(135deg, #2E5DBA, #1C438F)")
            }
            onMouseLeave={(e) =>
                (e.currentTarget.style.background = "linear-gradient(135deg, #3F78E0, #2E5DBA)")
            }
        >
            {children}
            {showText && <span>Dashboard</span>}
        </button>
    );
};

export default DashboardIconLayout;
