import { Box, Radio, Slider, TextField, Typography } from "@mui/material";
import React from "react";

const SubmitOfferCard = ({ index, detail, formData, handleChange }) => {
  const fonts = ["Mockads", "Escopedia", "Great Vibes"];
  const colors = ["#FFFFFF", "#303030", "#ECA601"];

  const defaultColor = "#FFFFFF";
  const defaultFontFamily = "Mockads";
  const defaultFontSize = 30;

  const handleFontChange = (index, font) => {
    handleChange(index, "selectedFont", font);
  };

  const handleColorClick = (index, color) => {
    handleChange(index, "selectedColor", color);
  };

  const handleFontSizeChange = (index, event, newValue) => {
    handleChange(index, "fontSize", newValue);
  };

  return (
    (detail.title || detail.description || detail.picture) && (
      <Box key={index} sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Detail {index + 1}: {detail.detail}
        </Typography>
        {detail.title && (
          <TextField
            fullWidth
            required
            label={`Title`}
            value={formData[`title_${index}`] || ""}
            onChange={(e) => handleChange(index, "title", e.target.value)}
            sx={{ mb: 2 }}
          />
        )}
        {detail.description && (
          <TextField
            fullWidth
            required
            label={`Description`}
            value={formData[`description_${index}`] || ""}
            onChange={(e) => handleChange(index, "description", e.target.value)}
            sx={{ mb: 2 }}
          />
        )}
        {detail.picture && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                opacity: 1,
                pointerEvents: "auto",
                background: "#FFFFFF",
                border: "1px solid #303030",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                fullWidth
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "column",
                    lg: "row",
                  },
                  justifyContent: {
                    xs: "space-between",
                    sm: "space-around",
                    md: "space-between",
                  },
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  padding: "20px 20px",
                }}
              >
                <Box
                  fullWidth
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography>Pick a Custom Font</Typography>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      value={formData[`logoName_${index}`] || ""}
                      onChange={(e) =>
                        handleChange(index, "logoName", e.target.value)
                      }
                      placeholder="Type Your Logo Name here"
                      size="small"
                      sx={{
                        width: "95%",
                        boxShadow: "-2px 2px 4px #00000040",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        marginTop: "10px",
                      }}
                    />
                  </Box>

                  {/* Font Selection */}
                  <Box>
                    <Typography>Choose a Font</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        marginTop: "4px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {fonts.map((font) => (
                        <Box
                          key={font}
                          onClick={() => handleFontChange(index, font)}
                          sx={{
                            background: "#E5EDFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "4px",
                            border: "1px solid #959595",
                            borderRadius: "10px",
                            padding: {
                              xs: "4px 6px",
                              sm: "4px 12px",
                              md: "4px 25px",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: font,
                              fontSize: "20px",
                              lineHeight: "24px",
                              fontWeight: "600",
                              color: "#474747",
                            }}
                          >
                            lorem
                          </Typography>
                          <Radio
                            checked={
                              formData[`selectedFont_${index}`]
                                ? formData[`selectedFont_${index}`] === font
                                : defaultFontFamily === font
                            }
                            onChange={() => handleFontChange(index, font)}
                            sx={{
                              color: "primary.main",
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  {/* Color and Font Size Selection */}
                  <Typography>Choose a color and font size</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: 2 }}>
                        {colors.map((color) => (
                          <Box
                            key={color}
                            onClick={() => handleColorClick(index, color)}
                            sx={{
                              width: 20,
                              height: 20,
                              borderRadius: "50%",
                              backgroundColor: color,
                              cursor: "pointer",
                              border: `1px ${
                                (
                                  formData[`selectedColor_${index}`]
                                    ? formData[`selectedColor_${index}`] ===
                                      color
                                    : defaultColor === color
                                )
                                  ? "blue"
                                  : "#303030"
                              } solid`,
                              transition: "border 0.3s ease",
                            }}
                          />
                        ))}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                      }}
                    >
                      <Slider
                        value={formData[`fontSize_${index}`] || defaultFontSize}
                        onChange={(e, value) =>
                          handleFontSizeChange(index, e, value)
                        }
                        min={20}
                        max={50}
                        step={1}
                        valueLabelDisplay="auto"
                        sx={{ width: "100px" }}
                      />
                    </Box>
                  </Box>

                  {/* Logo Preview */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "6px",
                      height: "200px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "28px",
                        color: "#474747",
                      }}
                    >
                      Your Logo Demo
                    </Typography>
                    <Box
                      sx={{
                        background: "radial-gradient(circle, #51AAFD, #002D68)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        padding: "40px",
                        borderRadius: "15px",
                        width: "100px",
                        height: "80px",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: `${
                            formData[`fontSize_${index}`] || defaultFontSize
                          }px`,
                          fontWeight: "700",
                          color:
                            formData[`selectedColor_${index}`] || defaultColor,
                          fontFamily:
                            formData[`selectedFont_${index}`] ||
                            defaultFontFamily,
                        }}
                      >
                        {formData[`logoName_${index}`] || "LOREM"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  );
};

export default SubmitOfferCard;
