import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    .title {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #474747;
      font-weight: 600;
      a {
        text-decoration: none;
        color: #474747;
        font-weight: 400;
      }
      svg {
        margin-top: 6px;
      }
    }
    .service-name {
      color: #474747;
      font-size: 24px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      margin-bottom: 20px;
      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
    .chart-card {
      width: 66%;
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  `
);
