import { css, styled } from "@mui/material";
export const Styles = styled("div")(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    .chat {
      width: 80%;
      height: 70dvh;
      border-radius: 15px;
      z-index: 100;
      display: block;
      position: relative;
      box-shadow: #161616 0px 2px 10px 0px;
      background: #e5edff;
      .messages {
        height: calc(100% - 200px);
        margin: 15px;
        margin-bottom: 5px;
        border-radius: 20px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        padding-inline: 10px;
        padding-block: 10px;
        background: #e5edff;
        font-size: 0.9rem;
        .message-container {
          width: 50%;
          max-width: 90%;
          padding: 10px;
          padding-bottom: 20px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          text-align: start;
          .date {
            text-align: start;
            width: 100%;
            color: #959595;
            font-weight: 600;
            font-size: 14px;
          }
          gap: 10px;
          .details {
            display: flex;
            gap: 5px;
            color: #161616dd;
          }
          .user {
            width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 100%;
            margin-bottom: -15px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .data {
            width: 100%;
            display: flex;
            gap: 10px;
            .user {
              width: 31px;
              height: 31px;
              background: #fff;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .message {
              width: calc(100% - 70px);
              border-radius: 10px;
              padding: 15px;
              word-break: break-all;
              background: #fff;
              box-shadow: -2px 4px 4px 0px #0000003a;
            }
          }
        }
        .incoming-message {
          align-self: flex-end;
          flex-direction: column;
          align-items: flex-end;
          .date {
            text-align: end;
          }
          .data {
            color: #5f5f5f;
            flex-direction: row-reverse;
            .message {
              background: #bbf9e2;
            }
            /* border-end-end-radius: 0; */
          }
        }
        .outcoming-message {
          align-self: flex-start;
          border-end-start-radius: 0;
          text-align: start;
          .data {
            color: #5f5f5f;
            /* border-end-start-radius: 0; */
          }
        }
      }
      .footer {
        padding-inline: 10px;
        padding-top: 10px;
        form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
          align-items: center;
          margin-inline: 23px 15px;
          textarea {
            width: calc(100% - 48px);
            border: 1px solid
              ${theme.palette.mode === "dark" ? "#f0f3f740" : "#1d1d1d40"} !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: 10px;
            background: transparent;
            padding: 5px 10px;
            resize: none;
            color: ${theme.palette.mode === "dark" ? "#f0f3f7" : "#1d1d1d"};
            &::placeholder {
              color: ${theme.palette.mode === "dark" ? "#f0f3f7" : "#1d1d1d"};
            }
          }
        }
        .send {
          all: unset;
          width: 100px;
          height: 36px;
          border-radius: 10px;
          background: #eca601;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          cursor: pointer;
          transition: all.3s;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  `
);
