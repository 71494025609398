import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Styles } from "./styles";
import FooterLogo from "../../Assets/Svg/Footer-logo.svg";
import Whatsaap from "../../Assets/Svg/whatsaap.svg";
import Telegram from "../../Assets/Svg/Telegram.svg";
import Twitter from "../../Assets/Svg/Twitter.svg";
import Youtube from "../../Assets/Svg/youtube.svg";
import Instaram from "../../Assets/Svg/instagram.svg";
import Facebook from "../../Assets/Svg/Facebook.svg";
import Crypto from "../../Assets/crypto.png"
import Payeer from "../../Assets/Payeer.png"


const Footer = () => {
  return (
    <Styles>
      <Box className="footer" sx={{ padding: { xs: "40px", sm: "40px 80px" } }}  >
        <Grid container spacing={4} sx={{ flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "center", md: "normal" } }}>
          <Grid item xs={12} md={3}>
            <Box>
              <img src={FooterLogo} alt="Footer Logo" />
              <Typography className="footer-description">
                Unlock the full potential of your online presence with our
                tailored social media panel services. Elevate your engagement
                and grow your audience. Join us today and watch your social
                media soar!
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography className="footer-title" textAlign="center">Location & Contact</Typography>
              <Typography className="footer-contact" textAlign="center">
                <span>3275 McCarthy Rd, Ottawa, ON K1V 9M7, Canada</span>
                <span>info@booksmm.com</span>
                <span>001-343-987-4565</span>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography className="footer-title"> Links</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Link className="footerroute" to="/home">
                Services Finder
              </Link>
              <Link className="footerroute" to="/Providers">
                Trusted Providers
              </Link>
              <Link className="footerroute" to="/OurServices">
                Our Services
              </Link>
              <Link className="footerroute" to="/OurOffers">
                Our Offers
              </Link>
              <Link className="footerroute" to="/AddPanel">
                Add Panel
              </Link>
              <Link className="footerroute" to="/FAQ">
                FAQ
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography className="footer-title">Subscribe to our newsletter</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <Typography >
                Get our latest offers and news directly to your email,
                subscribe now and enjoy being up to date to all services trends.
              </Typography>
              <Button
                sx={{
                  color: "#3F78E0",
                  border: "solid 2px #3F78E0",
                  width: "110px",
                  borderRadius: "8px",
                  fontSize: "15px",
                  lineHeight: "20px",
                  fontWeight: "500",
                  boxShadow: `
      -2px 4px 4px #00000040, /* Drop shadow */
      inset 0px 5px 10px #FFFFFF40 /* Inner shadow */
    `,
                  background: "white",
                  marginTop: "10px"
                }}

              >
                Subscribe


              </Button>
            </Box>
          </Grid>

          {/* <Grid
            item
            xs={12}
            sm={12}
            md={2}
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <Typography className="footer-title">Follow Us</Typography>
            <div className="social-icons">
              <div className="item">
                <a href="https://www.facebook.com/profile.php?id=61570173386448&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                  <img src={Facebook} alt="Facebook" />
                </a>
              </div>
              <div className="item">
                <a href="https://www.instagram.com/booksmmpromo/profilecard/?igsh=MWZiODl2OGZ5YmdjOA==" target="_blank" rel="noopener noreferrer">
                  <img src={Instaram} alt="Instagram" />
                </a>
              </div>
              <div className="item">
                <a href="https://x.com/BooksmmPro?t=44reAEHS23YntFSIcysFZA&s=09" target="_blank" rel="noopener noreferrer">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </div>
              <div className="item">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img src={Youtube} alt="Youtube" />
                </a>
              </div>
              <div className="item">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img src={Telegram} alt="Telegram" />
                </a>
              </div>
              <div className="item">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img src={Whatsaap} alt="WhatsApp" />
                </a>
              </div>
            </div>
          </Grid> */}

        </Grid>
        <Grid container spacing={4} sx={{ flexDirection: { xs: "column-reverse", md: "row" }, alignItems: { xs: "center", md: "normal" }, marginTop: "20px" }}>
          <Grid item xs={12} md={5} sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center", justifyContent: "center", gap: "20px" }} >
            <Typography sx={{ fontSize: "14px" }}>
              © 2022 BookSMM. All rights reserved
            </Typography>

            <Link className="footerroute" to="/PrivacyPolicy" style={{ margin: "0", padding: "0", fontSize: "14px" }}>
              Privacy Policy
            </Link>
            <Link className="footerroute" to="/Termofuse" style={{ margin: "0", padding: "0", fontSize: "14px" }}>
              Term of use
            </Link>
          </Grid>

          <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
            {/* <span style={{ marginRight: "20px", color: "#CCCCCC", }}>

              |
            </span> */}
            <Typography >
              Payments
            </Typography>
            <img src={Crypto} alt="crypto" />
            <img src={Payeer} alt="Payeer" />
            {/* <span style={{ marginLeft: "20px", color: "#CCCCCC" }}>

              |
            </span> */}
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: "flex", flexDirection: "row", gap: "50px", alignItems: "center", flexWrap: "wrap" }} >

            <div className="item">
              <a href="https://www.facebook.com/profile.php?id=61570173386448&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                <img src={Facebook} alt="Facebook" />
              </a>
            </div>
            <div className="item">
              <a href="https://www.instagram.com/booksmmpromo/profilecard/?igsh=MWZiODl2OGZ5YmdjOA==" target="_blank" rel="noopener noreferrer">
                <img src={Instaram} alt="Instagram" />
              </a>
            </div>
            <div className="item">
              <a href="https://x.com/BooksmmPro?t=44reAEHS23YntFSIcysFZA&s=09" target="_blank" rel="noopener noreferrer">
                <img src={Twitter} alt="Twitter" />
              </a>
            </div>
            <div className="item">
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={Youtube} alt="Youtube" />
              </a>
            </div>
            <div className="item">
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={Telegram} alt="Telegram" />
              </a>
            </div>


          </Grid>

        </Grid>
      </Box>
    </Styles >
  );
};

export default Footer;
