import React from "react";
import Tooltip from "@mui/material/Tooltip";
import CircularProgressChart from "./CircularProgressChart";
import { useMediaQuery } from "@mui/material";
import { showIconByServiceId } from "../../../../../helpers/showIconByServiceId";

const ServiceCard = ({ data }) => {
  const isTablet = useMediaQuery("(max-width:1300px)");

  return (
    <div className="service">
      <div className="main-contain">
        <div className="service-title">
          <img src={showIconByServiceId(data?.service_id)} alt="icon" className="icon" />
          <div className="subtitle">{data?.service}</div>
          {!isTablet && (
            <Tooltip title={data?.type || ""} arrow>
              <div className="description">({data?.type})</div>
            </Tooltip>)}
        </div>
        {isTablet && (
          <Tooltip title={data?.type || ""} arrow>
            <div className="description">({data?.type})</div>
          </Tooltip>)}
        <div className="statistic-data">
          <div className="chart">
            <CircularProgressChart percentage={data?.percentage || 0} />
          </div>
          <div className="days-left">
            <div className="number">{data?.count_days_active || 0}</div>
            <div className="name">Days Left</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
