import { React, useState, useEffect, Fragment, useCallback } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Pagination,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Helper } from "../../tools/Helper";
import { useSnackbar } from "notistack";
import { api_Routes } from "../../api_Routes";
import Rating from "@mui/material/Rating";
import Autocomplete from "@mui/material/Autocomplete";
import BannerCard from "../BannerCard";
import Filters from "./filters";
import MainLoader from "../common/main-loader";

const Providers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const trustedParam = searchParams.get("trusted");
  const [currentSignal, setCurrentSignal] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState(
    trustedParam === "true" ? "Trusted" : ""
  );
  const [rating, setrating] = useState([]);
  const [country, setcountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [sortedOptions, setSortedOptions] = useState([]);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [Panels, setPanels] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [triggerSearch, setTriggerSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [loadingpage, setisloadingpage] = useState(true);
  // const [sortedOptions, setSortedOptions] = useState([...country]);
  const [loading, setLoading] = useState(false);
  const baseApi = api_Routes.Providers.view;
  useEffect(() => {
    // Sort whenever country or selectedCountry changes
    setSortedOptions(
      [...country].sort(
        (a, b) =>
          selectedCountry.includes(b.id) - selectedCountry.includes(a.id)
      )
    );
  }, [country, selectedCountry]);
  useEffect(() => {
    get_Home();
  }, []);
  const get_Home = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.HomePage.view,
      hasToken: true,
      data: {
        results: 190,
      },
    });
    if (response) {
      setisloadingpage(false);

      setcountry(
        response.data.Countries.map((ele) => ({
          id: ele.id,
          label: ele.name,
        }))
      );
    } else {
      setisloadingpage(false);
    }
  };

  // useEffect(() => {
  //     if (searchInitiated) {
  //         const controller = new AbortController();
  //         const signal = controller.signal;

  //         get_Panels(signal).finally(() => {
  //             setLoading(false); // Ensure loading state is reset after API call
  //         });

  //         return () => {
  //             controller.abort(); // Abort ongoing request on cleanup
  //         };
  //     }
  // }, [triggerSearch, searchInitiated, page

  // ]);

  // const handleClose = () => {
  //     setSortedOptions([...country].sort((a, b) =>
  //         selectedCountry.includes(b.id) - selectedCountry.includes(a.id)
  //     ));
  // };
  const get_Panels = useCallback(
    async (signal, keyword = "", dynamicPage = page) => {
      setLoading(true); // Always set loading at the start
      let url = `${baseApi}?page=${dynamicPage + 1}&limit=${limit}`; // Include pagination

      // Append keyword if provided
      if (keyword.trim()) url += `&keyword=${keyword}`;

      // // Append filters
      // if (filter === "Not Trust") url += `&filter=0`;
      // else if (filter === "Trusted") url += `&filter=1`;
      // else if (filter === "All") url += `&filter=`;

      // Append country filters
      selectedCountry.forEach((id) => {
        url += `&country_id[]=${id}`;
      });

      try {
        const { response } = await Helper.Get_Abort({
          url,
          hasToken: true,
          signal,
        });

        if (signal.aborted) return; // Exit early if the request was aborted

        if (response && response.success) {
          const { data, pagination } = response;

          // Handle null values in data by replacing them with empty strings or arrays
          const sanitizedData = (data || []).map((item) => {
            // Replace null values in each object property with an empty string
            return Object.fromEntries(
              Object.entries(item).map(([key, value]) => [
                key,
                value === null ? "" : value,
              ])
            );
          });

          setPanels(sanitizedData); // Update state with sanitized data
          setTotalItems(pagination?.total || 0); // Update total items for pagination
        } else {
          setPanels([]); // Reset panels in case of failure
          setTotalItems(0); // Reset total items
        }
      } catch (error) {
        console.error("Error fetching panels:", error);
      } finally {
        setLoading(false); // Ensure loading is always reset
        setSearchInitiated(true);
      }
    },
    [baseApi, limit, filter, selectedCountry]
  );

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    // Call the get_Panels function to refresh data with new filter
  };

  const handlePageChange = (event, newPage) => {
    const updatedPage = newPage - 1; // Convert 1-based UI to 0-based API
    setPage(updatedPage); // Update the state

    if (currentSignal) currentSignal.abort(); // Abort the previous request

    const controller = new AbortController();
    setCurrentSignal(controller);

    // Trigger the API call for the new page
    get_Panels(controller.signal, searchTerm.trim(), updatedPage);
  };

  const handleCountryChange = (event, newValues) => {
    setSelectedCountry(newValues.map((Cou) => Cou.id));
  };
  const handleSearchClick = () => {
    if (loading) return;
    // Prevent multiple clicks while loading

    setLoading(true);

    // Abort previous search
    if (currentSignal) currentSignal.abort();

    // Create a new AbortController for the current request
    const controller = new AbortController();
    setCurrentSignal(controller);

    // Determine search keyword
    const keyword = searchTerm.trim();

    // Logic for search
    if (!keyword && (selectedCountry.length > 0 || filter)) {
      // No keyword, but filters are applied
      get_Panels(controller.signal, "");
    } else if (keyword) {
      // Search with a keyword
      get_Panels(controller.signal, keyword);
    } else {
      // Default search with "Trusted" filter
      // setFilter("All");
      get_Panels(controller.signal, "");
    }
  };

  // const handleSearchClick = () => {
  //     if (searchTerm.trim()) { // Check if search term is not empty
  //         setSearchInitiated(true);
  //         setLoading(true);
  //         setTriggerSearch('');
  //         setTimeout(() => {
  //             setTriggerSearch(searchTerm);
  //             // Replace this with your actual API call
  //             // const fetchedPanels = []; // Assume this is the result of your API call
  //             // setPanels(fetchedPanels);8
  //         }, 0);
  //     } else {
  //         enqueueSnackbar("Please enter a keyword to search.", { variant: "warning" });
  //     }
  // };

  useEffect(() => {
    if (trustedParam === "true") {
      handleSearchClick();
    } else {
      setSearchInitiated(false);
    }
  }, [location.search]);

  return (
    <>
      {loadingpage ? (
        <MainLoader />
      ) : (
        <Box sx={{ backgroundColor: "#D2E2EC", height: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  color: "#474747",
                  fontWeight: "700",
                  lineHeight: "40px",
                  fontSize: "32px",
                  textAlign: "center",
                }}
              >
                FIND CHEAP SMM PANEL DEALS
              </Typography>
              <Typography
                sx={{
                  color: "#474747",
                  fontSize: "16px",
                  fontWeight: "700",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                Explore all providers and Search for any SMM Panel from hundreds
                of active SMM panels
              </Typography>
            </Box>
            <Filters
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              handleSearchClick={handleSearchClick}
              handleCountryChange={handleCountryChange}
              handleFilterChange={handleFilterChange}
              loading={loading}
              sortedOptions={sortedOptions}
              filter={filter}
            />
            <Box
              sx={{
                background: "white",
                marginInline: "40px",
                padding: {
                  xs: "20px 40px",
                  sm: "20px 30px",
                  lg: " 20px 80px ",
                },
                marginBottom: "40px",
                borderRadius: "20px",
              }}
            >
              {searchInitiated ? (
                Panels.length > 0 ? (
                  <>
                    <Grid container spacing={3}>
                      {Panels.map((panel, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={index}
                          sx={{ height: "416px", width: "308px" }}
                        >
                          <BannerCard data={panel} isprovider={true} />
                        </Grid>
                      ))}
                    </Grid>
                    <Box
                      sx={{
                        my: 2,
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" }, // Stack on smaller screens
                        justifyContent: { sm: "space-evenly", xs: "center" }, // Adjust spacing
                        alignItems: "center",
                        gap: { xs: 2, sm: 0 }, // Add space between stacked elements
                        textAlign: { xs: "center", sm: "left" }, // Center align on smaller screens
                      }}
                    >
                      {/* Results and Page Info */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" }, // Stack on smaller screens
                          gap: "8px", // Increase gap slightly for better spacing
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#004571",
                            fontSize: { xs: "13px", sm: "15px" }, // Adjust font size for smaller screens
                            lineHeight: "18px",
                            fontWeight: "400",
                          }}
                        >
                          {totalItems} results{" "}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#004571",
                            fontSize: { xs: "13px", sm: "15px" },
                            lineHeight: "18px",
                            fontWeight: "400",
                          }}
                        >
                          |
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#004571",
                            fontSize: { xs: "13px", sm: "15px" },
                            lineHeight: "18px",
                            fontWeight: "400",
                          }}
                        >
                          Showing {page + 1} of {Math.ceil(totalItems / limit)} pages
                        </Typography>
                      </Box>

                      {/* Pagination Component */}
                      <Pagination
                        count={Math.ceil(totalItems / limit)} // Total number of pages
                        page={page + 1} // Adjust for 1-based pagination control
                        onChange={handlePageChange} // Handle page changes
                        variant="outlined"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        siblingCount={1} // Number of pages on either side of the current page
                        boundaryCount={1} // Number of pages at the start and end
                        sx={{
                          mt: { xs: 2, sm: 0 }, // Add margin-top for smaller screens
                          "& .MuiPagination-ul": {
                            justifyContent: { xs: "center", sm: "flex-start" },
                            gap: "10px" // Center align on smaller screens
                          },
                        }}
                      />
                    </Box>

                  </>
                ) : (
                  <Box sx={{ textAlign: "center", padding: "20px" }}>
                    <Typography variant="h6">No panels found.</Typography>
                  </Box>
                )
              ) : (
                <Box sx={{ textAlign: "center", padding: "20px" }}>
                  <Typography variant="h6">
                    Please initiate a search.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default Providers;
