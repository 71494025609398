import { React, useState, useEffect } from "react";
import { Helper } from "../../../../tools/Helper";
import { api_Routes } from "../../../../api_Routes";
import { useSnackbar } from "notistack";
import Details from "./Details/GeneralDetails";
import MainLoader from "../../../../components/common/main-loader";
import NoPanelWrapper from "../no-panel";
import { useNavigate } from "react-router-dom";

const EditPanel = () => {
  const navigate = useNavigate();

  //todo : remove {} from init panel
  const [Panel, setPanel] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isPanelsLoading, setIsPanelsLoading] = useState(false);
  const baseApi = api_Routes.Providers.getPanelByAuth;

  const get_Panels = async () => {
    setIsPanelsLoading(true);
    let url = `${baseApi}`;
    const { response, message } = await Helper.Get({
      url: url,
      hasToken: true,
    });
    if (response && response.success) {
      setPanel({
        id: response.data.panel_details.id,
        name: response.data.panel_details.name,
        email: response.data.panel_details.email,
        logo: response.data.panel_details.logo,
        customLogo: response.data.panel_details.customLogo,
        description: response.data.panel_details.description,
        trust: response.data.panel_details.trust,
        screenShot: response.data.panel_details.screenShot,
        url: response.data.panel_details.url,
        whatsapp: response.data.panel_details.whatsapp,
        rate: response.data.rate.avg,
        telegram: response.data.panel_details.telegram,
        instagram: response.data.panel_details.instagram,
        tel1: response.data.panel_details.tel1,
        tel2: response.data.panel_details.tel2,
        tel3: response.data.panel_details.tel3,
        trust: response.data.panel_details.trust,
        domain_registered: response.data.panel_details.domain_registered,
        payment_method: response.data.payment_method,
        count_review: response.data.count_review,
        country_code: response.data.panel_details.country_code,
        country_name: response.data.panel_details.country_name,
      });
    } else {
      console.log("API Response:", response, message);
      enqueueSnackbar(message, { variant: "error" });
    }
    setIsPanelsLoading(false);
    console.log("API Response:", response, message);
  };

  const onSave = async (formData) => {
    setIsLoading(true);

    // Ensure the Panel has an `id`
    if (!Panel.id) {
      enqueueSnackbar("Panel ID is missing.", { variant: "error" });
      setIsLoading(false);
      return;
    }


    const url = api_Routes.Providers.edit(Panel.id);

    // Make the API call
    const result = await Helper.Post({
      url: url,
      data: formData,
      hasToken: true,
    });

    const { response, message } = result || {};

    if (response && response.success) {
      enqueueSnackbar("Panel updated successfully.", { variant: "success" });
      navigate("/dashboard/mypanel"); // Navigate back to the panel list
    } else {
      enqueueSnackbar(message || "Failed to update panel.", { variant: "error" });
    }

    setIsLoading(false);
  };

  const onCancel = () => {
    navigate("/dashboard/mypanel");
  };

  useEffect(() => {
    get_Panels();
  }, []);

  return isPanelsLoading ? (
    <MainLoader isFull={true} />
  ) : (
    Panel ?
      <Details
        Panel={Panel}
        onSave={onSave}
        onCancel={onCancel}
        isLoading={isLoading}
      />
      : <NoPanelWrapper />
  );
};
export default EditPanel;
