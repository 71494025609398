const Host = "https://subwebsite.rentchicken.net/api";
export const api_Routes = {
  Auth: {
    login: `${Host}/user/login`,
    add: `${Host}/user/register`,
    verifyaccount: `${Host}/user/register/verification`,
    Resendverifyaccount: `${Host}/user/register/resendVerification`,
    SendEmailForForgetPassword: `${Host}/user/forgetPassword/step1`,
    VerficationPasswordByEmail: `${Host}/user/forgetPassword/step2`,
    ResetPassword: `${Host}/user/forgetPassword/step3`,
    ForgetPasswordResend: `${Host}/user/forgetPassword/resendVerification`,
  },
  Search: {
    view: `${Host}/panels/search`,
    servers: `${Host}/services/search`,
  },
  AddPanel: {
    add: `${Host}/panel`,
  },
  HomePage: {
    view: `${Host}/homepage`,
  },
  Providers: {
    view: `${Host}/panels/search`,
    getOne: (id) => `${Host}/panels/${id}`,
    add_rate: `${Host}/review`,
    getPanelByAuth: `${Host}/getPanelByIdAuth`,
    editMyPanel: `${Host}/editPanel`,
    edit: (id) => (`${Host}/editPanel/${id}`),

  },
  OurServices: {
    view: `${Host}/OurServices`,
    add: `${Host}/request`,
  },
  OurOffer: {
    view: `${Host}/offers`,
    getOne: (id) => `${Host}/offer/${id}`,
    add: `${Host}/offerInvoice`,
  },
  invoice: {
    getOne: (id) => `${Host}/GetInvoice?invoice_id=${id}`,
    getOneOffer: (id) => `${Host}/GetOfferInvoice?invoice_id=${id}`,
    pay: `${Host}/PayInvoice`,
  },
  Header: {
    getMyNotifications: `${Host}/admin/getMyNotifications`,
  },
  profile: {
    showprofile: `${Host}/dashboard/showProfile`,
    updateprofile: `${Host}/dashboard/updateProfile`,
    changePassword: {
      sendCode: `${Host}/dashboard/sendOtpForChangePassword`,
      verifyCode: `${Host}/dashboard/verifyOtpChangePassword`,
      changePassword: `${Host}/dashboard/ChangePassword`,
    },
  },
  dashboard: {
    overView: {
      view: `${Host}/dashboard/user`,
    },
    tickets: {
      view: `${Host}/dashboard/support/category`,
      viewTicket: `${Host}/dashboard/support/TicketMessages/`,
      add: `${Host}/dashboard/support/createTicket`,
      addMessage: `${Host}/dashboard/support/createMessageTicket`,
      get_history: `${Host}/dashboard/support/historyTicket`,
    },
    orderhistory: {
      view: `${Host}/dashboard/filterOrders`,
      viewByOffer: `${Host}/dashboard/OrdersOfferHistory`,
      Statistics: `${Host}/dashboard/GetStatisticsOrder/`,
    },
  },
  Pin: {
    AddStatistics: `${Host}/addPinStatistics/`,
    OnSee: `${Host}/strorageViewsByPinActice`,
  },
  Banner: {
    AddStatistics: `${Host}/addBannerStatistics/`,
    OnSee: `${Host}/strorageViewsByBannerActive`,
  },
  FAQ: {
    view: `${Host}/faqs`,

  }
};
