import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme, orderOption }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .switch-container {
      width: 180px;
      border-radius: 5px;
      border: 1px solid #3f78e0;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      .service {
        color: ${orderOption === "service" ? "white" : "black"};
        z-index: 2;
      }
      .offer {
        color: ${orderOption === "offer" ? "white" : "black"};
        z-index: 2;
      }
      &::after {
        position: absolute;
        z-index: 1;
        content: "";
        ${orderOption === "service" ? "left:-31%;" : "left: 31%;"};
        top: -30%;
        height: 200%;
        width: 100%;
        transform: rotate(-60deg);
        background-color: #3f78e0;
        transition: 0.3s;
      }
    }
  `
);
