import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleTag = () => {
    return (
        <Helmet>
        
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11547846789"></script>
            <script>
                {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11547846789');
        `}
            </script>
        </Helmet>
    );
};

export default GoogleTag;
