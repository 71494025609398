import React from "react";
import { Styles } from "./styles";

const SwitchButton = ({ orderOption, setOrderOption }) => {
  const toggleOrdersOptions = () => {
    if (orderOption === "service") {
      setOrderOption("offer");
    } else {
      setOrderOption("service");
    }
  };

  return (
    <Styles orderOption={orderOption}>
      <div className="switch-container" onClick={toggleOrdersOptions}>
        <div className="service">Services</div>
        <div className="offer">Offers</div>
      </div>
    </Styles>
  );
};

export default SwitchButton;
