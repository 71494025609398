import React from "react";
import CustomModal from "../../../../common/custom-modal";
import { Styles } from "./styles";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../../../AuthContext/AuthContext";
import { useSnackbar } from "notistack";

const ViewServiceModal = ({ open, setOpen, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const handleNavigation = () => {
    if (auth || data?.search_first) {
      navigate(`/PanelsDetails/${data?.panelUuid}`);
    } else {
      enqueueSnackbar("Please login first", { variant: "warning" });
      navigate("/login", { state: { from: location } });
    }
  };
  return (
    <div>
      <CustomModal
        open={open}
        setOpen={setOpen}
        title={"Service Details"}
        description={
          "Here are all the Details you want to know about the service"
        }
        data={
          <Styles>
            <Grid
              container
              style={{ justifyContent: "space-between" }}
              spacing={1}
            >
              <Grid item xs={5}>
                <span className="name">ID</span>{" "}
                <span className="data">{data?.id}</span>
              </Grid>
              <Grid item xs={5}>
                <span className="name">Category</span>{" "}
                <span className="data">{data?.category}</span>
              </Grid>
              <Grid item xs={5}>
                <span className="name">Rate</span>{" "}
                <span className="data">{data?.rate_per_1000}</span>
              </Grid>
              <Grid item xs={5}>
                <span className="name">Min & Max</span>{" "}
                <span className="data">{data?.order_range}</span>
              </Grid>
              <Grid item xs={5}>
                <span className="name">Panel</span>{" "}
                <span className="data">
                  <a onClick={handleNavigation} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    target="_blank">
                    {data?.panelUrl}
                  </a>
                </span>
              </Grid>
              <Grid item xs={5}>
                <span className="name">Service</span>{" "}
                <span className="data">{data?.name}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="name description">Description</div>
                <div className="data description">{data?.description}</div>
              </Grid>
              <div>
              </div>
              <Grid item xs={12} className="btn-container">
                <Button className="btn" onClick={handleNavigation}>
                  Go To Panel Profile
                  <ArrowForwardIcon />
                </Button>


              </Grid>
            </Grid>
          </Styles>
        }
      />
    </div >
  );
};

export default ViewServiceModal;
