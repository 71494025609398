import { styled, css, keyframes } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 25px;
      @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  `
);
