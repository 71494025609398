import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Helper } from "../tools/Helper";
import { api_Routes } from "../api_Routes";

const RenderOnScroll = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      Helper.Post({
        url: api_Routes.Banner.OnSee,
        hasToken: true,
      });
    }
  }, [inView]);

  return <div ref={ref}>{inView && children}</div>;
};

export default RenderOnScroll;
