import { React } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import BannerCard from "../BannerCard";

const HomePagePanel = ({ homePagePanels }) => {
  return (
    homePagePanels?.length > 0 && (
      <>
        <Container
          fluid={true}
          sx={{
            marginBlock: "20px",
            marginBottom: "30px",
            padding: { xs: "70px", md: "30px 40px" },
            "@media (min-width: 1200px)": {
              maxWidth: "1400px",
            },
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{
              justifyContent: "center",
            }}
          >
            {homePagePanels.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={index}
                sx={{ height: "416px", width: "308px" }}
              >
                <BannerCard data={item} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </>
    )
  );
};
export default HomePagePanel;
