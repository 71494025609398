import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const CircularProgressChart = ({ percentage }) => {
  return (
    <div className="circular-progress-container">
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          textColor: "#000",
          pathColor: "#3578E5",
          trailColor: "#E0E0E0",
          textSize: "18px",
          strokeLinecap: "round",
          textStyle: {
            fontWeight: "600",
          },
        })}
      />
    </div>
  );
};

export default CircularProgressChart;
