import React from "react";
import { Styles } from "./styles";
import ActiveServiceCard from "./active-service-card";
import SpecialOffer from "../special-offer";

const ActiveServices = ({ servicedata, offerdata }) => {
  return (
    <Styles dataCount={servicedata?.length}>
      <div className="title">Active services</div>
      <div className="contain">
        {servicedata?.length < 2 ? (
          <>
            <SpecialOffer data={offerdata} />
            <div className="it">
              {servicedata?.map((item, idx) => {
                return <ActiveServiceCard data={item} key={idx} />;
              })}
            </div>
          </>
        ) : (
          <>
              {servicedata?.map((item, idx) => {
              if (idx < 2) {
                return <ActiveServiceCard data={item} key={idx} />;
              }
            })}
              <SpecialOffer data={offerdata} />
            <div className="it">
                {servicedata?.map((item, idx) => {
                if (idx === 2 || idx === 3) {
                  return <ActiveServiceCard data={item} key={idx} />;
                }
              })}
            </div>
              {servicedata?.map((item, idx) => {
              if (idx > 3) {
                return <ActiveServiceCard data={item} key={idx} />;
              }
            })}
          </>
        )}
      </div>
    </Styles>
  );
};

export default ActiveServices;
