import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { api_Routes } from "../../api_Routes";
import { Helper } from "../../tools/Helper";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import OfferCard from "./offer-card";
import MainLoader from "../common/main-loader";
const OurOffers = () => {
  const [Offers, setOffers] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    get_Offers();
  }, []);

  const get_Offers = async () => {
    const { response, message } = await Helper.Get({
      url: api_Routes.OurOffer.view,
      hasToken: true,
      data: {},
    });
    if (response) {
      setOffers([]);
      response.data.forEach((ele) => {
        setOffers((prev) => [
          ...prev,
          {
            offer_id: ele.offer_id,
            title: ele.title,
            details:
              ele.offerDetails?.map((detail) => detail.detail).join(", ") ||
              "No details provided", // Safely map details
            note: ele.note,
            total_price: ele.total_price,
            discount: ele.discount,
            discount_type: ele.discount_type,
            final_price: ele.final_price,
            offerDetails: ele.offerDetails,
          },
        ]);
      });
    } else {
    }
    setIsLoading(false);
  };

  const handleOfferClick = (offer_id) => {
    navigate(`/OffersDetails/${offer_id}`);
  };

  return (
    <>
      <Container
        sx={{
          "@media (min-width: 1200px)": {
            maxWidth: "1400px",
          },
        }}
      >
        {isLoading ? (
          <MainLoader />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
                marginBlock: "60px",
              }}
            >
              <Typography
                sx={{
                  color: "#474747",
                  fontSize: { xs: "20px", sm: "25px", md: "40px" },
                  lineHeight: "40px",
                  fontWeight: "700",
                }}
              >
                Searching For Many Services !{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#474747",
                  fontSize: "18px",
                  lineHeight: "20px",
                  fontWeight: "400",
                }}
              >
                Choose what works best dor you among all our exclusive offers.
              </Typography>
            </Box>
            {Offers.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  flexDirection: { sm: "column", xs: "column", md: "column" },
                  padding: { sm: "30px 100px 230px 100px", xs: "100px 20px" },
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    color: "#141414",
                    lineHeight: "32px",
                    fontSize: { xs: "16px", sm: "25px", lg: "32px" },
                    marginBlock: "20px",
                  }}
                >
                  {" "}
                  There is no Offers yet{" "}
                </Typography>
              </Box>
            ) : (
              <Grid
                container
                spacing={8}
                sx={{
                  marginBottom: "60px",
                  justifyContent: "center",
                }}
              >
                {Offers.map((Offer, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    sx={{
                      position: "relative",
                      marginTop: "60px",
                      paddingTop: "0 !important",
                    }}
                  >
                    <OfferCard data={Offer} />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </Container>
    </>
  );
};
export default OurOffers;
