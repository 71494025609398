import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext/AuthContext";

const GuestRoute = ({ children }) => {
    const { auth } = useAuth();

    if (auth) {
        return <Navigate to="/Home" />;
    }

    return children;
};

export default GuestRoute;
