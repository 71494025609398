import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    .main-data-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }
      width: 100%;
      margin-block: 50px;
      gap: 20px;
      .form-data {
        width: 90%;
        height: fit-content;
          @media (max-width: 900px) {
        width: 100%; /* Adjust form width for smaller screens */
      }
      }
      .image-container {
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  `
);
