import { Button, TableCell } from "@mui/material";
import React, { useState } from "react";
import ViewServiceModal from "./view-service-modal";

const DataTableColumn = ({ row, column, value }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <TableCell
      key={column.id}
      style={{
        border: "1px solid #00000080",
      }}
    >
      {column.id === "action" ? (
        <>
          <Button onClick={handleOpen}>View</Button>
          <ViewServiceModal open={open} setOpen={setOpen} data={{ ...row }} />
        </>
      ) : typeof value === "string" ? (
        value.slice(0, 50)
      ) : (
        value
      )}
    </TableCell>
  );
};

export default DataTableColumn;
