import React from "react";
import { Styles } from "./styles";
import noPanelIcon from "../../../../Assets/Dashbaord/my-panel/noPanelIcon.svg";
import noPanelBG from "../../../../Assets/Dashbaord/my-panel/noPanelBg.png";

const NoPanelWrapper = () => {
  return (
    <Styles>
      <div className="container">
        <div className="data">
          <div className="bg-image">
            <img src={noPanelBG} alt="no panel" />
          </div>
          <div className="icon">
            <img src={noPanelIcon} alt="no panel" />
          </div>
          <div className="title">There are no panel linked to your account</div>
          <div className="desc">
            <div className="desc-title">1.</div>
            <div className="desc-data">
              If you have a panel added to BookSMM and you want to link it to
              this account, just contact our support team in order to solve the
              problem.
            </div>
          </div>
          <div className="desc">
            <div className="desc-title">2.</div>
            <div className="desc-data">
              In case you own a panel and you haven’t added already to BookSMM
              yet! you can add it in “ Add Panel “ page, then contact our
              support team in order to link it.
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default NoPanelWrapper;
