import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    background: #d2e2ec;
    width: 100%;
    min-height: calc(100dvh - 60px);
    .container {
      .data {
        padding-top: 40px;
        padding-inline: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 100px;
        @media (max-width: 768px) {
          padding-inline: 50px;
        }
        position: relative;
        overflow: hidden;
        .title {
          color: #5f5f5f;
          font-size: 30px;
          font-weight: 600;
          margin-block: 20px;
          text-align: center;
        }
        .desc {
          display: flex;
          align-items: flex-start;
          margin-bottom: 30px;
          .desc-title {
            color: #5f5f5f;
            font-size: 25px;
            font-weight: 600;
            margin-inline-end: 20px;
            width: 21px;
          }
          .desc-data {
            color: #5f5f5f;
            font-size: 20px;
            font-weight: 500;
            margin-top: 5px;
          }
        }
        .bg-image {
          position: absolute;
          width: 90%;
          height: 90%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  `
);
