import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme, backgroundColor }) => css`
    padding: 15px;
    border-radius: 20px;
    background-color: ${backgroundColor || "#fff"};
    .title {
      color: #303030;
      margin-bottom: 10px;
    }
    .data {
      padding-inline: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #303030;
        font-weight: 600;
        .number {
          font-size: 30px;
        }
        .name {
          font-size: 18px;
        }
      }
    }
  `
);
