import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    .container {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    .main-data {
      width: 100%;
      .title {
        margin-top: 10px;
        margin-bottom: 15px;
        color: #5f5f5f;
        font-weight: 600;
        font-size: 35px;
      }
      .description {
        color: #5f5f5f;
        font-weight: 600;
        font-size: 20px;
      }
    }
    .form-data {
      width: 100%;
    }
  `
);
