import React from "react";
import { style, Styles } from "./styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const CustomModal = ({ open, setOpen, title, description, data }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: { backgroundColor: "rgba(255, 255, 255, 0.6)" }, // Change color and opacity
      }}
    >
      <Box sx={{ ...style, position: "relative" }}>
        <Styles>
          {" "}
          {/* Ensures relative positioning */}
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              border: "1px solid rgba(0, 0, 0, 0.3)",
              fontSize: "0.9rem",
            }}
          >
            <CloseIcon fontSize="2rem" />
          </IconButton>
          <Typography
            id="modal-modal-title"
            className="title"
            variant="h6"
            component="h2"
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-description"
            className="description"
            sx={{ mt: 2 }}
          >
            {description}
          </Typography>
          <div className="modal-data">
            <div className="main-data">{data}</div>
          </div>
        </Styles>
      </Box>
    </Modal>
  );
};

export default CustomModal;
