import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    .main-container {
      padding-inline: 100px;
      @media (max-width: 1300px) {
        padding-inline: 50px;
      }
      @media (max-width: 1150px) {
        padding-inline: 30px;
      }
      .logo {
        width: 160px;
        @media (max-width: 1100px) {
          width: 120px;
        }
        @media (max-width: 930px) {
          width: 100px;
        }
      }
      .links-container {
        gap: 40px;
        a {
          font-size: 17px;
          font-weight: 500;
        }
        @media (max-width: 1100px) {
          gap: 25px;
        }
        @media (max-width: 930px) {
          gap: 15px;
        }
        @media (max-width: 1025px) {
          a {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }
  `
);
