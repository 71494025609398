import React from "react";
import TotalServices from "./total-services";
import { Styles } from "./styles";
import ActiveServices from "./active-services";

const DashboardComponent = ({ data }) => {
  return (
    <Styles>
      <TotalServices data={data} />
      {data?.activeOffers?.length > 0 && (
        <ActiveServices offerdata={data?.activeOffers} servicedata={data.activeServices} />
      )}
    </Styles>
  );
};

export default DashboardComponent;
