import { React, useState, useEffect } from "react";
import { useAuth } from "../../AuthContext/AuthContext";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { api_Routes } from "../../api_Routes";
import { Helper } from "../../tools/Helper";
import { useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import MainLoader from "../common/main-loader";
import SubmitOfferForm from "./submit-offer-form";

const OffersDetails = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { offer_id } = useParams();
  const baseApi = api_Routes.OurOffer.getOne(offer_id);
  const [offer, setOffer] = useState(null);
  const [handleloading, sethandleLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    panel_url: "",
  });

  useEffect(() => {
    get_Offers();
  }, []);

  const get_Offers = async () => {
    const { response, message } = await Helper.Get({
      url: baseApi,
      hasToken: true,
    });
    if (response) {
      setIsLoading(false);
      setOffer({
        FiledTitle: response.data.FiledTitle,
        FiledDescription: response.data.FiledDescription,
        FiledImage: response.data.FiledImage,
        offer_id: response.data.offer_id,
        title: response.data.title,
        description: response.data.description,
        offerDetails: response.data.offerDetails,
        note: response.data.note,
        total_price: response.data.total_price,
        discount: response.data.discount,
        discount_type: response.data.discount_type,
        final_price: response.data.final_price,
        active: response.data.active,
        created_at: response.data.created_at,
      });
    } else {
    }
  };

  const handleButtonClick = async (e) => {
    if (!auth) {
      enqueueSnackbar("please login first ", { variant: "warning" });
      navigate("/login", { state: { from: location } });
    } else {
      await handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ["panel_url", "offer_id"];

    const updatedFormData = {
      ...formData,
      offer_id,
    };

    const missingFields = requiredFields.filter(
      (field) => !updatedFormData[field]
    );

    // Validate customLogo properties for each offer detail with a picture
    offer?.offerDetails?.forEach((item, idx) => {
      if (item?.picture) {
        if (!formData?.[`logoName_${idx}`]) {
          missingFields.push(`logoName for detail ${++idx}`);
        }
      }
    });

    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Please fill in all required fields: ${missingFields.join(", ")}`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      sethandleLoading(false);
      return;
    }

    sethandleLoading(true);

    const form_data = new FormData();

    // Construct requests array for offer details
    const offer_details_data = [];
    offer?.offerDetails?.forEach((item, idx) => {
      const request = {};
      if (item.title || item.description || item.picture)
        request.offer_detail_id = item?.id || "";
      if (item.title) request.title = formData[`title_${idx}`] || "";
      if (item.description)
        request.description = formData[`description_${idx}`] || "";
      if (item.picture) {
        request.customLogo = {
          fontSize: formData[`fontSize_${idx}`]
            ? `${formData[`fontSize_${idx}`]}px`
            : "30px",
          color: formData[`selectedColor_${idx}`] || "#FFFFFF",
          fontFamily: formData[`selectedFont_${idx}`] || "Mockads",
          logoName: formData[`logoName_${idx}`],
        };
      }
      offer_details_data.push(request);
    });

    // Append offer_details_data array to form_data
    offer_details_data.forEach((request, index) => {
      Object.keys(request).forEach((key) => {
        if (key === "customLogo") {
          Object.keys(request[key]).forEach((logoKey) => {
            form_data.append(
              `offer_details_data[${index}][customLogo][${logoKey}]`,
              request[key][logoKey]
            );
          });
        } else {
          form_data.append(`offer_details_data[${index}][${key}]`, request[key]);
        }
      });
    });

    // Append other fields
    Object.keys(updatedFormData).forEach((key) => {
      if (
        !key.startsWith("title_") &&
        !key.startsWith("description_") &&
        !key.startsWith("logoName_") &&
        !key.startsWith("selectedColor_") &&
        !key.startsWith("selectedFont_") &&
        !key.startsWith("fontSize_")
      ) {
        form_data.append(key, updatedFormData[key]);
      }
    });

    const { response, message } = await Helper.Post({
      url: api_Routes.OurOffer.add,
      data: form_data,
      hasToken: true,
    });

    if (response) {
      const invoice_id = response.data.uuid;

      navigate(`/OffersInvoice?invoice_id=${invoice_id}`);
      enqueueSnackbar(message, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      sethandleLoading(false);
    } else {
      let errorMessage = "";
      if (typeof message === "string") {
        errorMessage = message;
      } else if (typeof message === "object") {
        errorMessage = Object.values(message).flat().join(", ");
      }
      enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      sethandleLoading(false);
    }
  };

  const handleChange = (index, field, value) => {
    if (index || index == 0) {
      setFormData((prev) => ({
        ...prev,
        [`${field}_${index}`]: value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [`${field}`]: value,
      }));
    }
  };

  return (
    <>
      {isLoading ? (
        <MainLoader />
      ) : (
        <Box
          sx={{
            background: "#F8FAFF",
            padding: "30px 0",
            animation: "slideIn 0.7s ease",
            "@keyframes slideIn": {
              from: { transform: "translateY(20px)", opacity: 0 },
              to: { transform: "translateY(0)", opacity: 1 },
            },
          }}
        >
          <Container>
            <Box
              sx={{
                textAlign: "center",
                background: "linear-gradient(135deg, #296D99, #4E54C8)",
                borderRadius: "15px",
                padding: "25px",
                color: "#FFF",
                marginBottom: "40px",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Offer Details
              </Typography>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Card
                  sx={{
                    boxShadow: 4,
                    borderRadius: 3,
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.05)" },
                    background: "linear-gradient(135deg, #F3F4F8, #E5E8EE)",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#555", fontWeight: "600" }}
                    >
                      Total Price
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#296D99", fontWeight: "bold" }}
                    >
                      {offer.total_price}$
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  sx={{
                    boxShadow: 4,
                    borderRadius: 3,
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.05)" },
                    background: "linear-gradient(135deg, #FFF5F5, #FFEDED)",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#555", fontWeight: "600" }}
                    >
                      Discount
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#ED1818", fontWeight: "bold" }}
                    >
                      {offer.discount}
                      {offer?.discount_type}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  sx={{
                    boxShadow: 4,
                    borderRadius: 3,
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.05)" },
                    background: "linear-gradient(135deg, #E6F7FF, #CCEBFF)",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#555", fontWeight: "600" }}
                    >
                      Final Price
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#004571", fontWeight: "bold" }}
                    >
                      {offer.final_price}$
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Card
              sx={{
                boxShadow: 4,
                borderRadius: 3,
                marginTop: "20px",
                padding: "20px",
                background: "#FFF",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#296D99",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  {offer.title}
                </Typography>
                <Typography
                  sx={{
                    color: "#666",
                    marginBottom: "10px",
                  }}
                >
                  {offer.offerDetails?.length > 0 && (
                    <ul style={{ width: "100%" }}>
                      {offer.offerDetails.map((detail, index) => (
                        <li style={{ width: "100%" }} key={index}>
                          {detail.detail}
                        </li>
                      ))}
                    </ul>
                  )}
                </Typography>
                <Typography
                  sx={{
                    fontStyle: "italic",
                    color: "#888",
                    fontWeight: "500",
                  }}
                >
                  Note: {offer.note}
                </Typography>
              </CardContent>
            </Card>
            <SubmitOfferForm
              handleloading={handleloading}
              handleChange={handleChange}
              handleButtonClick={handleButtonClick}
              formData={formData}
              offer={offer}
            />
          </Container>
        </Box>
      )}
    </>
  );
};

export default OffersDetails;
