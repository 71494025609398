import React, { useState } from "react";
import { Styles } from "./styles";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LeftBlue from "../../../Assets/Svg/OurServiceSvg/LeftBlue.svg";
import RightBlue from "../../../Assets/Svg/OurServiceSvg/RightBlue.svg";
import LeftOrange from "../../../Assets/Svg/OurServiceSvg/LeftOrange.svg";
import RightOrange from "../../../Assets/Svg/OurServiceSvg/RightOrange.svg";
const MainData = (props) => {
  const [errorText, setErrorText] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const validateUrl = (value) => {
    if (value.trim() === "") {
      // Reset error state if the input is empty
      setError(false);
      setHelperText("");
      return;
    }

    const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]{2,}\.[a-z]{2,}(\.[a-z]{2,})?$/;
    if (!urlPattern.test(value)) {
      setError(true);
      setHelperText("Please enter a valid URL (e.g., https://example.com)");
    } else {
      setError(false);
      setHelperText("");
    }
  };
  const {
    selectedCategories,
    handleCategoryChange,
    selectedSubcategoryDetails,
    handleSubcategoryChange,
    selectedSubcategory,
    Categories,
    selectedCategoryDetails,
    handleChange,
    formData,
  } = props;
  return (
    <Styles>
      <Box className="main-data-container">
        <Box className="form-data">
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ paddingInlineStart: "0px !important" }}
            >
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  boxShadow: "1px 2px 4px #454040",
                  borderRadius: "8px",
                  background: "#F5F8FE",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      borderColor: "#6B9DFE",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#007BFF",
                    },
                  },
                }}
              >
                <InputLabel
                  id="select-category-label"
                  sx={{
                    color: "#474747",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    "&.Mui-focused": {
                      color: "#007BFF",
                    },
                  }}
                >
                  Service
                </InputLabel>
                <Select
                  labelId="select-category-label"
                  id="select-category"
                  value={selectedCategories}
                  onChange={(e) => {
                    handleCategoryChange(e?.target?.value);
                  }}
                  displayEmpty
                  label="Select Category"
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10px 12px",

                      display: "flex",
                      alignItems: "center",
                    },
                    "& .MuiSelect-icon": {
                      color: "#707275",
                    },
                    "& .MuiMenu-paper": {
                      maxHeight: "200px",
                      backgroundColor: "#f5f8fe",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      "& .MuiMenuItem-root": {
                        padding: "10px",
                        color: "#333",
                        fontSize: "14px",
                        borderBottom: "1px solid #9E9E9E",
                        '&[aria-selected="true"]': {
                          backgroundColor: "#007bff",
                          color: "#fff",
                        },
                        '&[data-focus="true"]': {
                          backgroundColor: "#007bff",
                        },
                      },
                    },
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#F5F8FE",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#004571",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#004571",
                    },
                  }}
                  inputProps={{
                    "aria-label": "Select Category",
                  }}
                >
                  {/* Replace Categories with your actual category data array */}
                  {Categories.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      sx={{
                        padding: "10px",
                        color: "#333",
                        fontSize: "14px",
                        borderBottom: "1px solid #9E9E9E",
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              sx={{
                paddingInlineStart: {
                  xs: "0 !important",
                  sm: "16px !important",
                },
              }}
            >
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  boxShadow: "1px 2px 4px #454040",
                  borderRadius: "8px",
                  background: "#F5F8FE",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      borderColor: "#6B9DFE",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#007BFF",
                    },
                  },
                }}
              >
                <InputLabel
                  id="select-subcategory-label"
                  sx={{
                    color: "#474747",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    "&.Mui-focused": {
                      color: "#007BFF",
                    },
                  }}
                >
                  Type
                </InputLabel>
                <Select
                  labelId="select-subcategory-label"
                  id="select-subcategory"
                  value={selectedSubcategory}
                  onChange={(e) => {
                    handleSubcategoryChange(e?.target?.value);
                  }}
                  displayEmpty
                  label="Select Subcategories"
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10px 12px",

                      display: "flex",
                      alignItems: "center",
                    },
                    "& .MuiSelect-icon": {
                      color: "#707275",
                    },
                    "& .MuiMenu-paper": {
                      maxHeight: "200px",
                      backgroundColor: "#f5f8fe",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      "& .MuiMenuItem-root": {
                        padding: "10px",
                        color: "#333",
                        fontSize: "14px",
                        borderBottom: "1px solid #9E9E9E",
                        '&[aria-selected="true"]': {
                          backgroundColor: "#007bff",
                          color: "#fff",
                        },
                        '&[data-focus="true"]': {
                          backgroundColor: "#007bff",
                        },
                      },
                    },
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#F5F8FE",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#004571",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#004571",
                    },
                  }}
                >
                  {/* Replace getSubCategories() with your actual function or array */}
                  {selectedCategoryDetails &&
                    selectedCategoryDetails.subCategories.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={{
                          padding: "10px",
                          color: "#333",
                          fontSize: "14px",
                          borderBottom: "1px solid #9E9E9E",
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  {/* {getSubCategories().map((option) => (
                                      <MenuItem
                                          key={option.id}
                                          value={option.id}
                                          sx={{
                                              padding: '10px',
                                              color: '#333',
                                              fontSize: '14px',
                                              borderBottom: '1px solid #9E9E9E',
                                          }}
                                      >
                                          {option.label}
                                      </MenuItem>
                                  ))} */}
                </Select>
              </FormControl>
            </Grid>

            {selectedCategoryDetails?.FiledTitle && (
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                sx={{ paddingInlineStart: "0px !important" }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      color: "#434343",
                      fontSize: "13px",
                      padding: "5px",
                    },
                  }}
                  value={formData.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                  name="title"
                  placeholder="Type Title"
                  sx={{
                    background: "white",
                    borderRadius: "8px",
                    "& .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px",
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "1px solid #5F5F5F",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "8px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Grid>
            )}

            {selectedCategoryDetails?.FiledDescription && (
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                sx={{ paddingInlineStart: "0px !important" }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={1}
                  InputLabelProps={{
                    style: { color: "#434343", fontSize: "13px" },
                  }}
                  value={formData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  name="description"
                  placeholder="Add your description here..."
                  sx={{
                    background: "white",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      border: "1px solid #5F5F5F",
                      boxShadow: "-2px 2px 4px #00000040",
                      borderRadius: "8px",
                      "& fieldset": {
                        border: "none",
                      },
                      "& textarea": {
                        borderRadius: "10px",
                      },
                    },
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={11}
              sx={{ paddingInlineStart: "0px !important" }}
            >
              <TextField
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "#434343",
                    fontSize: "13px",
                    padding: "5px",
                  },
                }}
                value={formData.panel_url}
                onChange={(e) => {
                  const url = e.target.value;
                  handleChange("panel_url", url);
                  if (error || url.trim() === "") validateUrl(url); // Live validation with empty field check
                }}
                onBlur={(e) => validateUrl(e.target.value)} // Validation on blur
                error={error} // Display error state
                helperText={helperText} // Show validation message
                name="panel_url"
                placeholder="Put your Link here (e.g., https://example.com)"
                sx={{
                  background: "white",
                  borderRadius: "8px",
                  "& .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "10px",
                  },
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #5F5F5F",
                    boxShadow: "-2px 2px 4px #00000040",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />

            </Grid>

          </Grid>
        </Box>

        <Box className="image-container">
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            sx={{ paddingInlineStart: "0px !important" }}
          >
            {/* Category and Subcategory Details Box */}

            <Box
              sx={{
                border: "1px solid #5F5F5F",
                height: "12rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "14px 100px",
                boxShadow: "-2px 2px 4px #00000040",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <img
                src={LeftBlue}
                alt="blue"
                style={{ position: "absolute", top: "0", left: "0" }}
              />
              <img
                src={LeftOrange}
                alt="orange"
                style={{ position: "absolute", top: "0", left: "0" }}
              />
              <img
                src={RightBlue}
                alt="blue"
                style={{ position: "absolute", bottom: "0", right: "0" }}
              />
              <img
                src={RightOrange}
                alt="orange"
                style={{ position: "absolute", bottom: "0", right: "0" }}
              />
              {selectedCategoryDetails ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "25px",
                        color: "#3F78E0",
                      }}
                    >
                      {selectedCategoryDetails.label}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "18px",
                        color: "#5E5B5B",
                      }}
                    >
                      {selectedCategoryDetails.description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        background:
                          "linear-gradient(90deg, #004571, #00568E, #B4D1E4, #0064A4, #0083D7)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Price:{" "}
                      {selectedSubcategoryDetails
                        ? selectedSubcategoryDetails.price
                        : "Please Select Subgategories"}{" "}
                      $
                    </Typography>
                    <svg
                      width="131"
                      height="9"
                      viewBox="0 0 131 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M70.1214 6.5L69.3357 9L68.6214 6.5C68.6214 6.5 68.1214 6.03057 67.1214 6C65.979 5.96508 64.8664 5.96943 64.8664 5.96943L0.522949 4.89515L64.8664 3.54585C64.8664 3.54585 65.7115 3.50932 66.8544 3.45488C67.7869 3.41046 68.6214 3 68.6214 3L69.3357 0L70.1214 3C70.1214 3 70.4925 3.5 71.3421 3.5C72.485 3.5 74.0891 3.55444 74.0891 3.55444L130.694 4.89515L74.2506 5.96133C74.2506 5.96133 72.485 5.96133 71.3421 6C70.5526 6.02671 70.1214 6.5 70.1214 6.5Z"
                        fill="#ECA601"
                      />
                    </svg>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "25px",
                        color: "#989898",
                      }}
                    >
                      Service title
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "#989898",
                      }}
                    >
                      Detailed discription about the services and how it works
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        color: "#989898",
                      }}
                    >
                      Price: XXX $
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    </Styles>
  );
};

export default MainData;
