import { styled, css, keyframes } from "@mui/system";
import ArrowImg from "../../../Assets/images/icons/arrow.svg";

export const Styles = styled("div")(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .icons {
      height: 40px;
      display: flex;
      gap: 8px;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      padding-inline: 5px;
      img {
        height: 100%;
        max-width: 50px;
        object-fit: contain;
      }
    }
    .offer-discount {
      position: absolute;
      top: -33px;
      right: -15px;
      width: 143px;
      height: 49px;
      img {
        width: 143px;
        height: 49px;
        object-fit: contain;
      }
      .offer-discount-data {
        position: absolute;
        width: 110px;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 49px;
        display: flex;
        align-items: center;
        .offer-discount-number {
          display: flex;
          align-items: center;
          font-weight: 600;
          color: #ffcf48;
          font-size: 30px;
          .number {
            font-family: "ArmorPiercing";
            margin-inline-end: 2px;
            font-size: 35px;
            margin-bottom: -7px;
            letter-spacing: 0.5px;
          }
          .offer-discount-percentage {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 22px;
            line-height: 0.9;
            span {
              color: #fff;
              font-size: 12px;
              font-weight: 300;
            }
          }
        }
        .offer-discount-title {
          border-inline-start: 1px solid #fff;
          margin-inline-start: 3px;
          padding-inline-start: 3px;
          color: #fff;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          gap: 0;
          .MuiTypography-body1 {
            line-height: 1;
            font-size: 14px;
          }
        }
      }
    }
    .offer-details {
      list-style: none;
      padding: 0;
      li {
        background-image: url(${ArrowImg});
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: left 6px;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  `
);
