import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme }) => css`
    .name {
      font-size: 14px;
      color: #303030;
      text-align: start;
    }
    .data {
      font-size: 14px;
      font-weight: 600;
      color: #303030;
      text-align: start;
      &.description {
        color: #474747;
      }
    }
    .btn-container {
      text-align: center;
      margin-top: 10px;
      .btn {
        background: #ECA601;
        color: #fff;
        font-weight: 600;
        border-radius: 10px;
        padding-block: 5px;
        padding-inline: 20px;
      }
    }
  `
);
