// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: `'Poppins', sans-serif`, // Set global font family

    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: `'Poppins', sans-serif`, // Ensure all Typography components use Poppins
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: `'Poppins', sans-serif`, // Ensure all links use Poppins
                },
            },
        },
    },
});

export default theme;
