import React from "react";
import { Styles } from "./styles";
import SearchResultIcon from "../../../../../Assets/images/dashboard/icons/searchResult.svg";
import MainPageIcon from "../../../../../Assets/images/dashboard/icons/mainPage.svg";
import BlukWhatsappIcon from "../../../../../Assets/images/dashboard/icons/BlukWhatsapp.svg";
import BlukTelegramIcon from "../../../../../Assets/images/dashboard/icons/BlukTelegram.svg";
import PinUpIcon from "../../../../../Assets/images/dashboard/icons/PinUp.svg";
import CircularProgressChart from "./CircularProgressChart";

const ActiveServiceCard = ({ data }) => {
  return (
    <Styles>
      <div className="main-container">
        <div className="title-container">
          <div className="main-data">
            <img
              src={
                data?.service === "Main Page"
                  ? MainPageIcon
                  : data?.service === "Search Results"
                    ? SearchResultIcon
                    : data?.service === "Bulk Whatsapp"
                      ? BlukWhatsappIcon
                      : data?.service === "Bulk Telegram"
                        ? BlukTelegramIcon
                        : data?.service === "Pin Up"
                          ? PinUpIcon
                          : SearchResultIcon
              }
              alt="icon"
              className="icon"
            />
            <div className="title">{data?.service}</div>
            <div className="description">({data?.type})</div>
          </div>
          <div className="secondary-data">
            <div className="price">{data?.price}$</div>
          </div>
        </div>
        <div className="data-container">
          <div className="main-data">
            <div className="item">
              <div className="name">Panel</div>
              <div className="description url">
                <a href={data?.panel_url} target="_blank">
                  {data?.panel_url}
                </a>
              </div>
            </div>
            <div className="item">
              <div className="name">Start</div>
              <div className="description">{data?.start_date}</div>
            </div>
            <div className="item">
              <div className="name">End</div>
              <div className="description">{data?.end_date}</div>
            </div>
          </div>
          <div className="statistic-data">
            <div className="chart">
              <CircularProgressChart percentage={data?.percentage || 0} />
            </div>
            <div className="days-left">
              <div className="number">{data?.count_days_active}</div>
              <div className="name">Days Left</div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default ActiveServiceCard;
