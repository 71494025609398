// src/App.js
import React from 'react';
import './App.css';
import "./fonts.css";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from "./AuthContext/AuthContext.js";
import AppContent from './components/App/AppContent.js';
import theme from "./Theme/them.js"
import { ThemeProvider } from '@mui/material/styles';

function App() {
  return (
    <div className="App">
      <SnackbarProvider autoHideDuration={2000} maxSnack={1}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <AppContent />  {/* AppContent handles the header, footer, sidebar, and routes */}
            </AuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

export default App;
