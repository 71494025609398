import React from 'react';
import {
    Box,
    Typography,
    Container,
    Paper,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Box>
            <Container
                sx={{
                    "@media (min-width: 1200px)": {
                        maxWidth: "1400px",
                    },
                }}
            >
                <Typography
                    variant="h4"
                    component="h1"
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: '700', color: '#303030', marginBlock: "30px", fontSize: "35px", lineHeight: "75px" }}
                >
                    Privacy Policy
                </Typography>
                <Paper elevation={3} sx={{ p: 4, borderRadius: "25px", background: "#E5EDFF", marginBottom: "40px" }}>
                    <Box>
                        <Typography sx={{ fontWeight: "600", fontSize: "30px", lineHeight: "45px" }}>
                            Last update: January 16, 2025
                        </Typography>
                        <Typography sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: "#474747", marginTop: "10px" }}>
                            BooksMM is committed to protecting your privacy. You can contact us at [Your Email Address] if you have any questions or issues regarding the use of your personal data, and we will be happy to assist you. By using this website or our services, you consent to the processing of your personal data as described in this Privacy Policy. We may update or make changes to this policy, so we encourage you to review it periodically.
                        </Typography>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography sx={{ fontWeight: "600", fontSize: "26px", lineHeight: "40px" }}>
                            Table of Contents
                        </Typography>
                        <List sx={{ fontSize: "20px", fontWeight: "400", color: "#474747" }}>
                            <ListItem>
                                <ListItemText primary="1.Definitions Used in This Policy" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="2.Principles We Follow for Data Protection" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="3.Your Rights Related to Your Personal Data" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="4.Personal Data We Collect About You" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="5.How We Use Your Personal Data" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="6.Who Has Access to Your Personal Data" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="7.How We Protect Your Data" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="8.Information About Cookies" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="9.Contact Information" />
                            </ListItem>
                        </List>
                    </Box>

                    {[{
                        title: "Definitions",
                        content: "Personal Data: Any information relating to an identified or identifiable natural person. Processing: Any operation or set of operations performed on personal data. Data Subject: A natural person whose personal data is being processed. We/Us: BooksMM."
                    }, {
                        title: "1. Principles We Follow for Data Protection",
                        content: "We adhere to the following principles for data protection: Processing is lawful, fair, and transparent. Processing is limited to the purpose for which data was collected. Data is minimized and stored only as long as necessary. Data is accurate and confidential."
                    }, {
                        title: "2. Data Subject's Rights",
                        content: "Data Subjects have the following rights: The right to information. The right to access, correct, or delete personal data. The right to restrict processing and object to automated processing. The right to data portability. The right to file a complaint or withdraw consent."
                    }, {
                        title: "3. Data We Collect",
                        content: "Information You Provide to Us: Name, email address, mailing address, and phone number. Information Collected Automatically: Browser information, IP address, and usage data."
                    }, {
                        title: "4. How We Use Your Personal Data",
                        content: "We use your personal data to: Provide services and support. For communication and marketing purposes. Analyze user behavior to improve services."
                    }, {
                        title: "5. Sharing Your Data",
                        content: "We do not share your personal data with third parties, except in the following cases: With partners who help us provide services. When required by law."
                    }, {
                        title: "6. How We Protect Your Data",
                        content: "We use secure protocols to transfer and store data and monitor our systems to detect vulnerabilities."
                    }, {
                        title: "7. Cookies",
                        content: "We use cookies to: Enable essential website functionality. Enhance user experience. Provide analytics and targeted advertisements."
                    }, {
                        title: "8. Changes to This Privacy Policy",
                        content: "We may update this Privacy Policy periodically. Changes will be posted on this page with the new effective date."
                    }, {
                        title: "9. Contact Information",
                        content: "If you have any questions, please contact us at: [Your Email Address], [Your Business Address]."
                    }].map((section, index) => (
                        <Box sx={{ mt: 3 }} key={index}>
                            <Typography sx={{ fontWeight: "500", fontSize: "26px", lineHeight: "40px", color: "#474747" }}>
                                {section.title}
                            </Typography>
                            <Typography sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: "#474747", marginTop: "10px" }}>
                                {section.content}
                            </Typography>
                        </Box>
                    ))}
                </Paper>
            </Container>
        </Box>
    );
};

export default PrivacyPolicy;