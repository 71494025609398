import React from "react";
import { Styles } from "./styles";
import { Box } from "@mui/material";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";

const ViewsStatisticsCard = ({ chartData, overAllData }) => {
  // Combine views and clicks into a unified data structure, handle empty clicks
  const viewsMap = new Map(
    chartData?.views?.map(({ date, total }) => [date, total])
  );

  // Create combinedData, even if clicks is empty
  const combinedData =
    chartData?.clicks?.length > 0
      ? chartData?.clicks?.map(({ date, total }) => ({
          date,
          clicks: total,
          views: viewsMap?.get(date) || 0,
        }))
      : chartData?.views?.map(({ date, total }) => ({
          date,
          clicks: 0, // No clicks, fallback to 0
          views: total,
        }));

  return (
    <Styles>
      <div className="main-data">
        <div className="header">
          <div className="title">Views & Clicks</div>
          <div className="stats">
            <div className="stat">
              <span className="dot blue"></span>
              Views <span className="number">{overAllData?.total_views}</span>
            </div>
            <div className="stat">
              <span className="dot orange"></span>
              Clicks <span className="number">{overAllData?.total_clicks}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="chart" style={{ height: "300px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={combinedData}
            margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
          >
            <defs>
              <linearGradient id="colorViews" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#3b82f6" stopOpacity={0.2} />
              </linearGradient>
              <linearGradient id="colorClicks" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f59e0b" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#f59e0b" stopOpacity={0.2} />
              </linearGradient>
            </defs>
            <Tooltip />
            <Area
              type="monotone"
              dataKey="views"
              stroke="#3b82f6"
              fill="url(#colorViews)"
              strokeWidth={2}
            />
            <Area
              type="monotone"
              dataKey="clicks"
              stroke="#f59e0b"
              fill="url(#colorClicks)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Styles>
  );
};

export default ViewsStatisticsCard;
