import { styled, css } from "@mui/system";

export const Styles = styled("div")(
  ({ theme, isTablet }) => css`
    width: 100%;
    .main-container {
      padding: 15px;
      padding-bottom: 0;
    }
    border-radius: 20px;
    background-color: #d9dfee;
    box-shadow: -4px 4px 4px 0 #00000040;
    margin-bottom: 20px;
    .title-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .price {
        color: #5f5f5f;
        background: #f0f0f0;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 600;
        white-space: nowrap;
        font-size: 16px;
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
    .panel-url {
      font-weight: 600;
      margin-bottom: 25px;
      .description {
        display: flex;
        align-items: flex-start;
        gap: 5px;
      }
      .name {
        font-size: 18px;
        color: #5f5f5f;
      }
      a {
        text-decoration: none;
        margin-top: 3px;
        color: #3f78e0 !important;
        font-size: 14px;
        display: inline-block;
        flex: 1;
      }
    }
    .services {
      display: flex;
      width: 100%;
      gap: 20px;
      flex-wrap:wrap;
      
      .service {
        border-radius: 20px;
        background-color: #e5edff;
        margin-bottom: 20px;
        width: calc(50% - 10px);
        .main-contain {
          padding: 10px;
          padding-bottom: 10px;
        }
        .service-title {
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: center;
          @media (max-width: 600px) {
            align-items: flex-start;
          }
          width: 100%;
          margin-bottom: ${!isTablet ? "20px" : "5px"};
          .icon {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
          .subtitle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
            font-size: 12px;
            max-width: calc(100% - 92px);
            @media (max-width: 1300px) {
              font-size: 11px;
              max-width: calc(100% - 25px);
            }
            @media (max-width: 600px) {
              white-space: normal;
            }
          }
          .description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 8px;
            width: 62px;
          }
        }
        .description {
          margin-bottom: ${isTablet && "10px"};
          text-align: ${isTablet && "center"};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 11px;
          width: 100%;
        }
        .statistic-data {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          @media (max-width: 400px) {
            gap: 5px;
          }
          .days-left {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .number {
              font-size: 30px;
              @media (max-width: 1300px) {
                font-size: 20px;
              }
              @media (max-width: 768px) {
                font-size: 17px;
              }
              @media (max-width: 500px) {
                font-size: 14px;
              }
              @media (max-width: 400px) {
                font-size: 10px;
              }
              color: #303030;
              font-weight: 600;
            }
            .name {
              font-size: 16px;
              @media (max-width: 1300px) {
                font-size: 12px;
              }
              @media (max-width: 768px) {
                font-size: 11px;
              }
              @media (max-width: 400px) {
                font-size: 10px;
              }
              font-weight: 600;
            }
          }
        }
        .circular-progress-container {
          width: 64px;
          height: 64px;
        }

        @media (max-width: 1300px) {
          /* Tablet */
          .circular-progress-container {
            width: 55px;
            height: 55px;
          }
        }

        @media (max-width: 768px) {
          /* Mobile */
          .circular-progress-container {
            width: 49px;
            height: 49px;
          }
        }
        @media (max-width: 500px) {
          .circular-progress-container {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  `
);
